
.preloader{
    background-color: white;
    width: 100%;
    height: 200px;
}
.preloader img{
    width: 100px;
    display: block;
    margin: auto;
}
/*
background: #ffecea !important;
border-color: #fcc4bf !important;
*/
.counter.red {
    color: #f63b2c;
    background-color: #fcc4bf !important;
}
.counter.blue {
    color: #00aff3;
    background-color: #d9ebf5;
}
.review-form .form-error label, .review-form .form-error span {
    color: #f63b2c;
}
.review-form .form-error input {
    border-color: #f63b2c;
}
.form-error label, .form-error span {
    color: #f63b2c;
}
.form-error input {
    border-color: #f63b2c;
}
.form-error .date4-select {
    border: solid 1px #f63b2c;
    width: 99px;
}

.card-body > .row.form-error {
    background: #ffecea !important;
    border-color: #fcc4bf !important;
}
.card-body li.form-error {
    background: #ffecea !important;
    border-top: 1px solid #fcc4bf !important;
}
.review-form .check-list li {
    margin-bottom: 0;
}

.text-row {
    color: #325885;
}

.signature-notice  {
    position: relative; left: -50%;
    /*border: dotted red 1px;*/
    -webkit-user-select:none;
    -khtml-user-select:none;
    -moz-user-select:none;
    -ms-user-select:none;
    -o-user-select:none;
    user-select:none;
    white-space: nowrap;
}
.signature-notice-container {
    position: absolute;
    left: 50%;
    top: 0px;
}

.form-error .sigCanvas {
    border: 1px solid red;
}

.review-form label {
    text-transform: none;
}

.back-link, .cursor-pointer, .btn {
    cursor: pointer;
}

.todo-info-table {
    border: solid 1px grey;
}

.signature canvas{
    border: solid 1px #00AEEF;
}

/*** iPhone and iOS Form Input Zoom Fixes ***/
/* Fix Input Zoom on devices older than iPhone 5: */
@media screen and (device-aspect-ratio: 2/3) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px; }
}

/* Fix Input Zoom on iPhone 5, 5C, 5S, iPod Touch 5g */
@media screen and (device-aspect-ratio: 40/71) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px; }
}

/* Fix Input Zoom on iPhone 6, iPhone 6s, iPhone 7  */
@media screen and (device-aspect-ratio: 375/667) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px; }
}

/* Fix Input Zoom on iPhone 6 Plus, iPhone 6s Plus, iPhone 7 Plus, iPhone 8, iPhone X, XS, XS Max  */
@media screen and (device-aspect-ratio: 9/16) {
    select, textarea, input[type="text"], input[type="password"],
    input[type="datetime"], input[type="datetime-local"],
    input[type="date"], input[type="month"], input[type="time"],
    input[type="week"], input[type="number"], input[type="email"],
    input[type="url"]{ font-size: 16px; }
}

.cursor-default {
    cursor: default;
}

.date4-select {
    float: left;
    width: 33%;
}

/*.date4-select select {*/
/*    width: 100px;*/
/*    height: 40px;*/
/*}*/

.payment-completed.tasks-block {
    text-align: center;
}

@media (max-width: 767px) {
    .custom-list-block {
        border-bottom: 1px solid #dae0e6;
        margin-bottom: 20px;
    }
}

.payment-completed .head-block.head-simple h2 {
    margin: 0 auto;
    font-size: 1.8rem;
}

.payment-completed {
    margin: 100px;
}
a.back-link .icon {
    transform: none;
}

.signin-section .img-holder.welcome-image span {
    background-size: auto 100%;
}
.select-autocomplete {
    width: 100%;
}
.autocomplete-dropdown {
    position: absolute;
    text-align: center;
    width: 40px;
    bottom: 0;
    right: 0;
    top: 0;
    color: #93a4b8;
    font-size: .625rem;
}

.autocomplete-dropdown:before {
    position: absolute;
    display: block;
    content: "";
    top: 50%;
    bottom: auto;
    left: 50%;
    right: auto;
}

.autocomplete-dropdown:before {
    font-family: "icomoon";
    content: "";
    font-weight: normal;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    pointer-events: none;
}
.jcf-select-select-mmyy-month .jcf-select-drop-content, .jcf-select-select-mmyy-year .jcf-select-drop-content  {
    width: 120px;
}
.google-places-dropdown {
    position: relative;
    background: #fff;
    z-index: 999;
    border: 1px solid #00aff3;
}
.google-places-field {
    position: absolute;
    width: 100%;
    top: 30px;
}

.google-places-option {
    cursor: default;
    overflow: hidden;
    display: block;
    padding: .5rem .625rem;
    color: #656565;
    height: 1%;
}
#root {
    height: 100%;
}
.row label {
    text-transform: none;
}

.input-error1 {
    color: #f63b2c;
    position: absolute;
    margin-left: -1.5rem !important;
    margin-top: 0.2rem;
}
@media (min-width: 992px) {
    .input-error1 {
        margin-left: -1.5rem !important;
        margin-top: 0.2rem;
    }
    .input-error1 a{
        margin: 0 !important;
    }
}

@media (max-width: 992px) {
    .input-error1 {
        margin-left: 0 !important;
        margin-top: 0;
    }
    .input-error1 a{
        margin: 0 !important;
    }
    .input-error1 ~ .form-control{
        padding-left: 1.2rem !important;
    }
}

.preloader-text {
    padding-top: 20px;
    text-align: center;
}

.aca-108-patch-2 .input-error1 a.tooltip-opener {
    z-index: 202
}
.aca-108-patch-1 .input-error1 a.tooltip-opener {
    z-index: 201
}

.input-error2 {
    color: #f63b2c;
    /*position: absolute;*/
    /*margin-left: -1.5rem !important;*/
    /*margin-top: 0.2rem;*/
}
.select-async-dropdown {
    position: absolute;
    text-align: center;
    width: 10px;
    bottom: 0;
    right: 0;
    top: 0;
    color: #93a4b8;
    font-size: .625rem;
}

.select-async-dropdown:before {
    position: absolute;
    display: block;
    top: 50%;
    bottom: auto;
    left: 50%;
    right: auto;
    font-family: "icomoon";
    content: "";
    font-weight: normal;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    pointer-events: none;
}


.select-async-loading {
    position: absolute;
    text-align: center;
    width: 20px;
    bottom: 0;
    right: 0;
    top: 0;
    color: #93a4b8;
    font-size: .625rem;
}
.select-async-loading img {
    position: absolute;
    display: block;
    top: 50%;
    bottom: auto;
    left: 50%;
    right: auto;
    width: 30px;
    font-weight: normal;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    pointer-events: none;
}

.sigCanvas {
    border: 1px solid;
}

.tasks-completed-item {
    background: #F4F7F7;
}

.impersonate-message {
    position: absolute;
    top: 0;
    background-color: green;
    z-index: 9000;
    width: 100%;
    margin: 0 auto;
    text-align: center;
    color: white;
}

.logo-esph img{
    width: 130px;
    margin: 0 !important;
}

.review-form .card-body .items-list.items-list4 .item {
    width: 25%;
}

.booklet-head {
    text-align: center;
    background-color: #F1EEE3;
    border: solid 1px;
}
.booklet-body {
    text-align: center;
    border: solid 1px;
}
.width-100 {
    width: 100%;
}

.booklet2 td {
    text-align: center;
    border: solid 1px;
}

.booklet3-head1 td {
    text-align: center;
    background-color: #03C2A0;
    border: solid 1px;
}
.booklet3-head2 td {
    text-align: center;
    background-color: #F1EEE3;
    border: solid 1px;
}
.booklet3-body td {
    text-align: center;
    border: solid 1px;
}
.signin-section .logo-maryvale {
    margin-bottom: 40px;
    text-align: center;
}
.custom-list-error {
    color: red;
    margin-left: 10px;
}
.date-mmyy-month, .date-mmyy-year  {
    float: left;
}