// custom variables and override bootstrap variables

$body-bg: #fff;

$white:  #fff !default;
$gray-50: #dbe3ee;
$gray-100: #f1f2f5;
$gray-150: #fafbfc;
$gray-200: #e4e8ed;
$gray-250: #dae0e6;
$gray-300: #c4cdd8;
$gray-350: #e5e7eb;
$gray-400: #b3bfcd;
$gray-450: #b9c4d1;
$gray-500: #a3b2c3;
$gray-600: #93a4b8;
$gray-650: #9296a8;
$gray-700: #495057 !default;
$gray-800: #325885;
$gray-900: #212529 !default;
$gray-light: #9397a9;
$gray-1000: #203b5c;
$black:  #000 !default;

$blue:        #00aff3;
$blue-100:    #26bbf5;
$blue-200:    #4cc7f7;
$blue-300:    #73d3f8;
$blue-400:    #99dffa;
$blue-500:    #bfebfc;
$blue-600:    #e5f7fe;
$blue-dark:   #00b0f0;
$blue-light:  #03b0f3;
$blue-lighten: #d9ebf5;

$green:        #67d32e;
$green-100:    #7eda4d;
$green-200:    #95e06d;
$green-300:    #abe78c;
$green-400:    #c2edab;
$green-500:    #d9f4cb;
$green-600:    #f0fbea;

$red:        #f63b2c;
$red-100:    #f7584c;
$red-200:    #f9766b;
$red-300:    #fa938b;
$red-400:    #fbb1ab;
$red-500:    #fdceca;
$red-600:    #feebea;

$orange:        #ff9b20;
$orange-100:    #ffaa41;
$orange-200:    #ffb963;
$orange-300:    #ffc884;
$orange-400:    #ffd7a6;
$orange-500:    #ffe6c7;
$orange-600:    #fff5e9;

$indigo:  #6610f2 !default;
$purple:  #6f42c1 !default;
$pink:    #e83e8c !default;
$yellow:  #ffc107 !default;
$teal:    #20c997 !default;
$cyan:    #17a2b8 !default;

$colors: ();
$colors: map-merge((
  "blue":       $blue,
  "indigo":     $indigo,
  "purple":     $purple,
  "pink":       $pink,
  "red":        $red,
  "orange":     $orange,
  "yellow":     $yellow,
  "green":      $green,
  "teal":       $teal,
  "cyan":       $cyan,
  "white":      $white,
  "gray":       $gray-600,
  "gray-dark":  $gray-800,
  "gray-light": $gray-light,
  "blue-dark":  $blue-dark
), $colors);

$primary:       $blue-dark;
$secondary:     $gray-600;
$success:       $green;
$info:          $cyan !default;
$warning:       $orange;
$danger:        $red;
$light:         $gray-100;
$dark:          $gray-800;
$default:       $gray-light;

$theme-colors: ();
$theme-colors: map-merge((
  "primary":    $primary,
  "secondary":  $secondary,
  "success":    $success,
  "info":       $info,
  "warning":    $warning,
  "danger":     $danger,
  "light":      $light,
  "dark":       $dark,
  "default":    $default
), $theme-colors);

$spacer: 1rem !default;
$spacers: () !default;
$spacers: map-merge((
	0: 0,
	1: ($spacer * .25),
	2: ($spacer * .5),
	3: $spacer,
	4: ($spacer * 1.5),
	5: ($spacer * 3),
	6: ($spacer * 4.5),
	7: ($spacer * 6),
	8: ($spacer * 7.5),
	9: ($spacer * 9),
	10: ($spacer * 10.5)
), $spacers);

$base-min-width: 320px;

// This is the default html and body font-size for the base rem value.
$rem-base: 16px;



// Grid breakpoints

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 768px,
  lg: 992px,
  xl: 1260px
);

$container-max-widths: (
  sm: 100%,
  md: 720px,
  lg: 960px,
  xl: 1200px
);

// Typography

// stylelint-disable value-keyword-case
$font-family-sans-serif: 'Poppins', 'Arial', 'Helvetica Neue', 'Helvetica', sans-serif;
$font-family-base:            $font-family-sans-serif;

$font-size-base:              0.8125rem;

$font-weight-medium:          500;
$font-weight-semi-bold:       600;

$font-weight-base:            $font-weight-medium;
$line-height-base:            (24/13);

$h1-font-size:                $font-size-base * 1.846;
$h2-font-size:                $font-size-base * 1.538;
$h3-font-size:                $font-size-base * 1.384;
$h4-font-size:                $font-size-base;
$h5-font-size:                $font-size-base;
$h6-font-size:                $font-size-base;

$headings-font-weight:        $font-weight-semi-bold;
$headings-color:              $gray-800;

$border-color:                $gray-250;

$border-radius:               .25rem;
$border-radius-lg:            .25rem;
$border-radius-sm:            .25rem;

// Body

$body-bg:                   theme-color("light");
$body-color:                $gray-650;


// Links
$link-color:             theme-color("primary");
$link-hover-decoration:  none;


// Buttons + Forms
//
// Shared variables that are reassigned to `$input-` and `$btn-` specific variables.

$input-btn-padding-y:         .687rem;
$input-btn-padding-x:         .75rem;
$input-btn-font-size:         0.75rem;
$input-btn-line-height:       1.333;

$input-btn-focus-width:       0;
$input-btn-focus-box-shadow:  none;

$input-btn-padding-y-sm:      .375rem;
$input-btn-padding-x-sm:      .5rem;
$input-btn-font-size-sm:      $input-btn-font-size;
$input-btn-line-height-sm:    $input-btn-line-height;

$input-btn-padding-y-lg:      .9375rem;
$input-btn-padding-x-lg:      1rem;
$input-btn-font-size-lg:      $input-btn-font-size;
$input-btn-line-height-lg:    $input-btn-line-height;


// Buttons
//
// For each of Bootstrap's buttons, define text, background, and border color.

$btn-padding-y:               $input-btn-padding-y;
$btn-padding-x:               1.4375rem;
$btn-font-size:               $input-btn-font-size;
$btn-line-height:             $input-btn-line-height;

$btn-padding-y-sm:            $input-btn-padding-y-sm;
$btn-padding-x-sm:            $input-btn-padding-x-sm;
$btn-font-size-sm:            $input-btn-font-size-sm;
$btn-line-height-sm:          $input-btn-line-height-sm;

$btn-padding-y-lg:            $input-btn-padding-y-lg;
$btn-padding-x-lg:            1.4375rem;
$btn-font-size-lg:            $input-btn-font-size-lg;
$btn-line-height-lg:          $input-btn-line-height-lg;

$btn-font-weight:             $font-weight-semi-bold;
$btn-box-shadow:              none;
$btn-focus-width:             $input-btn-focus-width;
$btn-focus-box-shadow:        $input-btn-focus-box-shadow;
$btn-disabled-opacity:        .44;
$btn-active-box-shadow:       none;
$btn-link-disabled-color:     $gray-600;

$btn-border-radius:           15rem;
$btn-border-radius-lg:        15rem;
$btn-border-radius-sm:        15rem;


// Forms

$input-padding-y:                       $input-btn-padding-y;
$input-padding-x:                       $input-btn-padding-x;
$input-font-size:                       $input-btn-font-size;
$input-font-weight:                     $font-weight-medium;
$input-line-height:                     $input-btn-line-height;

$input-padding-y-sm:                    $input-btn-padding-y-sm;
$input-padding-x-sm:                    $input-btn-padding-x-sm;
$input-font-size-sm:                    $input-btn-font-size-sm;
$input-line-height-sm:                  $input-btn-line-height-sm;

$input-padding-y-lg:                    $input-btn-padding-y-lg;
$input-padding-x-lg:                    $input-btn-padding-x-lg;
$input-font-size-lg:                    $input-btn-font-size-lg;
$input-line-height-lg:                  $input-btn-line-height-lg;

$input-bg:                              $gray-150;
$input-disabled-bg:                     $gray-150;

$input-color:                           $gray-800;
$input-border-color:                    $gray-250;
$input-box-shadow:                      none;

$input-border-radius:                   4px;
$input-border-radius-lg:                4px;
$input-border-radius-sm:                4px;

$input-focus-bg:                        $input-bg;
$input-focus-color:                     $input-color;
$input-focus-width:                     $input-btn-focus-width;
$input-focus-box-shadow:                $input-btn-focus-box-shadow;

$input-placeholder-color:               theme-color("secondary");
$input-plaintext-color:                 $body-color;

$input-height-border:                   $input-border-width * 2;

$input-height-inner:                    calc(#{$input-line-height * 1em} + #{$input-padding-y * 2});
$input-height-inner-half:               calc(#{$input-line-height * .5em} + #{$input-padding-y});
$input-height-inner-quarter:            calc(#{$input-line-height * .25em} + #{$input-padding-y / 2}) !default;

$input-height:                          calc(#{$input-line-height * 1em} + #{$input-padding-y * 2} + #{$input-height-border});
$input-height-sm:                       calc(#{$input-line-height-sm * 1em} + #{$input-btn-padding-y-sm * 2} + #{$input-height-border});
$input-height-lg:                       calc(#{$input-line-height-lg * 1em} + #{$input-btn-padding-y-lg * 2} + #{$input-height-border});


$custom-control-indicator-size:         1rem;
$custom-control-indicator-bg:           $input-bg;

$custom-control-indicator-bg-size:      50% 50%;
$custom-control-indicator-box-shadow:   none;
$custom-control-indicator-border-color: $gray-250;

$custom-control-indicator-disabled-bg:          $input-disabled-bg;
$custom-control-label-disabled-color:           $gray-600;

$custom-control-indicator-checked-color:        theme-color("primary");
$custom-control-indicator-checked-bg:           $input-bg;
$custom-control-indicator-checked-disabled-bg:  $input-bg;
$custom-control-indicator-checked-box-shadow:   none;
$custom-control-indicator-checked-border-color: $input-border-color;

$custom-control-indicator-focus-box-shadow:     $input-focus-box-shadow;
$custom-control-indicator-focus-border-color:   $input-border-color;


$custom-control-indicator-active-color:         theme-color("primary");
$custom-control-indicator-active-bg:            $input-bg;
$custom-control-indicator-active-box-shadow:    none !default;
$custom-control-indicator-active-border-color:  $input-border-color;

$custom-checkbox-indicator-icon-checked:        str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3e%3cpath fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3e%3c/svg%3e"), "#", "%23");

$custom-checkbox-indicator-indeterminate-bg:           theme-color("primary");
$custom-checkbox-indicator-indeterminate-color:        $custom-control-indicator-checked-color;
$custom-checkbox-indicator-icon-indeterminate:         str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3e%3cpath stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/%3e%3c/svg%3e"), "#", "%23");
$custom-checkbox-indicator-indeterminate-box-shadow:   none;
$custom-checkbox-indicator-indeterminate-border-color: $custom-checkbox-indicator-indeterminate-bg;

$custom-radio-indicator-icon-checked:           str-replace(url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='-4 -4 8 8'%3e%3ccircle r='3' fill='#{$custom-control-indicator-checked-color}'/%3e%3c/svg%3e"), "#", "%23");

$form-group-margin-bottom: 1.5rem;

$custom-file-height:                auto;
$custom-file-height-inner:          100%;
$custom-file-focus-border-color:    $input-focus-border-color;
$custom-file-focus-box-shadow:      none;
$custom-file-disabled-bg:           $input-disabled-bg;

$custom-file-padding-y:             1rem;
$custom-file-padding-x:             1.5rem;
$custom-file-line-height:           $input-line-height;
$custom-file-font-family:           $input-font-family;
$custom-file-font-weight:           $font-weight-semi-bold;
$custom-file-color:                 $input-color;
$custom-file-bg:                    $white;
$custom-file-border-width:          2px;
$custom-file-border-color:          $input-border-color;
$custom-file-border-radius:         $input-border-radius;
$custom-file-box-shadow:            none;
$custom-file-button-color:          $custom-file-color;
$custom-file-text: (
  en: "Browse"
);


// Modals
$modal-content-bg:                  $white;
$modal-content-border-width:        0;
$modal-content-border-radius:       4px;

$modal-backdrop-bg:                 $secondary;
$modal-backdrop-opacity:            0.34;
$modal-md:                          568px;


// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y:                   0.6875rem;
$alert-padding-x:                   1.125rem;
$alert-border-radius:               $border-radius;
$alert-link-font-weight:            $font-weight-medium;
$alert-border-width:                $border-width;

$alert-bg-level:                    -11.6;
$alert-border-level:                0;
$alert-color-level:                 0;

$navbar-light-toggler-icon-bg:      str-replace(url("data:image/svg+xml,%3csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3e%3cpath stroke='#{$dark}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3e%3c/svg%3e"), "#", "%23");