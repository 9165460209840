.footer {
	display: table-footer-group;
	height: 1%;
	overflow: hidden;
	text-align: center;
	@include font(12, 16, 600);
	color: $gray-650;
	background: $white;

	@include media('>=tablet') {
		text-align: left;
	}

	.container {
		padding-top: rem-calc(24);
		padding-bottom: rem-calc(24);

		@include media('>=tablet') {
			display: flex;
			justify-content: space-between;
			padding-top: rem-calc(50);
			padding-bottom: rem-calc(50);
		}
	}

	.copyright {
		display: block;

		a{
			color: $gray-650;
			text-decoration: none;

			&:hover {
				color: $primary;
			}
		}

		@include media('>=tablet') {
			order: 1;
		}
	}

	.footer-logo {
		width: 28px;
		margin: 0 auto 24px;

		@include media('>=tablet') {
			order: 2;
			margin: 0 auto;
		}
	}

	.footer-nav {
		@include media('>=tablet') {
			order: 3;
		}
	}
}

.footer-nav {
	@extend %listreset;
	display: flex;
	flex-flow: row wrap;
	justify-content: center;
	text-transform: capitalize;
	margin: rem-calc(0 0 10);

	@include media('>=tablet') {
		margin: 0;
		justify-content: flex-start;
	}

	li {
		padding: rem-calc(0 10 10);

		@include media('>=tablet') {
			padding: rem-calc(0 10);
		}
	}

	a{
		display: block;
		text-decoration: none;
		color: $gray-650;

		&:hover {
			color: $primary;
		}
	}
}