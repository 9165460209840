.admission-section {
	position: relative;

	.head {
		display: flex;
		flex-flow: row wrap;
		justify-content: space-between;
		align-items: center;
		margin: rem-calc(0 0 20);

		@include media('>=tablet') {
			margin: rem-calc(0 0 40);
		}

		.h2 {
			margin: rem-calc(0);

			@include media('<tablet') {
				@include font(16, 24, 600);
			}
		}

		.btn {
			@include media('<tablet') {
				display: flex;
				justify-content: center;
				align-items: center;
				min-width: inherit;
				width: 32px;
				height: 32px;
				padding: 0;
				font-size: 15px;
				line-height: 1;
			}

			.icon {
				@include media('<tablet') {
					margin: 0;
				}
			}
		}
	}
}

.btn-circle {
	display: flex;
	justify-content: center;
	align-items: center;
	width: 40px;
	height: 40px;
	border-radius: 50px;
	border: 1px solid #d7dde6;
	background: $white;
}

p {
	&:last-child {
		margin: 0;
	}
}

.admission-holder {
	padding: rem-calc(20 24);
	margin: rem-calc(0 0 24);
	border-radius: rem-calc(4);
	box-shadow: 0 2px 2px rgba(73, 92, 117, 0.1);
	background: $white;

	@include media('>=desktop') {
		display: flex;
		align-items: center;
		padding: rem-calc(16 20);
		margin: rem-calc(0 0 32);
	}
	@include media('>=widescreen') {
		padding: rem-calc(16 32);
	}

	&:last-of-type {
		margin: 0;
	}

	.left-col {
		width: 100%;
		flex-shrink: 0;
		padding: rem-calc(10 10 10 0);
		border-width: 0 0 1px;
		border-style: solid;
		border-color: #d7dde6;

		@include media('>=desktop') {
			width: 340px;
			border-width: 0 1px 0 0;
		}
		@include media('>=widescreen') {
			width: 392px;
			padding: rem-calc(10 30 10 0);
		}
	}

	.right-col {
		padding: rem-calc(20 0 10);

		@include media('>=tablet') {
			display: flex;
			flex-grow: 1;
			flex-basis: 0;
			align-items: center;
			justify-content: space-between;
		}
		@include media('>=desktop') {
			padding: rem-calc(14 0 10 20);
		}
		@include media('>=widescreen') {
			padding: rem-calc(14 0 10 36);
		}
	}

	h2 {
		margin: rem-calc(0 0 15);

		@include media('<tablet') {
			@include font(16, 24, 600);
		}
	}

	.text-box {
		width: 100%;
		position: relative;
		@include font(12, 16, 500);
		padding: rem-calc(0 0 33);
		margin: rem-calc(0 0 24);

		@include media('>=tablet') {
			width: 400px;
			padding: rem-calc(0 10 0 0);
			margin: 0;
		}
		@include media('>=desktop') {
			width: 320px;
		}
		@include media('>=widescreen') {
			width: 366px;
		}
	}

	.title-holder {
		margin: rem-calc(0 0 10 0);

		@include media('>=tablet') {
			display: flex;
			align-items: center;
		}

		&:only-child {
			margin: 0;
		}
	}

	h3 {
		@include font(14, 24, 600);
		margin: rem-calc(0);

		@include media('>=tablet') {
			@include font(18, 24, 600);
			margin: rem-calc(0 15 0 0);
		}
		@include media('>=widescreen') {
			margin: rem-calc(0 25 0 0);
		}
	}

	.bar-holder {
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		width: 100%;
		display: flex;
		align-items: center;

		@include media('>=tablet') {
			position: static;
			width: 216px;
		}
	}

	.progress {
		height: 6px;
		flex-grow: 1;
		flex-basis: 0;
		margin: rem-calc(0 8 0 0);
	}

	.btn-holder {
		display: flex;
		align-items: center;

		.btn-circle {
			margin: rem-calc(0 0 0 8);
			@include media('>=tablet') {
				margin: rem-calc(0 0 0 16);
			}
		}

		> .btn {
			flex-grow: 1;
			@include media('>=tablet') {
				flex-grow: 	0;
			}
		}
	}
}

.list {
	@extend %listreset;
	@include font(13, 16, 500);

	@include media('>=tablet') {
		@include font(14, 16, 500);
	}

	@include media('>=desktop') {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	li {
		display: flex;
		align-items: center;
		margin: rem-calc(0 0 10);

		@include media('>=desktop') {
			margin: 0;
		}
	}

	.icon {
		font-size: 16px;
		margin: rem-calc(0 15 0 0);

		@include media('>=tablet') {
			margin: rem-calc(0 8 0 0);
		}
		@include media('>=widescreen') {
			margin: rem-calc(0 15 0 0);
		}
	}
}


.modal-dialog {
	max-width: 100%;
	color: $gray-650;
	margin: rem-calc(15);
	@include fontsize(13, 24);

	@include media('>=tablet') {
		max-width: 568px;
		margin: 28px auto;
		@include fontsize(14, 16);
	}

	.close {
		position: absolute;
		top: -5px;
		right: -5px;
		font-size: 10px;
		line-height: 1;
		margin: 0;
	}

	.modal-header {
		display: block;
		position: relative;
		text-align: center;
		padding: rem-calc(80 24 10);
		border: 0;

		@include media('>=tablet') {
			padding: rem-calc(80 15 10);
		}
	}

	.icon-box {
		position: absolute;
		top: -44px;
		left: 50%;
		transform: translateX(-50%);
		@include font(40, 1, 600);
		color: $white;
		padding: rem-calc(5);
		border-radius: 100%;
		width: 88px;
		height: 88px;
		display: flex;
		align-items: center;
		justify-content: center;
		background: $primary;
	}

	.modal-content {
		box-shadow: 0 2px 2px rgba(73, 92, 117, 0.1);
	}

	.modal-body {
		padding: rem-calc(6 24 24);

		@include media('>=tablet') {
			padding: rem-calc(6 56 56);
		}
	}

	h2 {
		@include font(16, 20, 600);
		margin: rem-calc(0 0 11);

		@include media('>=tablet') {
			@include font(24, 28, 600);
		}
	}

	.btn-holder {
		text-align: center;
		padding: rem-calc(8 0 0);
	}
}

label {
	display:block;
	@include font(12, 16, 600);
	text-transform: capitalize;
	margin: rem-calc(0 0 8);
	color: $gray-800;
}

.radio-holder {

	@include media('>=tablet') {
		display: flex;
		justify-content: space-between;
		flex-flow: row wrap;
	}

	.form-group {
		width: 100%;

		@include media('>=tablet') {
			width: 48.5%;
		}
	}

	input[type="radio"] {
		display: none;

		&:checked ~ {
			.customradio {
				border-color: $primary;
				color: $primary;
				box-shadow: 0 0 0 1px $primary;
				background: $white;

				&:after {
					display: block;
				}
			}
		}
	}

	.customradio {
		display: block;
		@include font(12, 16, 600);
		color: $gray-650;
		margin: 0;
		padding: rem-calc(20 10);
		border-radius: 4px;
		text-align: center;
		border: 1px solid $gray-250;
		cursor: pointer;
		position: relative;
		background: $gray-150;

		&:after {
			display: none;
			position: absolute;
			top: 3px;
			right: 5px;
			@include font(13);
			@include icon("\e904");
		}
	}
}


.pre-admission {
	@include font(13, 24, 500);
	color: $gray-650;
	padding: rem-calc(28 24 20);
	text-align: center;
	border-radius: 4px;
	box-shadow: 0 2px 2px rgba(73, 92, 117, 0.1);
	background: $white;

	@include media('>=phone') {
		@include font(14, 24, 500);
	}
	@include media('>=tablet') {
		padding: rem-calc(63 15px);
		margin: 0;
	}

	.holder {
		max-width: 925px;
		margin: 0 auto;
	}

	.text-holder {
		max-width: 500px;
		margin: 0 auto 33px;

		@include media('>=tablet') {
			margin: 0 auto 48px;
		}
	}

	h1 {
		margin: rem-calc(0 0 23);

		@include media('<phone') {
			@include font(16, 25, 600);
			margin: rem-calc(0 0 15);
		}
	}

	p {
		margin: rem-calc(0 0 24);
	}

	.cols-holder {
		margin: rem-calc(0 0 32);
		line-height: 22px;
		font-weight: 500;

		@include media('>=desktop') {
			display: flex;
			flex-flow: row wrap;
			margin: rem-calc(0 -10 32);
		}
		@include media('>=widescreen') {
			margin: rem-calc(0 -14 32);
		}

		.column {
			width: 100%;
			position: relative;
			padding: rem-calc(0 15);

			@include media('>=phone') {
				width: 300px;
				margin: 0 auto;
			}
			@include media('>=desktop') {
				width: 25%;
				padding: rem-calc(0 10);
			}
			@include media('>=widescreen') {
				padding: rem-calc(0 14);
			}

			&:first-child {
				&:after {
					display: none;
				}

				.icon-box {
					&:before {
						display: none;
					}
				}
			}

			&:last-child {

				.icon-box {
					&:after {
						display: none;
					}
				}
			}

			&:after {
				display: none;
				position: absolute;
				top: 31px;
				left: -8px;
				font-size: 16px;
				line-height: 1;
				@include icon("\e90d");
				color: $gray-600;

				@include media('>=desktop') {
					display: block;
				}
			}
		}
	}

	.icon-box {
		display: flex;
		justify-content: center;
		align-items: center;
		width: 80px;
		height: 80px;
		font-size: 23px;
		line-height: 1;
		color: $primary;
		border: 1px solid $gray-250;
		border-radius: 50px;
		margin: 0 auto 24px;
		position: relative;

		@include media('>=tablet') {
			margin: 0 auto 26px;
		}

		&:after,
		&:before {
			display: none;
			position: absolute;
			top: 49%;
			left: 100%;
			content: "";
			width: 39px;
			height: 1px;
			margin-left: 16px;
			border-top: 1px dashed #93a4b8;

			@include media('>=desktop') {
				display: block;
			}
		}

		&:before {
			left: auto;
			right: 100%;
			margin-right: 16px;
		}
	}

	.text-box {
		overflow: hidden;
	}

	.title {
		display: block;
		@include font(13, 16, 600);
		margin: rem-calc(0 0 11);
		color: $gray-800;

		@include media('>=tablet') {
			margin: rem-calc(0 0 16);
		}
	}

	.btn-holder {

		@include media('>=phone') {
			padding: rem-calc(0 24);
			display: flex;
			justify-content: center;
			align-items: center;
		}
		@include media('>=tablet') {
			padding: 0;
		}

		.btn {
			display: block;
			margin: rem-calc(0 0 16);

			@include media('>=phone') {
				display: inline-block;
				margin: rem-calc(0 8);
			}

			&.btn-primary {
				@include media('>=phone') {
					order: 2;
				}
			}

			&.btn-outline-secondary {
				@include media('>=phone') {
					order: 1;
				}
			}
		}
	}
}

.text-section {
	@include v-align(inherit);
	min-height: 463px;
	text-align: center;
	color: $gray-650;
	padding: rem-calc(15 24);
	border-radius: 4px;
	box-shadow: 0 2px 2px rgba(73, 92, 117, 0.1);
	background: $white;

	@include media('>=tablet') {
		min-height: 568px;
		@include font(14, 24, 500);
	}

	.holder {
		max-width: 525px;
	}

	.icon-holder {
		font-size: 57px;
		line-height: 1;
		color: $primary;
		margin: rem-calc(0 0 36);

		@include media('>=tablet') {
			margin: rem-calc(0 0 56);
		}
	}

	h1 {
		margin: rem-calc(0 0 24);

		@include media('<phone') {
			@include font(16, 24, 600);
			margin: rem-calc(0 0 16);
		}
	}

	p {
		margin: rem-calc(0 0 20);

		br {
			display: none;
			@include media('>=tablet') {
				display: block;
			}
		}
	}

	.btn-holder {
		padding: rem-calc(10 0);

		.btn {
			@include media('<phone') {
				display: block;
				width: 100%;
			}
		}
	}
}

.container {
	@include media('<tablet') {
		padding-left: 24px;
		padding-right: 24px;
	}
}

.profile-detail {
	max-width: 770px;
	margin: 0 auto;
	padding: rem-calc(24);
	border-radius: 4px;
	box-shadow: 0 2px 2px rgba(73, 92, 117, 0.1);
	background: $white;

	@include media('>=tablet') {
		padding: rem-calc(32);
	}

	.jcf-select.jcf-select-lg {
		@include media('<tablet') {
			padding-top: rem-calc(11);
			padding-bottom: rem-calc(11);
		}
	}

	.form-group {
		@include media('<tablet') {
			margin-bottom: rem-calc(16);
		}
	}

	.form-control,
	.form-control-lg {
		@include media('<tablet') {
			height: 40px;
			padding-top: rem-calc(10);
			padding-bottom: rem-calc(10);
		}
	}

	.h2 {
		margin: rem-calc(0 0 16);

		@include media('<tablet') {
			@include font(16, 24);
		}
	}

	.h3 {
		display: block;
		@include font(16, 20, 600);
		margin: rem-calc(0 0 22);

		@include media('>=tablet') {
			margin: rem-calc(0 0 30);
		}
	}

	.profile-holder {
		margin-bottom: rem-calc(14);
	}

	.row {
		margin-right: -12px;
		margin-left: -12px;
	}

	[class*="col-"] {
		padding-left: 12px;
		padding-right: 12px;
	}

	.sel-box {
		display: flex;
		align-items: center;
		position: absolute;
		top: 50%;
		transform: translateY(-50%);
		left: 1px;
		width: 80px;
		z-index: 1;
	}

	.icon {
		position: absolute;
		top: 50%;
		right: 16px;
		transform: translateY(-50%);
		font-size: 12px;
		line-height: 1;
		pointer-events: none;

		@include media('>=tablet') {
			font-size: 14px;
			right: 15px;
		}
	}

	.code {
		display: inline-block;
		@include font(12, 16, 500);
		color: $gray-650;
		min-width: 25px;
		padding: rem-calc(0 0 0 8);
	}

	.btn {
		display: block;
		min-width: 100%;

		@include media('>=phone') {
			display: inline-block;
			min-width: 100px;
		}
	}
}

.form-row {
	align-items: center;
}

.label-list {
	@extend %listreset;
	@include font(12, 16, 600);
	color: $dark;
	padding: rem-calc(0 0 2);

	@include media('>=desktop') {
		@include fontsize(13, 20);
		padding: rem-calc(0 15);
	}

	li {
		position: relative;
		padding: 0 0 0 10px;

		&:after {
			position: absolute;
			top: 6px;
			left: 0;
			content: "";
			width: 6px;
			height: 6px;
			border-radius: 15px;
			background: $primary;
		}
	}
}