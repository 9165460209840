@font-face {
  font-family: 'icomoon';
  src:  url('../fonts/icomoon.eot?is72a9');
  src:  url('../fonts/icomoon.eot?is72a9#iefix') format('embedded-opentype'),
    url('../fonts/icomoon.ttf?is72a9') format('truetype'),
    url('../fonts/icomoon.woff?is72a9') format('woff'),
    url('../fonts/icomoon.svg?is72a9#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-check:before {
  content: "\e91c";
}
.icon-arrow-right:before {
  content: "\e91b";
}
.icon-question:before {
  content: "\e91a";
}
.icon-arrow-left:before {
  content: "\e919";
}
.icon-phone:before {
  content: "\e918";
}
.icon-help:before {
  content: "\e917";
}
.icon-save:before {
  content: "\e916";
}
.icon-admission:before {
  content: "\e900";
}
.icon-arrow-down:before {
  content: "\e901";
}
.icon-calendar:before {
  content: "\e902";
}
.icon-chat:before {
  content: "\e903";
}
.icon-checkmark:before {
  content: "\e904";
}
.icon-close:before {
  content: "\e905";
}
.icon-create:before {
  content: "\e906";
}
.icon-dollor:before {
  content: "\e907";
}
.icon-drop-file:before {
  content: "\e908";
}
.icon-ellipsis-v:before {
  content: "\e909";
}
.icon-error:before {
  content: "\e90a";
}
.icon-file:before {
  content: "\e90b";
}
.icon-global:before {
  content: "\e90c";
}
.icon-long-arrow-right:before {
  content: "\e90d";
}
.icon-map-pin:before {
  content: "\e90e";
}
.icon-medicare:before {
  content: "\e90f";
}
.icon-medication:before {
  content: "\e910";
}
.icon-minus:before {
  content: "\e911";
}
.icon-mobile:before {
  content: "\e912";
}
.icon-plus:before {
  content: "\e913";
}
.icon-success:before {
  content: "\e914";
}
.icon-support:before {
  content: "\e915";
}
