.header {
	@extend %clearfix;
	display: table-header-group;
	width: 100%;
	height: 1%;
	position: relative;
	box-shadow: 0 2px 2px rgba(73, 92, 117, 0.1);
	background: $white;

	.container-fluid {
		display: flex;
		position: relative;
		padding-top: rem-calc(15);
		padding-bottom: rem-calc(13);
		z-index: 99;

		@include media('>=tablet') {
			padding: rem-calc(16 24);
		}
		@include media('>=desktop') {
			padding: rem-calc(16 41);
		}
	}

	.logo {
		width: 189px;
		flex-shrink: 0;
		margin: rem-calc(2 10 0 0);

		@include media('>=desktop') {
			width: 235px;
			margin: rem-calc(2 24 0 0);
		}

		a {
			display: block;
		}

		img {
			display: block;
			max-width: 100%;
			height: auto;
		}
	}

	.right-col {

		@include media('>=desktop') {
			display: flex;
			align-items: center;
		}

		.support-link {
			display: flex;
			align-items: center;
			position: relative;
			@include font(12, 16, 600);
			color: #9397a9;
			padding: rem-calc(20 0);

			@include media('>=desktop') {
				margin: rem-calc(0 32);
				padding: 0;
			}

			.icon {
				display: inline-block;
				font-size: 15px;
				line-height: 1;
				margin: rem-calc(0 12 0 0);
			}
		}
	}

	.user-info {
		display: block;
		color: $gray-800;
		@include font(12, 16, 500);
		border-top: 1px solid #dae0e6;
		padding: rem-calc(20 24 5);
		margin: rem-calc(0 -24);

		@include media('>=desktop') {
			max-width: 160px;
			margin: 0;
			border: 0;
			padding: 0;
		}

		.author {
			display: flex;
			align-items: center;
			justify-content: center;
			width: 40px;
			height: 40px;
			flex-shrink: 0;
			border-radius: 50px;
			@include font(14, 17, 600);
			text-transform: uppercase;
			text-align: center;
			color: $primary;
			margin: rem-calc(0 10 0 0);
			background: rgba($primary, 0.1);
		}

		.title {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
			color: $gray-800;

			&:after {
				@include icon("\e901");
				font-size: 10px;
				line-height: 1;
				border: 0;
				margin: rem-calc(-2 0 0 8);
				position: absolute;
				right: 0;

				@include media('>=desktop') {
					display: inline-block;
					vertical-align: top;
					position: static;
				}
			}
		}

		.dropdown-menu {
			position: static;
			width: 100%;
			margin: rem-calc(5 0 0);
			@include media('>=desktop') {
				position: absolute;
				width: 160px;
				left: auto;
				right: 0;
				top: 100%;
			}
		}
	}

	.navbar {
		flex-grow: 1;
		flex-basis: 0;
		.nav-opener {
			position: absolute;
			top: 19px;
			right: 20px;
			border: 0;
			padding: 0;
		}
	}
}

.navbar {
	padding: rem-calc(0);
	
	position: static;

	@include media('>=desktop') {
		position: relative;
		margin: 0;
		box-shadow: none;
	}

	.navbar-toggler {
		@include size(20px);
		@include pseudo-element(before, 50%, 0, auto, 0);
		@include pseudo-element(after, 50%, 0, auto, 0);
		border: none;
		position: relative;
		overflow: hidden;

		&:before,
		&:after {
			height: 2px;
			background: $dark;
			@include animate(transform color);
			transform: rotate(0);
			margin: -5px 0 0;
		}
		&:after {
			margin: 5px 0 0;
		}

		&:not([aria-expanded="false"]) {
			&:before,
			&:after {
				transform: rotate(45deg);
				margin: 0;
			}

			&:after {
				transform: rotate(-45deg);
			}
			.navbar-toggler-icon {
				opacity: 0;
				visibility: hidden;
			}
		}
	}

	.navbar-toggler-icon {
		@include animate(transform color opacity);
		height: auto;
		width: auto;
		background: none;
		position: absolute;
		top: 50%;
		left: 0;
		right: 0;
		height: 2px;
		background: $dark;
	}

	.navbar-collapse {
		padding: rem-calc(20 24);
		box-shadow: 0 2px 2px rgba(73, 92, 117, 0.1);
		background: $white;
		position: absolute;
		top: 100%;
		left: 0;
		right: 0;
		margin: rem-calc(2 0 0);
		overflow-x: hidden;
		overflow-y: auto;
		max-height: calc(100vh - 63px);
		transition: all 0.5s ease;
		opacity: 0;
		visibility: hidden;

		@include media('>=desktop') {
			overflow: visible;
			position: static;
			justify-content: space-between;
			padding: 0;
			background: none;
			box-shadow: none;
			margin: 0;
			opacity: 1;
			visibility: visible;
			max-height: inherit;
		}

		@include media('<desktop') {
			height: auto !important;
			display: block !important;
		}

		&.show,
		&.collapsing {
			opacity: 1;
			visibility: visible;
		}
	}

	.navbar-nav {
		@include font(12, 16, 600);
		padding: rem-calc(0 0 10);
		margin: rem-calc(0);
		border-bottom: 1px solid #dae0e6;

		@include media('>=desktop') {
			display: flex;
			flex-flow: row wrap;
			padding: rem-calc(0 15);
			margin: rem-calc(-16 -10);
			border: 0;
		}

		li {

			@include media('>=desktop') {
				padding: rem-calc(0 10);
				margin: 0;
			}

			&.active {
				a {
					color: $primary;

					&:after {
						visibility: visible;
						opacity: 1;
					}
				}
			}
		}

		a {
			display: block;
			color: #9397a9;
			padding: rem-calc(15 0);
			position: relative;
			text-decoration: none;

			@include media('>=desktop') {
				padding: rem-calc(28 8);
			}

			&:hover {
				color: $primary;
				&:after {
					visibility: visible;
					opacity: 1;
				}
			}

			&:after {
				visibility: hidden;
				opacity: 0;
				transition: linear 0.3s;
				position: absolute;
				left: -24px;
				top: 0;
				bottom: 0;
				content: "";
				width: 2px;
				background: $primary;

				@include media('>=desktop') {
					bottom: 0;
					right: 0;
					left: auto;
					top: auto;
					height: 2px;
					width: 100%;
				}
			}
		}
	}
}
