@charset "UTF-8";
/*! jQuery UI - v1.11.4 - 2015-11-30
* http://jqueryui.com
* Includes: core.css, autocomplete.css, datepicker.css, menu.css, theme.css
* To view and modify this theme, visit http://jqueryui.com/themeroller/?ffDefault=Arial%2CHelvetica%2Csans-serif&fsDefault=1em&fwDefault=normal&cornerRadius=3px&bgColorHeader=e9e9e9&bgTextureHeader=flat&borderColorHeader=dddddd&fcHeader=333333&iconColorHeader=444444&bgColorContent=ffffff&bgTextureContent=flat&borderColorContent=dddddd&fcContent=333333&iconColorContent=444444&bgColorDefault=f6f6f6&bgTextureDefault=flat&borderColorDefault=c5c5c5&fcDefault=454545&iconColorDefault=777777&bgColorHover=ededed&bgTextureHover=flat&borderColorHover=cccccc&fcHover=2b2b2b&iconColorHover=555555&bgColorActive=007fff&bgTextureActive=flat&borderColorActive=003eff&fcActive=ffffff&iconColorActive=ffffff&bgColorHighlight=fffa90&bgTextureHighlight=flat&borderColorHighlight=dad55e&fcHighlight=777620&iconColorHighlight=777620&bgColorError=fddfdf&bgTextureError=flat&borderColorError=f1a899&fcError=5f3f3f&iconColorError=cc0000&bgColorOverlay=aaaaaa&bgTextureOverlay=flat&bgImgOpacityOverlay=0&opacityOverlay=30&bgColorShadow=666666&bgTextureShadow=flat&bgImgOpacityShadow=0&opacityShadow=30&thicknessShadow=5px&offsetTopShadow=0px&offsetLeftShadow=0px&cornerRadiusShadow=8px
* Copyright jQuery Foundation and other contributors; Licensed MIT */
.ui-helper-hidden {
  display: none; }

.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px; }

.ui-helper-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1.3;
  text-decoration: none;
  font-size: 100%;
  list-style: none; }

.ui-helper-clearfix:before, .ui-helper-clearfix:after {
  content: "";
  display: table;
  border-collapse: collapse; }

.ui-helper-clearfix:after {
  clear: both; }

.ui-helper-clearfix {
  min-height: 0; }

.ui-helper-zfix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  filter: Alpha(Opacity=0); }

.ui-front {
  z-index: 100; }

.ui-state-disabled {
  cursor: default !important; }

.ui-icon {
  display: block;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat; }

.ui-widget-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%; }

.ui-autocomplete {
  position: absolute;
  top: 0;
  left: 0;
  cursor: default; }

.ui-datepicker {
  width: 17em;
  padding: .2em .2em 0;
  display: none; }

.ui-datepicker .ui-datepicker-header {
  position: relative;
  padding: .2em 0; }

.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
  position: absolute;
  top: 2px;
  width: 1.8em;
  height: 1.8em; }

.ui-datepicker .ui-datepicker-prev-hover, .ui-datepicker .ui-datepicker-next-hover {
  top: 1px; }

.ui-datepicker .ui-datepicker-prev {
  left: 2px; }

.ui-datepicker .ui-datepicker-next {
  right: 2px; }

.ui-datepicker .ui-datepicker-prev-hover {
  left: 1px; }

.ui-datepicker .ui-datepicker-next-hover {
  right: 1px; }

.ui-datepicker .ui-datepicker-prev span, .ui-datepicker .ui-datepicker-next span {
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -8px;
  top: 50%;
  margin-top: -8px; }

.ui-datepicker .ui-datepicker-title {
  margin: 0 2.3em;
  line-height: 1.8em;
  text-align: center; }

.ui-datepicker .ui-datepicker-title select {
  font-size: 1em;
  margin: 1px 0; }

.ui-datepicker select.ui-datepicker-month, .ui-datepicker select.ui-datepicker-year {
  width: 45%; }

.ui-datepicker table {
  width: 100%;
  font-size: 14px;
  line-height: 18px;
  border-collapse: collapse;
  margin: 0 0 .4em; }

.ui-datepicker th {
  padding: .7em .3em;
  text-align: center;
  font-weight: bold;
  border: 0; }

.ui-datepicker td {
  border: 0;
  padding: 1px; }

.ui-datepicker td span, .ui-datepicker td a {
  display: block;
  padding: .2em;
  text-align: right;
  text-decoration: none; }

.ui-datepicker .ui-datepicker-buttonpane {
  background-image: none;
  margin: .7em 0 0 0;
  padding: 0 .2em;
  border-left: 0;
  border-right: 0;
  border-bottom: 0; }

.ui-datepicker .ui-datepicker-buttonpane button {
  float: right;
  margin: .5em .2em .4em;
  cursor: pointer;
  padding: .2em .6em .3em .6em;
  width: auto;
  overflow: visible; }

.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
  float: left; }

.ui-datepicker.ui-datepicker-multi {
  width: auto; }

.ui-datepicker-multi .ui-datepicker-group {
  float: left; }

.ui-datepicker-multi .ui-datepicker-group table {
  width: 95%;
  margin: 0 auto .4em; }

.ui-datepicker-multi-2 .ui-datepicker-group {
  width: 50%; }

.ui-datepicker-multi-3 .ui-datepicker-group {
  width: 33.3%; }

.ui-datepicker-multi-4 .ui-datepicker-group {
  width: 25%; }

.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header, .ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
  border-left-width: 0; }

.ui-datepicker-multi .ui-datepicker-buttonpane {
  clear: left; }

.ui-datepicker-row-break {
  clear: both;
  width: 100%;
  font-size: 0; }

.ui-datepicker-rtl {
  direction: rtl; }

.ui-datepicker-rtl .ui-datepicker-prev {
  right: 2px;
  left: auto; }

.ui-datepicker-rtl .ui-datepicker-next {
  left: 2px;
  right: auto; }

.ui-datepicker-rtl .ui-datepicker-prev:hover {
  right: 1px;
  left: auto; }

.ui-datepicker-rtl .ui-datepicker-next:hover {
  left: 1px;
  right: auto; }

.ui-datepicker-rtl .ui-datepicker-buttonpane {
  clear: right; }

.ui-datepicker-rtl .ui-datepicker-buttonpane button {
  float: left; }

.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current, .ui-datepicker-rtl .ui-datepicker-group {
  float: right; }

.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header, .ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
  border-right-width: 0;
  border-left-width: 1px; }

.ui-menu {
  list-style: none;
  padding: 0;
  margin: 0;
  display: block;
  outline: none; }

.ui-menu .ui-menu {
  position: absolute; }

.ui-menu .ui-menu-item {
  position: relative;
  margin: 0;
  padding: 3px 1em 3px .4em;
  cursor: pointer;
  min-height: 0;
  list-style-image: url("data:image/gif;base64,R0lGODlhAQABAIAAAAAAAP///yH5BAEAAAAALAAAAAABAAEAAAIBRAA7"); }

.ui-menu .ui-menu-divider {
  margin: 5px 0;
  height: 0;
  font-size: 0;
  line-height: 0;
  border-width: 1px 0 0 0; }

.ui-menu .ui-state-focus, .ui-menu .ui-state-active {
  margin: -1px; }

.ui-menu-icons {
  position: relative; }

.ui-menu-icons .ui-menu-item {
  padding-left: 2em; }

.ui-menu .ui-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: .2em;
  margin: auto 0; }

.ui-menu .ui-menu-icon {
  left: auto;
  right: 0; }

.ui-widget {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"; }

.ui-widget .ui-widget {
  font-size: 1em; }

.ui-widget input, .ui-widget select, .ui-widget textarea, .ui-widget button {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1em; }

.ui-widget-content {
  border: 1px solid #dae0e6;
  background: #fff;
  color: #325885; }

.ui-widget-content a {
  color: #333; }

.ui-widget-header {
  border: 1px solid #ddd;
  background: #fafbfc;
  color: #325885;
  font-weight: bold; }

.ui-widget-header a {
  color: #333; }

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default {
  border: 1px solid #dae0e6;
  background: #fafbfc;
  font-weight: 600;
  color: #454545;
  text-align: center;
  border-radius: 3px; }

.ui-state-default a, .ui-state-default a:link, .ui-state-default a:visited {
  color: #454545;
  text-decoration: none; }

.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus {
  border: 1px solid #ccc;
  background: #ededed;
  color: #2b2b2b; }

.ui-state-hover a, .ui-state-hover a:hover, .ui-state-hover a:link, .ui-state-hover a:visited, .ui-state-focus a, .ui-state-focus a:hover, .ui-state-focus a:link, .ui-state-focus a:visited {
  color: #2b2b2b;
  text-decoration: none; }

.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active {
  border: 1px solid #00aff3;
  background: #00aff3;
  color: #fff; }

.ui-state-active a, .ui-state-active a:link, .ui-state-active a:visited {
  color: #fff;
  text-decoration: none; }

.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
  border: 1px solid #325885;
  background: #325885;
  color: #fff; }

.ui-state-highlight a, .ui-widget-content .ui-state-highlight a, .ui-widget-header .ui-state-highlight a {
  color: #777620; }

.ui-state-error, .ui-widget-content .ui-state-error, .ui-widget-header .ui-state-error {
  border: 1px solid #f1a899;
  background: #fddfdf;
  color: #5f3f3f; }

.ui-state-error a, .ui-widget-content .ui-state-error a, .ui-widget-header .ui-state-error a {
  color: #5f3f3f; }

.ui-state-error-text, .ui-widget-content .ui-state-error-text, .ui-widget-header .ui-state-error-text {
  color: #5f3f3f; }

.ui-priority-primary, .ui-widget-content .ui-priority-primary, .ui-widget-header .ui-priority-primary {
  font-weight: bold; }

.ui-priority-secondary, .ui-widget-content .ui-priority-secondary, .ui-widget-header .ui-priority-secondary {
  opacity: .7;
  filter: Alpha(Opacity=70);
  font-weight: normal; }

.ui-state-disabled, .ui-widget-content .ui-state-disabled, .ui-widget-header .ui-state-disabled {
  opacity: .35;
  filter: Alpha(Opacity=35);
  background-image: none; }

.ui-state-disabled .ui-icon {
  filter: Alpha(Opacity=35); }

.ui-icon {
  width: 16px;
  height: 16px; }

.ui-icon, .ui-widget-content .ui-icon {
  background-image: url("../images/ui-icons_444444_256x240.png"); }

.ui-widget-header .ui-icon {
  background-image: url("../images/ui-icons_444444_256x240.png"); }

.ui-state-default .ui-icon {
  background-image: url("../images/ui-icons_777777_256x240.png"); }

.ui-state-hover .ui-icon, .ui-state-focus .ui-icon {
  background-image: url("../images/ui-icons_555555_256x240.png"); }

.ui-state-active .ui-icon {
  background-image: url("../images/ui-icons_ffffff_256x240.png"); }

.ui-state-highlight .ui-icon {
  background-image: url("../images/ui-icons_777620_256x240.png"); }

.ui-state-error .ui-icon, .ui-state-error-text .ui-icon {
  background-image: url("../images/ui-icons_cc0000_256x240.png"); }

.ui-icon-blank {
  background-position: 16px 16px; }

.ui-icon-carat-1-n {
  background-position: 0 0; }

.ui-icon-carat-1-ne {
  background-position: -16px 0; }

.ui-icon-carat-1-e {
  background-position: -32px 0; }

.ui-icon-carat-1-se {
  background-position: -48px 0; }

.ui-icon-carat-1-s {
  background-position: -64px 0; }

.ui-icon-carat-1-sw {
  background-position: -80px 0; }

.ui-icon-carat-1-w {
  background-position: -96px 0; }

.ui-icon-carat-1-nw {
  background-position: -112px 0; }

.ui-icon-carat-2-n-s {
  background-position: -128px 0; }

.ui-icon-carat-2-e-w {
  background-position: -144px 0; }

.ui-icon-triangle-1-n {
  background-position: 0 -16px; }

.ui-icon-triangle-1-ne {
  background-position: -16px -16px; }

.ui-icon-triangle-1-e {
  background-position: -32px -16px; }

.ui-icon-triangle-1-se {
  background-position: -48px -16px; }

.ui-icon-triangle-1-s {
  background-position: -64px -16px; }

.ui-icon-triangle-1-sw {
  background-position: -80px -16px; }

.ui-icon-triangle-1-w {
  background-position: -96px -16px; }

.ui-icon-triangle-1-nw {
  background-position: -112px -16px; }

.ui-icon-triangle-2-n-s {
  background-position: -128px -16px; }

.ui-icon-triangle-2-e-w {
  background-position: -144px -16px; }

.ui-icon-arrow-1-n {
  background-position: 0 -32px; }

.ui-icon-arrow-1-ne {
  background-position: -16px -32px; }

.ui-icon-arrow-1-e {
  background-position: -32px -32px; }

.ui-icon-arrow-1-se {
  background-position: -48px -32px; }

.ui-icon-arrow-1-s {
  background-position: -64px -32px; }

.ui-icon-arrow-1-sw {
  background-position: -80px -32px; }

.ui-icon-arrow-1-w {
  background-position: -96px -32px; }

.ui-icon-arrow-1-nw {
  background-position: -112px -32px; }

.ui-icon-arrow-2-n-s {
  background-position: -128px -32px; }

.ui-icon-arrow-2-ne-sw {
  background-position: -144px -32px; }

.ui-icon-arrow-2-e-w {
  background-position: -160px -32px; }

.ui-icon-arrow-2-se-nw {
  background-position: -176px -32px; }

.ui-icon-arrowstop-1-n {
  background-position: -192px -32px; }

.ui-icon-arrowstop-1-e {
  background-position: -208px -32px; }

.ui-icon-arrowstop-1-s {
  background-position: -224px -32px; }

.ui-icon-arrowstop-1-w {
  background-position: -240px -32px; }

.ui-icon-arrowthick-1-n {
  background-position: 0 -48px; }

.ui-icon-arrowthick-1-ne {
  background-position: -16px -48px; }

.ui-icon-arrowthick-1-e {
  background-position: -32px -48px; }

.ui-icon-arrowthick-1-se {
  background-position: -48px -48px; }

.ui-icon-arrowthick-1-s {
  background-position: -64px -48px; }

.ui-icon-arrowthick-1-sw {
  background-position: -80px -48px; }

.ui-icon-arrowthick-1-w {
  background-position: -96px -48px; }

.ui-icon-arrowthick-1-nw {
  background-position: -112px -48px; }

.ui-icon-arrowthick-2-n-s {
  background-position: -128px -48px; }

.ui-icon-arrowthick-2-ne-sw {
  background-position: -144px -48px; }

.ui-icon-arrowthick-2-e-w {
  background-position: -160px -48px; }

.ui-icon-arrowthick-2-se-nw {
  background-position: -176px -48px; }

.ui-icon-arrowthickstop-1-n {
  background-position: -192px -48px; }

.ui-icon-arrowthickstop-1-e {
  background-position: -208px -48px; }

.ui-icon-arrowthickstop-1-s {
  background-position: -224px -48px; }

.ui-icon-arrowthickstop-1-w {
  background-position: -240px -48px; }

.ui-icon-arrowreturnthick-1-w {
  background-position: 0 -64px; }

.ui-icon-arrowreturnthick-1-n {
  background-position: -16px -64px; }

.ui-icon-arrowreturnthick-1-e {
  background-position: -32px -64px; }

.ui-icon-arrowreturnthick-1-s {
  background-position: -48px -64px; }

.ui-icon-arrowreturn-1-w {
  background-position: -64px -64px; }

.ui-icon-arrowreturn-1-n {
  background-position: -80px -64px; }

.ui-icon-arrowreturn-1-e {
  background-position: -96px -64px; }

.ui-icon-arrowreturn-1-s {
  background-position: -112px -64px; }

.ui-icon-arrowrefresh-1-w {
  background-position: -128px -64px; }

.ui-icon-arrowrefresh-1-n {
  background-position: -144px -64px; }

.ui-icon-arrowrefresh-1-e {
  background-position: -160px -64px; }

.ui-icon-arrowrefresh-1-s {
  background-position: -176px -64px; }

.ui-icon-arrow-4 {
  background-position: 0 -80px; }

.ui-icon-arrow-4-diag {
  background-position: -16px -80px; }

.ui-icon-extlink {
  background-position: -32px -80px; }

.ui-icon-newwin {
  background-position: -48px -80px; }

.ui-icon-refresh {
  background-position: -64px -80px; }

.ui-icon-shuffle {
  background-position: -80px -80px; }

.ui-icon-transfer-e-w {
  background-position: -96px -80px; }

.ui-icon-transferthick-e-w {
  background-position: -112px -80px; }

.ui-icon-folder-collapsed {
  background-position: 0 -96px; }

.ui-icon-folder-open {
  background-position: -16px -96px; }

.ui-icon-document {
  background-position: -32px -96px; }

.ui-icon-document-b {
  background-position: -48px -96px; }

.ui-icon-note {
  background-position: -64px -96px; }

.ui-icon-mail-closed {
  background-position: -80px -96px; }

.ui-icon-mail-open {
  background-position: -96px -96px; }

.ui-icon-suitcase {
  background-position: -112px -96px; }

.ui-icon-comment {
  background-position: -128px -96px; }

.ui-icon-person {
  background-position: -144px -96px; }

.ui-icon-print {
  background-position: -160px -96px; }

.ui-icon-trash {
  background-position: -176px -96px; }

.ui-icon-locked {
  background-position: -192px -96px; }

.ui-icon-unlocked {
  background-position: -208px -96px; }

.ui-icon-bookmark {
  background-position: -224px -96px; }

.ui-icon-tag {
  background-position: -240px -96px; }

.ui-icon-home {
  background-position: 0 -112px; }

.ui-icon-flag {
  background-position: -16px -112px; }

.ui-icon-calendar {
  background-position: -32px -112px; }

.ui-icon-cart {
  background-position: -48px -112px; }

.ui-icon-pencil {
  background-position: -64px -112px; }

.ui-icon-clock {
  background-position: -80px -112px; }

.ui-icon-disk {
  background-position: -96px -112px; }

.ui-icon-calculator {
  background-position: -112px -112px; }

.ui-icon-zoomin {
  background-position: -128px -112px; }

.ui-icon-zoomout {
  background-position: -144px -112px; }

.ui-icon-search {
  background-position: -160px -112px; }

.ui-icon-wrench {
  background-position: -176px -112px; }

.ui-icon-gear {
  background-position: -192px -112px; }

.ui-icon-heart {
  background-position: -208px -112px; }

.ui-icon-star {
  background-position: -224px -112px; }

.ui-icon-link {
  background-position: -240px -112px; }

.ui-icon-cancel {
  background-position: 0 -128px; }

.ui-icon-plus {
  background-position: -16px -128px; }

.ui-icon-plusthick {
  background-position: -32px -128px; }

.ui-icon-minus {
  background-position: -48px -128px; }

.ui-icon-minusthick {
  background-position: -64px -128px; }

.ui-icon-close {
  background-position: -80px -128px; }

.ui-icon-closethick {
  background-position: -96px -128px; }

.ui-icon-key {
  background-position: -112px -128px; }

.ui-icon-lightbulb {
  background-position: -128px -128px; }

.ui-icon-scissors {
  background-position: -144px -128px; }

.ui-icon-clipboard {
  background-position: -160px -128px; }

.ui-icon-copy {
  background-position: -176px -128px; }

.ui-icon-contact {
  background-position: -192px -128px; }

.ui-icon-image {
  background-position: -208px -128px; }

.ui-icon-video {
  background-position: -224px -128px; }

.ui-icon-script {
  background-position: -240px -128px; }

.ui-icon-alert {
  background-position: 0 -144px; }

.ui-icon-info {
  background-position: -16px -144px; }

.ui-icon-notice {
  background-position: -32px -144px; }

.ui-icon-help {
  background-position: -48px -144px; }

.ui-icon-check {
  background-position: -64px -144px; }

.ui-icon-bullet {
  background-position: -80px -144px; }

.ui-icon-radio-on {
  background-position: -96px -144px; }

.ui-icon-radio-off {
  background-position: -112px -144px; }

.ui-icon-pin-w {
  background-position: -128px -144px; }

.ui-icon-pin-s {
  background-position: -144px -144px; }

.ui-icon-play {
  background-position: 0 -160px; }

.ui-icon-pause {
  background-position: -16px -160px; }

.ui-icon-seek-next {
  background-position: -32px -160px; }

.ui-icon-seek-prev {
  background-position: -48px -160px; }

.ui-icon-seek-end {
  background-position: -64px -160px; }

.ui-icon-seek-start {
  background-position: -80px -160px; }

.ui-icon-seek-first {
  background-position: -80px -160px; }

.ui-icon-stop {
  background-position: -96px -160px; }

.ui-icon-eject {
  background-position: -112px -160px; }

.ui-icon-volume-off {
  background-position: -128px -160px; }

.ui-icon-volume-on {
  background-position: -144px -160px; }

.ui-icon-power {
  background-position: 0 -176px; }

.ui-icon-signal-diag {
  background-position: -16px -176px; }

.ui-icon-signal {
  background-position: -32px -176px; }

.ui-icon-battery-0 {
  background-position: -48px -176px; }

.ui-icon-battery-1 {
  background-position: -64px -176px; }

.ui-icon-battery-2 {
  background-position: -80px -176px; }

.ui-icon-battery-3 {
  background-position: -96px -176px; }

.ui-icon-circle-plus {
  background-position: 0 -192px; }

.ui-icon-circle-minus {
  background-position: -16px -192px; }

.ui-icon-circle-close {
  background-position: -32px -192px; }

.ui-icon-circle-triangle-e {
  background-position: -48px -192px; }

.ui-icon-circle-triangle-s {
  background-position: -64px -192px; }

.ui-icon-circle-triangle-w {
  background-position: -80px -192px; }

.ui-icon-circle-triangle-n {
  background-position: -96px -192px; }

.ui-icon-circle-arrow-e {
  background-position: -112px -192px; }

.ui-icon-circle-arrow-s {
  background-position: -128px -192px; }

.ui-icon-circle-arrow-w {
  background-position: -144px -192px; }

.ui-icon-circle-arrow-n {
  background-position: -160px -192px; }

.ui-icon-circle-zoomin {
  background-position: -176px -192px; }

.ui-icon-circle-zoomout {
  background-position: -192px -192px; }

.ui-icon-circle-check {
  background-position: -208px -192px; }

.ui-icon-circlesmall-plus {
  background-position: 0 -208px; }

.ui-icon-circlesmall-minus {
  background-position: -16px -208px; }

.ui-icon-circlesmall-close {
  background-position: -32px -208px; }

.ui-icon-squaresmall-plus {
  background-position: -48px -208px; }

.ui-icon-squaresmall-minus {
  background-position: -64px -208px; }

.ui-icon-squaresmall-close {
  background-position: -80px -208px; }

.ui-icon-grip-dotted-vertical {
  background-position: 0 -224px; }

.ui-icon-grip-dotted-horizontal {
  background-position: -16px -224px; }

.ui-icon-grip-solid-vertical {
  background-position: -32px -224px; }

.ui-icon-grip-solid-horizontal {
  background-position: -48px -224px; }

.ui-icon-gripsmall-diagonal-se {
  background-position: -64px -224px; }

.ui-icon-grip-diagonal-se {
  background-position: -80px -224px; }

.ui-corner-all, .ui-corner-top, .ui-corner-left, .ui-corner-tl {
  border-top-left-radius: 3px; }

.ui-corner-all, .ui-corner-top, .ui-corner-right, .ui-corner-tr {
  border-top-right-radius: 3px; }

.ui-corner-all, .ui-corner-bottom, .ui-corner-left, .ui-corner-bl {
  border-bottom-left-radius: 3px; }

.ui-corner-all, .ui-corner-bottom, .ui-corner-right, .ui-corner-br {
  border-bottom-right-radius: 3px; }

.ui-widget-overlay {
  background: #aaa;
  opacity: .3;
  filter: Alpha(Opacity=30); }

.ui-widget-shadow {
  margin: 0 0 0 0;
  padding: 5px;
  background: #666;
  opacity: .3;
  filter: Alpha(Opacity=30);
  border-radius: 8px; }

/* ---------------------------------------------------------
   1. Embedded fonts styles
   2. Custom form styles
   3. Common site styles
   ---------------------------------------------------------*/
.dropdown .dropdown-menu, .step-widget .step-list, .footer-nav, .list, .label-list {
  margin: 0;
  padding: 0;
  list-style: none; }

.clearfix:after, .header:after {
  content: '';
  display: block;
  clear: both; }

.ellipsis {
  white-space: nowrap;
  /* 1 */
  text-overflow: ellipsis;
  /* 2 */
  overflow: hidden; }

/* ---------------------------------------------------------
   1. Embedded fonts styles
   ---------------------------------------------------------*/
@font-face {
  font-family: 'icomoon';
  src: url("../fonts/icomoon.eot?is72a9");
  src: url("../fonts/icomoon.eot?is72a9#iefix") format("embedded-opentype"), url("../fonts/icomoon.ttf?is72a9") format("truetype"), url("../fonts/icomoon.woff?is72a9") format("woff"), url("../fonts/icomoon.svg?is72a9#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block; }

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

.icon-check:before {
  content: "\e91c"; }

.icon-arrow-right:before {
  content: "\e91b"; }

.icon-question:before {
  content: "\e91a"; }

.icon-arrow-left:before {
  content: "\e919"; }

.icon-phone:before {
  content: "\e918"; }

.icon-help:before {
  content: "\e917"; }

.icon-save:before {
  content: "\e916"; }

.icon-admission:before {
  content: "\e900"; }

.icon-arrow-down:before {
  content: "\e901"; }

.icon-calendar:before {
  content: "\e902"; }

.icon-chat:before {
  content: "\e903"; }

.icon-checkmark:before {
  content: "\e904"; }

.icon-close:before {
  content: "\e905"; }

.icon-create:before {
  content: "\e906"; }

.icon-dollor:before {
  content: "\e907"; }

.icon-drop-file:before {
  content: "\e908"; }

.icon-ellipsis-v:before {
  content: "\e909"; }

.icon-error:before {
  content: "\e90a"; }

.icon-file:before {
  content: "\e90b"; }

.icon-global:before {
  content: "\e90c"; }

.icon-long-arrow-right:before {
  content: "\e90d"; }

.icon-map-pin:before {
  content: "\e90e"; }

.icon-medicare:before {
  content: "\e90f"; }

.icon-medication:before {
  content: "\e910"; }

.icon-minus:before {
  content: "\e911"; }

.icon-mobile:before {
  content: "\e912"; }

.icon-plus:before {
  content: "\e913"; }

.icon-success:before {
  content: "\e914"; }

.icon-support:before {
  content: "\e915"; }

/* ---------------------------------------------------------
   2. Custom form styles
   ---------------------------------------------------------*/
/* custom select styles */
.jcf-select {
  position: relative;
  border: 1px solid #dae0e6;
  background: #fafbfc;
  margin: 0;
  padding: 0.687rem 0.75rem;
  padding-right: 2.8125rem !important;
  font-size: 0.75rem;
  line-height: 1.333;
  color: #325885;
  border-radius: 4px;
  display: block;
  width: 100%;
  -webkit-transition: border 0.3s ease, color 0.3s ease, background 0.3s ease;
  transition: border 0.3s ease, color 0.3s ease, background 0.3s ease;
  text-align: left;
  height: 40px; }
  .jcf-select.jcf-select-lg {
    padding: 0.9375rem 1rem !important;
    height: 48px; }
  .jcf-select.jcf-select-sm {
    padding: 0.375rem 0.5rem !important;
    height: 30px; }
  .jcf-select.jcf-select-sel-flag {
    padding-right: 0.625rem !important;
    width: 48px;
    border: 0;
    background: none; }
    .jcf-select.jcf-select-sel-flag .jcf-select-opener {
      width: 8px;
      font-size: 9px; }
  .jcf-select.jcf-error,
  .error .jcf-select {
    border-color: #f63b2c; }
    .jcf-select.jcf-error .jcf-select-opener,
    .error .jcf-select .jcf-select-opener {
      color: #f63b2c; }
  .jcf-select.jcf-success,
  .success .jcf-select {
    border-color: #67d32e; }
  .jcf-select select {
    z-index: 1;
    left: 0;
    top: 0; }
  .jcf-select .jcf-select-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: default;
    display: block;
    color: #325885; }
    .jcf-select .jcf-select-text .jcf-option-first {
      color: #93a4b8; }
  .jcf-select .jcf-select-opener {
    position: absolute;
    text-align: center;
    width: 40px;
    bottom: 0;
    right: 0;
    top: 0;
    color: #93a4b8;
    font-size: 0.625rem; }
    .jcf-select .jcf-select-opener:before {
      position: absolute;
      display: block;
      content: "";
      top: 50%;
      bottom: auto;
      left: 50%;
      right: auto; }
    .jcf-select .jcf-select-opener:before {
      font-family: 'icomoon';
      content: "";
      font-weight: normal;
      -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%);
      pointer-events: none; }
  .jcf-select .jcf-select-drop {
    position: absolute;
    margin-top: 0;
    z-index: 9999;
    top: 100%;
    left: -1px;
    right: -1px; }
  .jcf-select .jcf-drop-flipped {
    bottom: 100%;
    top: auto; }

body > .jcf-select-drop {
  position: absolute;
  margin: -1px 0 0;
  z-index: 9999; }
  body > .jcf-select-drop.jcf-drop-flipped {
    margin: 1px 0 0; }

.jcf-select-drop .jcf-select-drop-content {
  border: 1px solid #00b0f0; }

/* multiple select styles */
.jcf-list-box {
  overflow: hidden;
  display: inline-block;
  border: 1px solid #b8c3c9;
  min-width: 200px;
  margin: 0 15px; }

/* select options styles */
.jcf-list {
  display: inline-block;
  vertical-align: top;
  position: relative;
  background: #fff;
  line-height: 14px;
  font-size: 12px;
  width: 100%; }
  .jcf-list .jcf-list-content {
    vertical-align: top;
    display: inline-block;
    overflow: auto;
    width: 100%; }
  .jcf-list ul {
    list-style: none;
    padding: 0;
    margin: 0; }
    .jcf-list ul li {
      overflow: hidden;
      display: block; }
  .jcf-list .jcf-overflow {
    overflow: auto; }
  .jcf-list .jcf-option {
    overflow: hidden;
    cursor: default;
    display: block;
    padding: 0.5rem 0.625rem;
    color: #656565;
    height: 1%; }
  .jcf-list .jcf-disabled {
    background: #fff !important;
    color: #aaa !important; }
  .jcf-list .jcf-optgroup-caption {
    white-space: nowrap;
    font-weight: bold;
    display: block;
    padding: 5px 9px;
    cursor: default;
    color: #000; }
  .jcf-list .jcf-optgroup .jcf-option {
    padding-left: 30px; }

.jcf-select-drop .jcf-hover,
.jcf-list-box .jcf-selected {
  background: #00aff3;
  color: #fff; }

/* custom file input styles */
.jcf-file {
  font-size: 0.75rem;
  line-height: 1.125rem;
  font-weight: 600;
  display: block;
  width: 100%;
  position: relative;
  overflow: hidden;
  background: #fff;
  padding: 0.9375rem;
  border: 2px dashed #dae0e6;
  border-radius: 4px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row nowrap;
          flex-flow: row nowrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-align: left; }
  .jcf-file .jcf-real-element {
    position: absolute;
    font-size: 200px;
    height: 200px;
    margin: 0;
    right: 0;
    top: 0; }
  .jcf-file .jcf-fake-input {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    overflow: hidden;
    color: #325885;
    position: relative; }
    .jcf-file .jcf-fake-input:after {
      display: inline-block;
      vertical-align: top;
      content: '\00a0 Browse';
      color: #00b0f0;
      -webkit-box-ordinal-group: 2;
          -ms-flex-order: 1;
              order: 1; }
    .jcf-file .jcf-fake-input:before {
      content: 'Supported file type: JPG, PNG, or PDF';
      display: block;
      color: #b3b6c2;
      font-weight: 500;
      width: 100%; }
  .jcf-file .jcf-upload-button {
    display: block;
    overflow: hidden;
    font-weight: 500;
    color: #b3b6c2;
    -webkit-box-ordinal-group: 0;
        -ms-flex-order: -1;
            order: -1;
    margin: 0 15px 0 0; }
    .jcf-file .jcf-upload-button:before {
      font-family: 'icomoon';
      content: "";
      font-weight: normal;
      color: #93a4b8;
      font-size: 1.375rem;
      display: inline-block;
      vertical-align: top; }

/* common custom form elements styles */
.jcf-disabled .jcf-select-text {
  color: rgba(50, 88, 133, 0.4) !important; }

.jcf-focus,
.jcf-focus * {
  border-color: #00b0f0; }

.form-group {
  position: relative; }
  .form-group .input-wrap {
    position: relative; }
  .form-group .icon-box {
    position: absolute;
    top: 1px;
    left: 1px;
    bottom: 1px;
    width: 48px;
    border-radius: 4px 0 0 4px;
    z-index: 4;
    pointer-events: none;
    background: #eef7fb;
    border-right: 1px solid #dae0e6;
    font-size: 1rem;
    color: #00b0f0; }
    .form-group .icon-box .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      -webkit-transform: translate(-50%, -50%);
          -ms-transform: translate(-50%, -50%);
              transform: translate(-50%, -50%); }
    .form-group .icon-box ~ .form-control,
    .form-group .icon-box ~ .jcf-select {
      padding-left: 3.8125rem !important; }

/* ---------------------------------------------------------
   3. Сommon site styles
   ---------------------------------------------------------*/
.header {
  display: table-header-group;
  width: 100%;
  height: 1%;
  position: relative;
  box-shadow: 0 2px 2px rgba(73, 92, 117, 0.1);
  background: #fff; }
  .header .container-fluid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    position: relative;
    padding-top: 0.9375rem;
    padding-bottom: 0.8125rem;
    z-index: 99; }
    @media (min-width: 768px) {
      .header .container-fluid {
        padding: 1rem 1.5rem; } }
    @media (min-width: 992px) {
      .header .container-fluid {
        padding: 1rem 2.5625rem; } }
  .header .logo {
    width: 189px;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    margin: 0.125rem 0.625rem 0 0; }
    @media (min-width: 992px) {
      .header .logo {
        width: 235px;
        margin: 0.125rem 1.5rem 0 0; } }
    .header .logo a {
      display: block; }
    .header .logo img {
      display: block;
      max-width: 100%;
      height: auto; }
  @media (min-width: 992px) {
    .header .right-col {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; } }
  .header .right-col .support-link {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: relative;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 600;
    color: #9397a9;
    padding: 1.25rem 0; }
    @media (min-width: 992px) {
      .header .right-col .support-link {
        margin: 0 2rem;
        padding: 0; } }
    .header .right-col .support-link .icon {
      display: inline-block;
      font-size: 15px;
      line-height: 1;
      margin: 0 0.75rem 0 0; }
  .header .user-info {
    display: block;
    color: #325885;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 500;
    border-top: 1px solid #dae0e6;
    padding: 1.25rem 1.5rem 0.3125rem;
    margin: 0 -1.5rem; }
    @media (min-width: 992px) {
      .header .user-info {
        max-width: 160px;
        margin: 0;
        border: 0;
        padding: 0; } }
    .header .user-info .author {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      width: 40px;
      height: 40px;
      -ms-flex-negative: 0;
          flex-shrink: 0;
      border-radius: 50px;
      font-size: 0.875rem;
      line-height: 1.0625rem;
      font-weight: 600;
      text-transform: uppercase;
      text-align: center;
      color: #00b0f0;
      margin: 0 0.625rem 0 0;
      background: rgba(0, 176, 240, 0.1); }
    .header .user-info .title {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row nowrap;
              flex-flow: row nowrap;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      color: #325885; }
      .header .user-info .title:after {
        font-family: 'icomoon';
        content: "";
        font-weight: normal;
        font-size: 10px;
        line-height: 1;
        border: 0;
        margin: -0.125rem 0 0 0.5rem;
        position: absolute;
        right: 0; }
        @media (min-width: 992px) {
          .header .user-info .title:after {
            display: inline-block;
            vertical-align: top;
            position: static; } }
    .header .user-info .dropdown-menu {
      position: static;
      width: 100%;
      margin: 0.3125rem 0 0; }
      @media (min-width: 992px) {
        .header .user-info .dropdown-menu {
          position: absolute;
          width: 160px;
          left: auto;
          right: 0;
          top: 100%; } }
  .header .navbar {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -ms-flex-preferred-size: 0;
        flex-basis: 0; }
    .header .navbar .nav-opener {
      position: absolute;
      top: 19px;
      right: 20px;
      border: 0;
      padding: 0; }

.navbar {
  padding: 0;
  position: static; }
  @media (min-width: 992px) {
    .navbar {
      position: relative;
      margin: 0;
      box-shadow: none; } }
  .navbar .navbar-toggler {
    width: 20px;
    height: 20px;
    border: none;
    position: relative;
    overflow: hidden; }
    .navbar .navbar-toggler:before {
      position: absolute;
      display: block;
      content: "";
      top: 50%;
      bottom: auto;
      left: 0;
      right: 0; }
    .navbar .navbar-toggler:after {
      position: absolute;
      display: block;
      content: "";
      top: 50%;
      bottom: auto;
      left: 0;
      right: 0; }
    .navbar .navbar-toggler:before, .navbar .navbar-toggler:after {
      height: 2px;
      background: #325885;
      -webkit-transition: color 0.3s ease, -webkit-transform 0.3s ease;
      transition: color 0.3s ease, -webkit-transform 0.3s ease;
      transition: transform 0.3s ease, color 0.3s ease;
      transition: transform 0.3s ease, color 0.3s ease, -webkit-transform 0.3s ease;
      -webkit-transform: rotate(0);
          -ms-transform: rotate(0);
              transform: rotate(0);
      margin: -5px 0 0; }
    .navbar .navbar-toggler:after {
      margin: 5px 0 0; }
    .navbar .navbar-toggler:not([aria-expanded="false"]):before, .navbar .navbar-toggler:not([aria-expanded="false"]):after {
      -webkit-transform: rotate(45deg);
          -ms-transform: rotate(45deg);
              transform: rotate(45deg);
      margin: 0; }
    .navbar .navbar-toggler:not([aria-expanded="false"]):after {
      -webkit-transform: rotate(-45deg);
          -ms-transform: rotate(-45deg);
              transform: rotate(-45deg); }
    .navbar .navbar-toggler:not([aria-expanded="false"]) .navbar-toggler-icon {
      opacity: 0;
      visibility: hidden; }
  .navbar .navbar-toggler-icon {
    -webkit-transition: color 0.3s ease, opacity 0.3s ease, -webkit-transform 0.3s ease;
    transition: color 0.3s ease, opacity 0.3s ease, -webkit-transform 0.3s ease;
    transition: transform 0.3s ease, color 0.3s ease, opacity 0.3s ease;
    transition: transform 0.3s ease, color 0.3s ease, opacity 0.3s ease, -webkit-transform 0.3s ease;
    height: auto;
    width: auto;
    background: none;
    position: absolute;
    top: 50%;
    left: 0;
    right: 0;
    height: 2px;
    background: #325885; }
  .navbar .navbar-collapse {
    padding: 1.25rem 1.5rem;
    box-shadow: 0 2px 2px rgba(73, 92, 117, 0.1);
    background: #fff;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    margin: 0.125rem 0 0;
    overflow-x: hidden;
    overflow-y: auto;
    max-height: calc(100vh - 63px);
    -webkit-transition: all 0.5s ease;
    transition: all 0.5s ease;
    opacity: 0;
    visibility: hidden; }
    @media (min-width: 992px) {
      .navbar .navbar-collapse {
        overflow: visible;
        position: static;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        padding: 0;
        background: none;
        box-shadow: none;
        margin: 0;
        opacity: 1;
        visibility: visible;
        max-height: inherit; } }
    @media (max-width: 991px) {
      .navbar .navbar-collapse {
        height: auto !important;
        display: block !important; } }
    .navbar .navbar-collapse.show, .navbar .navbar-collapse.collapsing {
      opacity: 1;
      visibility: visible; }
  .navbar .navbar-nav {
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 600;
    padding: 0 0 0.625rem;
    margin: 0;
    border-bottom: 1px solid #dae0e6; }
    @media (min-width: 992px) {
      .navbar .navbar-nav {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-flow: row wrap;
                flex-flow: row wrap;
        padding: 0 0.9375rem;
        margin: -1rem -0.625rem;
        border: 0; } }
    @media (min-width: 992px) {
      .navbar .navbar-nav li {
        padding: 0 0.625rem;
        margin: 0; } }
    .navbar .navbar-nav li.active a {
      color: #00b0f0; }
      .navbar .navbar-nav li.active a:after {
        visibility: visible;
        opacity: 1; }
    .navbar .navbar-nav a {
      display: block;
      color: #9397a9;
      padding: 0.9375rem 0;
      position: relative;
      text-decoration: none; }
      @media (min-width: 992px) {
        .navbar .navbar-nav a {
          padding: 1.75rem 0.5rem; } }
      .navbar .navbar-nav a:hover {
        color: #00b0f0; }
        .navbar .navbar-nav a:hover:after {
          visibility: visible;
          opacity: 1; }
      .navbar .navbar-nav a:after {
        visibility: hidden;
        opacity: 0;
        -webkit-transition: linear 0.3s;
        transition: linear 0.3s;
        position: absolute;
        left: -24px;
        top: 0;
        bottom: 0;
        content: "";
        width: 2px;
        background: #00b0f0; }
        @media (min-width: 992px) {
          .navbar .navbar-nav a:after {
            bottom: 0;
            right: 0;
            left: auto;
            top: auto;
            height: 2px;
            width: 100%; } }

/* main container styles */
#wrapper {
  display: table;
  table-layout: fixed;
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden; }

/* main informative part styles */
#main {
  position: relative;
  z-index: 10;
  padding: 1.4375rem 0; }
  @media (min-width: 768px) {
    #main {
      padding: 2.5rem 0; } }
  @media (min-width: 992px) {
    #main {
      padding: 3.5rem 0; } }

html {
  height: 100%; }

body {
  height: 100%;
  min-width: 320px;
  -ms-overflow-style: scrollbar; }

a {
  -webkit-transition: color 0.3s ease, background 0.3s ease, border 0.3s ease;
  transition: color 0.3s ease, background 0.3s ease, border 0.3s ease; }

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
  display: block; }

h1, .h1 {
  line-height: 1.3333333333; }

h2, .h2 {
  line-height: 1.6; }

h3, .h3 {
  line-height: 1.6666666667; }

h4, .h4 {
  line-height: 1.5384615385; }

.hidden {
  position: absolute;
  left: -9999px;
  top: auto;
  width: 1px;
  height: 1px;
  overflow: hidden; }

.accessibility {
  position: absolute;
  left: -10000px;
  top: auto;
  width: 1px;
  height: 0;
  overflow: hidden; }

.accessibility:focus {
  position: static;
  width: auto;
  height: auto; }

.form-control {
  box-shadow: none;
  outline: none;
  margin: 0;
  display: block; }
  .form-control.signature-filed {
    border-radius: 0;
    text-align: center;
    border-color: transparent transparent #325885; }
  .error .form-control {
    border-color: #f63b2c; }
  .success .form-control {
    border-color: #67d32e; }

.alert {
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 400;
  margin: 1rem 0; }
  .alert .icon {
    font-size: 18px;
    line-height: inherit;
    display: inline-block;
    vertical-align: top;
    margin: -1px 4px 0 0; }

.alert-dismissible {
  padding-top: 1.375rem;
  padding-bottom: 1.375rem; }
  .alert-dismissible .close {
    padding: 0.5rem;
    font-size: 0.625rem; }

.btn {
  min-width: 84px;
  text-transform: capitalize; }
  .btn:not([class^="btn-outline-"]):not([class*="btn-outline-"]):not(.btn-link) {
    color: #fff; }
  .btn.btn-lg {
    min-width: 100px; }
    @media (min-width: 992px) {
      .btn.btn-lg {
        padding-left: 2rem;
        padding-right: 2rem; } }
  @media (max-width: 767px) {
    .btn.btn-rounded {
      min-width: 32px;
      min-height: 32px;
      text-align: center;
      padding: 0.4375rem; } }
  .btn.btn-secondary {
    background: #fff;
    border-color: #d7dde6;
    color: #325885 !important; }
    .btn.btn-secondary:hover {
      background: #eef2f6 !important; }
    .btn.btn-secondary:active {
      background: #fafbfc !important;
      border-color: #d7dde6 !important; }
  .btn .icon {
    display: inline-block;
    vertical-align: top;
    line-height: inherit;
    font-size: 90%;
    margin: 0 0.375rem 0 0; }

.signin-section {
  overflow: hidden;
  min-height: 100vh;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-flow: column nowrap;
          flex-flow: column nowrap;
  background: #fff; }
  @media (min-width: 992px) {
    .signin-section {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row wrap;
              flex-flow: row wrap; } }
  .signin-section .logo {
    display: block;
    width: 100%;
    max-width: 235px;
    margin: 0 auto 50px; }
    @media (min-width: 768px) {
      .signin-section .logo {
        position: absolute;
        left: 50%;
        -webkit-transform: translateX(-50%);
            -ms-transform: translateX(-50%);
                transform: translateX(-50%);
        top: 60px;
        margin: 0; } }
    @media (min-width: 1260px) {
      .signin-section .logo {
        max-width: 299px;
        top: 118px; } }
    .signin-section .logo img {
      display: block;
      width: 100%;
      height: auto; }
  .signin-section .signin-block {
    text-align: center;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    position: relative;
    background: #fff; }
    @media (min-width: 768px) {
      .signin-section .signin-block {
        padding: 2.5rem 0;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-flow: row wrap;
                flex-flow: row wrap;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center; } }
    @media (min-width: 992px) {
      .signin-section .signin-block {
        -webkit-box-flex: 0;
            -ms-flex-positive: 0;
                flex-grow: 0;
        min-height: inherit;
        -ms-flex-negative: 0;
            flex-shrink: 0;
        width: 450px;
        padding: 6.25rem 0; } }
    @media (min-width: 1260px) {
      .signin-section .signin-block {
        width: 585px; } }
    .signin-section .signin-block .holder {
      overflow: hidden;
      padding: 3rem 1.5rem 1.1875rem;
      width: 100%; }
      @media (min-width: 768px) {
        .signin-section .signin-block .holder {
          padding: 4.375rem 2.5rem 3.125rem; } }
      @media (min-width: 992px) {
        .signin-section .signin-block .holder {
          padding: 5.625rem 3.75rem 3.75rem; } }
      @media (min-width: 1260px) {
        .signin-section .signin-block .holder {
          padding: 7.5rem 8.75rem 4.5625rem; } }
  .signin-section .img-holder {
    width: 100%;
    position: relative;
    overflow: hidden; }
    @media (min-width: 992px) {
      .signin-section .img-holder {
        -webkit-box-flex: 1;
            -ms-flex-positive: 1;
                flex-grow: 1;
        padding-top: 0;
        width: calc(100% - 450px); } }
    @media (min-width: 1260px) {
      .signin-section .img-holder {
        width: calc(100% - 585px); } }
    .signin-section .img-holder .img {
      display: block;
      padding-top: 54%;
      background-size: cover;
      background-position: 50% 0;
      background-repeat: no-repeat; }
      @media (min-width: 992px) {
        .signin-section .img-holder .img {
          padding-top: 0;
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0; } }
  .signin-section h3, .signin-section .h3 {
    margin-bottom: 2rem; }
  .signin-section .btn-holdre {
    padding: 0.5rem 0;
    margin: 0 0 0.9375rem; }
    @media (min-width: 992px) {
      .signin-section .btn-holdre {
        margin: 0 0 2.5rem; } }
  .signin-section .note-text {
    font-size: 0.75rem;
    line-height: 1rem;
    color: #325885;
    padding: 0.5625rem 0 0; }
    @media (min-width: 1260px) {
      .signin-section .note-text {
        margin: 0 0 -0.75rem;
        padding: 0; } }
    .signin-section .note-text a {
      display: inline-block;
      vertical-align: top;
      color: #325885; }
      .signin-section .note-text a:hover {
        color: #00b0f0; }

.dropdown {
  position: relative; }
  .dropdown.align-left .dropdown-menu {
    left: auto !important;
    right: 0; }
  .dropdown.align-left.has-arrow .dropdown-menu:before, .dropdown.align-left.has-arrow .dropdown-menu:after {
    left: 100%;
    -webkit-transform: translateX(-35px);
        -ms-transform: translateX(-35px);
            transform: translateX(-35px); }
  .dropdown.has-arrow .dropdown-menu {
    position: relative; }
    .dropdown.has-arrow .dropdown-menu:after, .dropdown.has-arrow .dropdown-menu:before {
      border: solid transparent;
      content: " ";
      height: 0;
      width: 0;
      position: absolute;
      pointer-events: none; }
    .dropdown.has-arrow .dropdown-menu:after {
      border-width: 6px; }
    .dropdown.has-arrow .dropdown-menu:before {
      border-width: 7px; }
    .dropdown.has-arrow .dropdown-menu:after, .dropdown.has-arrow .dropdown-menu:before {
      bottom: 100%;
      left: 50%; }
    .dropdown.has-arrow .dropdown-menu:after {
      border-color: rgba(255, 255, 255, 0);
      border-bottom-color: #fff;
      margin-left: -6px; }
    .dropdown.has-arrow .dropdown-menu:before {
      border-color: rgba(215, 221, 230, 0);
      border-bottom-color: #d7dde6;
      margin-left: -7px; }
    .dropdown.has-arrow .dropdown-menu:before, .dropdown.has-arrow .dropdown-menu:after {
      left: 20px; }
  .dropdown .dropdown-menu {
    padding: 0.625rem 0;
    margin: 0.9375rem 0 0;
    border: 1px solid #d7dde6;
    box-shadow: 4px 0 8px rgba(73, 92, 117, 0.2);
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 600; }
    .dropdown .dropdown-menu li {
      margin: 0 0 0.25rem; }
      .dropdown .dropdown-menu li:last-child {
        margin: 0; }
      .dropdown .dropdown-menu li a {
        color: #325885;
        display: block;
        padding: 0.375rem 0.625rem;
        -webkit-transition: all 0.3s ease;
        transition: all 0.3s ease; }
        .dropdown .dropdown-menu li a:hover {
          color: #fff;
          background: rgba(0, 176, 240, 0.5); }

.setps-block {
  position: relative; }
  .setps-block .title-text {
    display: block;
    color: #325885; }
  .setps-block .text {
    display: block;
    color: #9397a9; }
  .setps-block .label.color {
    color: #9397a9; }
  .setps-block .step-header {
    position: relative;
    position: relative;
    padding-top: 1rem;
    padding-bottom: 1rem;
    margin-bottom: 0.9375rem;
    z-index: 10; }
    .setps-block .step-header:before {
      position: absolute;
      display: block;
      content: "";
      top: 0;
      bottom: 0;
      left: -9999px;
      right: -9999px;
      background: #fff;
      z-index: -1; }
    @media (min-width: 768px) {
      .setps-block .step-header {
        margin-bottom: 1.25rem; } }
    @media (min-width: 992px) {
      .setps-block .step-header {
        margin-bottom: 2.4375rem; } }
    .setps-block .step-header:before {
      box-shadow: 0 2px 2px rgba(73, 92, 117, 0.1), 0 -1px 0 rgba(147, 164, 184, 0.28); }
    .setps-block .step-header h3, .setps-block .step-header .h3 {
      display: -webkit-inline-box;
      display: -ms-inline-flexbox;
      display: inline-flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row nowrap;
              flex-flow: row nowrap;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
      @media (max-width: 991px) {
        .setps-block .step-header h3, .setps-block .step-header .h3 {
          font-size: 1rem;
          line-height: 1.375rem; } }
      @media (max-width: 767px) {
        .setps-block .step-header h3, .setps-block .step-header .h3 {
          font-size: 0.75rem;
          line-height: 1rem; } }
  .setps-block .step-opener {
    display: inline-block;
    vertical-align: top;
    position: relative;
    padding-right: 16px;
    color: #00aff3; }
    @media (min-width: 375px) {
      .setps-block .step-opener {
        padding-right: 22px; } }
    @media (min-width: 768px) {
      .setps-block .step-opener {
        pointer-events: none;
        padding-right: 5px; } }
    .step-active .setps-block .step-opener:after {
      top: 4px;
      -webkit-transform: rotate(-180deg);
          -ms-transform: rotate(-180deg);
              transform: rotate(-180deg); }
    .setps-block .step-opener:after {
      position: absolute;
      top: 1px;
      right: 0;
      font-family: 'icomoon';
      content: "";
      font-weight: normal;
      font-size: 12px;
      line-height: 1;
      color: #93a4b8; }
      @media (min-width: 768px) {
        .setps-block .step-opener:after {
          display: none; } }
  .setps-block .step-active .step-opener:after {
    top: 4px;
    -webkit-transform: rotate(-180deg);
        -ms-transform: rotate(-180deg);
            transform: rotate(-180deg); }
  .setps-block .number {
    border-radius: 100%;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -ms-flex-item-align: start;
        align-self: flex-start;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    display: inline-block;
    vertical-align: top;
    font-size: 1.125rem;
    line-height: 1.375rem;
    font-weight: 600;
    color: #00b0f0;
    background: #e5f7fe;
    text-align: center;
    padding: 0.3125rem;
    min-width: 32px;
    margin: 0 0.5rem 0 0; }
    @media (min-width: 992px) {
      .setps-block .number {
        margin: 0 0.9375rem 0 0; } }
  .setps-block .title-holder {
    display: block;
    font-size: 0.75rem;
    line-height: 1rem;
    position: relative;
    padding: 10px 0 0 10px;
    color: #325885; }
    @media (min-width: 768px) {
      .setps-block .title-holder {
        display: inline-block;
        vertical-align: top;
        padding: 5px 0 0 9px;
        font-size: 1rem;
        line-height: 1.375rem; } }
    @media (min-width: 992px) {
      .setps-block .title-holder {
        font-size: 1.125rem;
        line-height: 1.375rem; } }
    .setps-block .title-holder.active-title:before {
      display: none;
      content: "";
      position: absolute;
      top: 8px;
      left: 0;
      width: 3px;
      height: 15px;
      background: #325885;
      -webkit-transform: rotate(10deg);
          -ms-transform: rotate(10deg);
              transform: rotate(10deg); }
      @media (min-width: 768px) {
        .setps-block .title-holder.active-title:before {
          display: block; } }

.custom-control {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }
  @media (min-width: 768px) {
    .custom-control {
      padding-left: 2rem; } }
  .custom-control .custom-control-label:before, .custom-control .custom-control-label:after {
    top: 0; }
    @media (min-width: 768px) {
      .custom-control .custom-control-label:before, .custom-control .custom-control-label:after {
        width: 1.5rem;
        height: 1.5rem;
        left: -2rem;
        top: -4px; } }
    @media (min-width: 992px) {
      .custom-control .custom-control-label:before, .custom-control .custom-control-label:after {
        top: -2px; } }

.step-widget {
  position: absolute;
  top: 100%;
  left: 0;
  right: 0;
  padding-bottom: 5px;
  box-shadow: 0 2px 2px rgba(73, 92, 117, 0.1);
  background: #fff;
  z-index: 9;
  opacity: 0;
  -webkit-transition: opacity 0s linear 1s;
  transition: opacity 0s linear 1s; }
  .step-active .step-widget {
    opacity: 1; }
  .sidebar .step-widget {
    opacity: 1; }
  @media (min-width: 768px) {
    .step-widget {
      position: relative;
      left: auto;
      top: auto;
      right: auto;
      -webkit-transition: none;
      transition: none;
      -webkit-transform: none;
          -ms-transform: none;
              transform: none;
      background: none;
      box-shadow: none; } }
  .step-widget .step-list {
    width: 100%;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 600;
    color: #325885;
    padding: 0.9375rem;
    margin: 0 0 0.625rem;
    box-shadow: 0 2px 2px rgba(73, 92, 117, 0.1), inset 0 2px 2px rgba(73, 92, 117, 0.1);
    border-radius: 0 0 4px 4px;
    background: #fff; }
    @media (min-width: 768px) {
      .step-widget .step-list {
        box-shadow: 0 2px 2px rgba(73, 92, 117, 0.1);
        border-radius: 4px; } }
    @media (min-width: 992px) {
      .step-widget .step-list {
        padding: 1.25rem 0.9375rem;
        margin: 0 0 1.5rem; } }
    @media (min-width: 1260px) {
      .step-widget .step-list {
        padding: 2rem; } }
    .step-widget .step-list li {
      position: relative;
      padding: 0 0 1.5625rem 2rem;
      z-index: 2; }
      @media (min-width: 1260px) {
        .step-widget .step-list li {
          padding: 0 0 1.875rem 2.5rem; } }
      .step-widget .step-list li:last-child {
        padding-bottom: 0; }
      .step-widget .step-list li.active:before {
        content: '';
        border-color: #00b0f0;
        background: #fff; }
      .step-widget .step-list li.active:after {
        background: #c3d1e2; }
      .step-widget .step-list li.active ~ li:before {
        border-color: #c3d1e2;
        content: '';
        background: #fff; }
      .step-widget .step-list li.active ~ li:after {
        background: #c3d1e2; }
      .step-widget .step-list li:before {
        font-family: 'icomoon';
        content: "";
        font-weight: normal;
        position: absolute;
        top: -4px;
        left: 0;
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 2px solid #00b0f0;
        background: #00b0f0;
        font-size: 12px;
        line-height: 18px;
        font-weight: 400;
        color: #fff;
        text-align: center; }
      .step-widget .step-list li:after {
        content: "";
        position: absolute;
        left: 11px;
        top: 0;
        bottom: 0;
        width: 2px;
        z-index: -1;
        background: #00b0f0; }
  .step-widget .btn-holder .btn .icon {
    font-size: 14px;
    margin-top: -2px;
    color: #93a4b8; }
  .step-widget .logo-wrap {
    display: none; }
    @media (min-width: 768px) {
      .step-widget .logo-wrap {
        display: block; } }
    .step-widget .logo-wrap .logo {
      display: inline-block;
      vertical-align: top;
      width: 109px; }
      .step-widget .logo-wrap .logo img {
        display: block;
        width: 100%;
        height: auto; }

.card {
  box-shadow: 0 2px 2px rgba(73, 92, 117, 0.1);
  background: #fff; }

.step-form, .review-form {
  position: relative;
  overflow: hidden; }
  .step-form.form-error .alert, .form-error.review-form .alert,
  .form-error .step-form .alert,
  .form-error .review-form .alert,
  .step-form .form-error .alert,
  .review-form .form-error .alert {
    display: block; }
    .step-form.form-error .alert.fade, .form-error.review-form .alert.fade,
    .form-error .step-form .alert.fade,
    .form-error .review-form .alert.fade,
    .step-form .form-error .alert.fade,
    .review-form .form-error .alert.fade {
      visibility: visible;
      opacity: 1 !important; }
  .step-form .alert, .review-form .alert {
    display: none; }
    .step-form .alert.alert-danger, .review-form .alert.alert-danger {
      border-color: #fcc4bf; }
    .step-form .alert.fade, .review-form .alert.fade {
      visibility: hidden; }
  .step-form .card, .review-form .card {
    border: 0; }
    .step-form .card .card-header, .review-form .card .card-header {
      background: none;
      padding: 1.125rem 1.5rem 0.75rem;
      border: 0; }
      @media (min-width: 992px) {
        .step-form .card .card-header, .review-form .card .card-header {
          padding: 2.0625rem 2rem 1.5rem; } }
  .step-form .upload-link, .review-form .upload-link {
    display: inline-block;
    vertical-align: top;
    font-size: 16px;
    line-height: 1;
    color: #93a4b8; }
    .step-form .upload-link:hover, .review-form .upload-link:hover {
      color: #00aff3; }
    .step-form .upload-link .icon, .review-form .upload-link .icon {
      line-height: inherit; }
  .step-form .check-list, .review-form .check-list {
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center; }
  .step-form .card-body, .review-form .card-body {
    position: relative;
    padding: 0 1.5rem 1.25rem; }
    @media (min-width: 992px) {
      .step-form .card-body, .review-form .card-body {
        padding: 0 2rem 1rem; } }
    .step-form .card-body > .row, .review-form .card-body > .row {
      border-top: 1px solid #dae0e6;
      padding: 0.5625rem 0; }
      @media (max-width: 991px) {
        .step-form .card-body > .row, .review-form .card-body > .row {
          margin-left: -24px;
          margin-right: -24px;
          padding: 0.625rem 0 0.375rem; } }
      .step-form .card-body > .row.bg-gray, .review-form .card-body > .row.bg-gray {
        background: #f8f9fc; }
      .step-form .card-body > .row.error, .review-form .card-body > .row.error {
        background: #ffecea !important;
        border-color: #fcc4bf !important; }
        .step-form .card-body > .row.error label, .review-form .card-body > .row.error label,
        .step-form .card-body > .row.error .label,
        .review-form .card-body > .row.error .label {
          color: #f63b2c; }
        .step-form .card-body > .row.error .jcf-select .jcf-select-opener, .review-form .card-body > .row.error .jcf-select .jcf-select-opener {
          color: #f63b2c; }
        .step-form .card-body > .row.error + .row, .review-form .card-body > .row.error + .row {
          border-color: #fcc4bf !important; }
      @media (min-width: 992px) {
        .step-form .card-body > .row .row, .review-form .card-body > .row .row {
          -webkit-box-flex: 1;
              -ms-flex-positive: 1;
                  flex-grow: 1; } }
      @media (min-width: 992px) {
        .step-form .card-body > .row .row > [class^="col-"], .review-form .card-body > .row .row > [class^="col-"] {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-flow: row wrap;
                  flex-flow: row wrap;
          -webkit-box-align: center;
              -ms-flex-align: center;
                  align-items: center; } }
      @media (max-width: 991px) {
        .step-form .card-body > .row .row > [class^="col-"], .review-form .card-body > .row .row > [class^="col-"] {
          padding-top: 8px; } }
      @media (max-width: 991px) {
        .step-form .card-body > .row .row label, .review-form .card-body > .row .row label,
        .step-form .card-body > .row .row .label,
        .review-form .card-body > .row .row .label {
          padding: 0; } }
      @media (min-width: 992px) {
        .step-form .card-body > .row > [class^="col-"], .review-form .card-body > .row > [class^="col-"] {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-flow: row wrap;
                  flex-flow: row wrap; } }
      @media (max-width: 991px) {
        .step-form .card-body > .row > [class^="col-"], .review-form .card-body > .row > [class^="col-"] {
          padding-left: 24px;
          padding-right: 24px; } }
      .step-form .card-body > .row .border-left, .review-form .card-body > .row .border-left {
        border-color: transparent !important;
        position: relative; }
        @media (min-width: 992px) {
          .step-form .card-body > .row .border-left:before, .review-form .card-body > .row .border-left:before {
            content: '';
            position: absolute;
            top: -10px;
            left: -1px;
            bottom: -10px;
            background: #dae0e6;
            width: 1px; } }
  .step-form .label, .review-form .label,
  .step-form label,
  .review-form label {
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 600;
    color: #325885;
    display: block;
    padding: 0 0 0.125rem;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center; }
    @media (min-width: 992px) {
      .step-form .label, .review-form .label,
      .step-form label,
      .review-form label {
        font-size: 0.8125rem;
        line-height: 1.25rem;
        padding: 0.5625rem 0.9375rem;
        margin: 0; } }
    .step-form .label sup, .review-form .label sup,
    .step-form label sup,
    .review-form label sup {
      opacity: 0.5;
      top: -2px;
      margin: 0 0 0 3px; }
  .step-form .jcf-select, .review-form .jcf-select,
  .step-form .form-control,
  .review-form .form-control {
    background: none;
    border-color: transparent;
    padding-left: 0 !important;
    -ms-flex-item-align: center;
        -ms-grid-row-align: center;
        align-self: center; }
    @media (min-width: 992px) {
      .step-form .jcf-select, .review-form .jcf-select,
      .step-form .form-control,
      .review-form .form-control {
        font-size: 0.8125rem; } }
  .step-form .form-control.signature-filed, .review-form .form-control.signature-filed {
    border-color: transparent transparent #325885;
    margin: 0 0 0.5rem; }
    @media (min-width: 992px) {
      .step-form .form-control.signature-filed, .review-form .form-control.signature-filed {
        margin: 1rem 0; } }

.check-list {
  margin: 0 -0.625rem; }
  .check-list li {
    padding: 0.3125rem 0.625rem; }

.tooltip-opener {
  line-height: inherit;
  z-index: 1;
  display: inline-block;
  vertical-align: top;
  color: #93a4b8;
  border-radius: 100%;
  font-size: 0.75rem;
  margin: 0 0 0 0.625rem;
  position: relative;
  cursor: pointer; }
  @media (min-width: 768px) {
    .tooltip-opener {
      font-size: 1rem; } }
  .tooltip-opener.align-right .tooltip-drop {
    left: auto;
    right: -25px; }
    .tooltip-opener.align-right .tooltip-drop .tooltip-wrap:after, .tooltip-opener.align-right .tooltip-drop .tooltip-wrap:before {
      left: calc(100% - 32px); }
  .tooltip-opener:hover {
    color: #93a4b8; }
  .tooltip-opener.hover .tooltip-drop {
    opacity: 1;
    visibility: visible; }
  .tooltip-opener .tooltip-drop {
    -webkit-transition: opacity 0.3s ease, visibility 0.3s ease;
    transition: opacity 0.3s ease, visibility 0.3s ease;
    width: 150px;
    display: block;
    font-family: "Poppins", "Arial", "Helvetica Neue", "Helvetica", sans-serif !important;
    position: absolute;
    top: 100%;
    padding: 0.875rem 0 0;
    left: -20px;
    z-index: 2;
    opacity: 0;
    visibility: hidden; }
    @media (min-width: 768px) {
      .tooltip-opener .tooltip-drop {
        width: 202px; } }
    .tooltip-opener .tooltip-drop .tooltip-wrap {
      position: relative;
      position: relative;
      display: block;
      background: #fff;
      box-shadow: 4px 0 8px rgba(73, 92, 117, 0.2);
      border: 1px solid #d7dde6;
      color: #9397a9;
      font-size: 0.8125rem;
      line-height: 1.25rem;
      font-weight: 500;
      border-radius: 4px;
      padding: 0.625rem 0.9375rem;
      text-align: left; }
      .tooltip-opener .tooltip-drop .tooltip-wrap:after, .tooltip-opener .tooltip-drop .tooltip-wrap:before {
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none; }
      .tooltip-opener .tooltip-drop .tooltip-wrap:after {
        border-width: 7px; }
      .tooltip-opener .tooltip-drop .tooltip-wrap:before {
        border-width: 8px; }
      .tooltip-opener .tooltip-drop .tooltip-wrap:after, .tooltip-opener .tooltip-drop .tooltip-wrap:before {
        bottom: 100%;
        left: 50%; }
      .tooltip-opener .tooltip-drop .tooltip-wrap:after {
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #fff;
        margin-left: -7px; }
      .tooltip-opener .tooltip-drop .tooltip-wrap:before {
        border-color: rgba(215, 221, 230, 0);
        border-bottom-color: #d7dde6;
        margin-left: -8px; }
      .tooltip-opener .tooltip-drop .tooltip-wrap:after, .tooltip-opener .tooltip-drop .tooltip-wrap:before {
        left: 28px; }
    .tooltip-opener .tooltip-drop .text {
      display: block; }

.tooltip {
  margin: 8px 0 0 100px;
  width: 145px; }
  @media (min-width: 768px) {
    .tooltip {
      width: 202px;
      margin: 8px 0 0 158px; } }
  .tooltip.bs-tooltip-top, .tooltip.bs-tooltip-auto[x-placement^="top"] {
    margin-top: 0 !important; }
    .tooltip.bs-tooltip-top .arrow:before, .tooltip.bs-tooltip-auto[x-placement^="top"] .arrow:before {
      border-top-color: #fff; }
  .tooltip.show {
    opacity: 1 !important; }
  .tooltip .arrow:before {
    border-bottom-color: #fff; }

.tooltip-inner {
  background: #fff;
  box-shadow: 4px 0 8px rgba(73, 92, 117, 0.2);
  border: 1px solid #d7dde6;
  color: #9397a9;
  font-size: 0.8125rem;
  line-height: 1.25rem;
  font-weight: 500;
  border-radius: 4px;
  padding: 0.625rem 0.9375rem;
  text-align: left; }

.btn-navigation {
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between; }
  .btn-navigation li {
    width: 100%;
    text-align: center;
    padding: 0.3125rem 0;
    -ms-flex-negative: 0;
        flex-shrink: 0; }
    @media (min-width: 576px) {
      .btn-navigation li {
        width: auto;
        text-align: left;
        padding: 0;
        max-width: 50%; } }
    .btn-navigation li:only-child {
      width: 100%; }
      @media (min-width: 576px) {
        .btn-navigation li:only-child {
          display: -webkit-box;
          display: -ms-flexbox;
          display: flex;
          -webkit-box-orient: horizontal;
          -webkit-box-direction: normal;
              -ms-flex-flow: row wrap;
                  flex-flow: row wrap;
          -webkit-box-pack: end;
              -ms-flex-pack: end;
                  justify-content: flex-end; } }
  .btn-navigation a {
    position: relative;
    display: block; }
    .btn-navigation a:not([class^="btn"]):not([class*="btn"]) {
      color: #325885;
      display: inline-block;
      vertical-align: top;
      font-size: 12px; }
      .btn-navigation a:not([class^="btn"]):not([class*="btn"]):hover {
        color: #00aff3; }
        .btn-navigation a:not([class^="btn"]):not([class*="btn"]):hover .icon {
          color: #00aff3; }
    .btn-navigation a .icon {
      -webkit-transition: color 0.3s ease;
      transition: color 0.3s ease;
      line-height: inherit;
      display: inline-block;
      vertical-align: top;
      -webkit-transform: rotate(180deg);
          -ms-transform: rotate(180deg);
              transform: rotate(180deg);
      margin: 1px 6px 1px 0;
      color: #93a4b8; }

.custom-file {
  position: relative; }
  .custom-file .custom-file-label {
    border-style: dashed;
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    font-size: 12px;
    padding: 0.9375rem; }
    .custom-file .custom-file-label .align-content {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row nowrap;
              flex-flow: row nowrap;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; }
    .custom-file .custom-file-label p {
      margin: 0; }
    .custom-file .custom-file-label:after {
      display: none; }
    .custom-file .custom-file-label .icon {
      color: #93a4b8;
      font-size: 1.375rem;
      display: inline-block;
      vertical-align: top;
      -ms-flex-negative: 0;
          flex-shrink: 0;
      margin: 0 0.9375rem 0 0; }
      @media (min-width: 768px) {
        .custom-file .custom-file-label .icon {
          font-size: 2rem; } }
    .custom-file .custom-file-label .main-text {
      display: block; }
    .custom-file .custom-file-label .note-text {
      display: block;
      color: #b3b6c2;
      font-weight: 500; }
  .custom-file .custom-file-input {
    position: absolute; }

.footer {
  display: table-footer-group;
  height: 1%;
  overflow: hidden;
  text-align: center;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 600;
  color: #9296a8;
  background: #fff; }
  @media (min-width: 768px) {
    .footer {
      text-align: left; } }
  .footer .container {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem; }
    @media (min-width: 768px) {
      .footer .container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        padding-top: 3.125rem;
        padding-bottom: 3.125rem; } }
  .footer .copyright {
    display: block; }
    .footer .copyright a {
      color: #9296a8;
      text-decoration: none; }
      .footer .copyright a:hover {
        color: #00b0f0; }
    @media (min-width: 768px) {
      .footer .copyright {
        -webkit-box-ordinal-group: 2;
            -ms-flex-order: 1;
                order: 1; } }
  .footer .footer-logo {
    width: 28px;
    margin: 0 auto 24px; }
    @media (min-width: 768px) {
      .footer .footer-logo {
        -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
                order: 2;
        margin: 0 auto; } }
  @media (min-width: 768px) {
    .footer .footer-nav {
      -webkit-box-ordinal-group: 4;
          -ms-flex-order: 3;
              order: 3; } }

.footer-nav {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-flow: row wrap;
          flex-flow: row wrap;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  text-transform: capitalize;
  margin: 0 0 0.625rem; }
  @media (min-width: 768px) {
    .footer-nav {
      margin: 0;
      -webkit-box-pack: start;
          -ms-flex-pack: start;
              justify-content: flex-start; } }
  .footer-nav li {
    padding: 0 0.625rem 0.625rem; }
    @media (min-width: 768px) {
      .footer-nav li {
        padding: 0 0.625rem; } }
  .footer-nav a {
    display: block;
    text-decoration: none;
    color: #9296a8; }
    .footer-nav a:hover {
      color: #00b0f0; }

.admission-section {
  position: relative; }
  .admission-section .head {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 0 0 1.25rem; }
    @media (min-width: 768px) {
      .admission-section .head {
        margin: 0 0 2.5rem; } }
    .admission-section .head .h2 {
      margin: 0; }
      @media (max-width: 767px) {
        .admission-section .head .h2 {
          font-size: 1rem;
          line-height: 1.5rem;
          font-weight: 600; } }
    @media (max-width: 767px) {
      .admission-section .head .btn {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        min-width: inherit;
        width: 32px;
        height: 32px;
        padding: 0;
        font-size: 15px;
        line-height: 1; } }
    @media (max-width: 767px) {
      .admission-section .head .btn .icon {
        margin: 0; } }

.btn-circle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50px;
  border: 1px solid #d7dde6;
  background: #fff; }

p:last-child {
  margin: 0; }

.admission-holder {
  padding: 1.25rem 1.5rem;
  margin: 0 0 1.5rem;
  border-radius: 0.25rem;
  box-shadow: 0 2px 2px rgba(73, 92, 117, 0.1);
  background: #fff; }
  @media (min-width: 992px) {
    .admission-holder {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      padding: 1rem 1.25rem;
      margin: 0 0 2rem; } }
  @media (min-width: 1260px) {
    .admission-holder {
      padding: 1rem 2rem; } }
  .admission-holder:last-of-type {
    margin: 0; }
  .admission-holder .left-col {
    width: 100%;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    padding: 0.625rem 0.625rem 0.625rem 0;
    border-width: 0 0 1px;
    border-style: solid;
    border-color: #d7dde6; }
    @media (min-width: 992px) {
      .admission-holder .left-col {
        width: 340px;
        border-width: 0 1px 0 0; } }
    @media (min-width: 1260px) {
      .admission-holder .left-col {
        width: 392px;
        padding: 0.625rem 1.875rem 0.625rem 0; } }
  .admission-holder .right-col {
    padding: 1.25rem 0 0.625rem; }
    @media (min-width: 768px) {
      .admission-holder .right-col {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 1;
            -ms-flex-positive: 1;
                flex-grow: 1;
        -ms-flex-preferred-size: 0;
            flex-basis: 0;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between; } }
    @media (min-width: 992px) {
      .admission-holder .right-col {
        padding: 0.875rem 0 0.625rem 1.25rem; } }
    @media (min-width: 1260px) {
      .admission-holder .right-col {
        padding: 0.875rem 0 0.625rem 2.25rem; } }
  .admission-holder h2 {
    margin: 0 0 0.9375rem; }
    @media (max-width: 767px) {
      .admission-holder h2 {
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 600; } }
  .admission-holder .text-box {
    width: 100%;
    position: relative;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 500;
    padding: 0 0 2.0625rem;
    margin: 0 0 1.5rem; }
    @media (min-width: 768px) {
      .admission-holder .text-box {
        width: 400px;
        padding: 0 0.625rem 0 0;
        margin: 0; } }
    @media (min-width: 992px) {
      .admission-holder .text-box {
        width: 320px; } }
    @media (min-width: 1260px) {
      .admission-holder .text-box {
        width: 366px; } }
  .admission-holder .title-holder {
    margin: 0 0 0.625rem 0; }
    @media (min-width: 768px) {
      .admission-holder .title-holder {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center; } }
    .admission-holder .title-holder:only-child {
      margin: 0; }
  .admission-holder h3 {
    font-size: 0.875rem;
    line-height: 1.5rem;
    font-weight: 600;
    margin: 0; }
    @media (min-width: 768px) {
      .admission-holder h3 {
        font-size: 1.125rem;
        line-height: 1.5rem;
        font-weight: 600;
        margin: 0 0.9375rem 0 0; } }
    @media (min-width: 1260px) {
      .admission-holder h3 {
        margin: 0 1.5625rem 0 0; } }
  .admission-holder .bar-holder {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    @media (min-width: 768px) {
      .admission-holder .bar-holder {
        position: static;
        width: 216px; } }
  .admission-holder .progress {
    height: 6px;
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1;
    -ms-flex-preferred-size: 0;
        flex-basis: 0;
    margin: 0 0.5rem 0 0; }
  .admission-holder .btn-holder {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center; }
    .admission-holder .btn-holder .btn-circle {
      margin: 0 0 0 0.5rem; }
      @media (min-width: 768px) {
        .admission-holder .btn-holder .btn-circle {
          margin: 0 0 0 1rem; } }
    .admission-holder .btn-holder > .btn {
      -webkit-box-flex: 1;
          -ms-flex-positive: 1;
              flex-grow: 1; }
      @media (min-width: 768px) {
        .admission-holder .btn-holder > .btn {
          -webkit-box-flex: 0;
              -ms-flex-positive: 0;
                  flex-grow: 0; } }

.list {
  font-size: 0.8125rem;
  line-height: 1rem;
  font-weight: 500; }
  @media (min-width: 768px) {
    .list {
      font-size: 0.875rem;
      line-height: 1rem;
      font-weight: 500; } }
  @media (min-width: 992px) {
    .list {
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: justify;
          -ms-flex-pack: justify;
              justify-content: space-between; } }
  .list li {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    margin: 0 0 0.625rem; }
    @media (min-width: 992px) {
      .list li {
        margin: 0; } }
  .list .icon {
    font-size: 16px;
    margin: 0 0.9375rem 0 0; }
    @media (min-width: 768px) {
      .list .icon {
        margin: 0 0.5rem 0 0; } }
    @media (min-width: 1260px) {
      .list .icon {
        margin: 0 0.9375rem 0 0; } }

.modal-dialog {
  max-width: 100%;
  color: #9296a8;
  margin: 0.9375rem;
  font-size: 0.8125rem;
  line-height: 1.5rem; }
  @media (min-width: 768px) {
    .modal-dialog {
      max-width: 568px;
      margin: 28px auto;
      font-size: 0.875rem;
      line-height: 1rem; } }
  .modal-dialog .close {
    position: absolute;
    top: -5px;
    right: -5px;
    font-size: 10px;
    line-height: 1;
    margin: 0; }
  .modal-dialog .modal-header {
    display: block;
    position: relative;
    text-align: center;
    padding: 5rem 1.5rem 0.625rem;
    border: 0; }
    @media (min-width: 768px) {
      .modal-dialog .modal-header {
        padding: 5rem 0.9375rem 0.625rem; } }
  .modal-dialog .icon-box {
    position: absolute;
    top: -44px;
    left: 50%;
    -webkit-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
            transform: translateX(-50%);
    font-size: 2.5rem;
    line-height: 0.0625rem;
    font-weight: 600;
    color: #fff;
    padding: 0.3125rem;
    border-radius: 100%;
    width: 88px;
    height: 88px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    background: #00b0f0; }
  .modal-dialog .modal-content {
    box-shadow: 0 2px 2px rgba(73, 92, 117, 0.1); }
  .modal-dialog .modal-body {
    padding: 0.375rem 1.5rem 1.5rem; }
    @media (min-width: 768px) {
      .modal-dialog .modal-body {
        padding: 0.375rem 3.5rem 3.5rem; } }
  .modal-dialog h2 {
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 600;
    margin: 0 0 0.6875rem; }
    @media (min-width: 768px) {
      .modal-dialog h2 {
        font-size: 1.5rem;
        line-height: 1.75rem;
        font-weight: 600; } }
  .modal-dialog .btn-holder {
    text-align: center;
    padding: 0.5rem 0 0; }

label {
  display: block;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 600;
  text-transform: capitalize;
  margin: 0 0 0.5rem;
  color: #325885; }

@media (min-width: 768px) {
  .radio-holder {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-orient: horizontal;
    -webkit-box-direction: normal;
        -ms-flex-flow: row wrap;
            flex-flow: row wrap; } }

.radio-holder .form-group {
  width: 100%; }
  @media (min-width: 768px) {
    .radio-holder .form-group {
      width: 48.5%; } }

.radio-holder input[type="radio"] {
  display: none; }
  .radio-holder input[type="radio"]:checked ~ .customradio {
    border-color: #00b0f0;
    color: #00b0f0;
    box-shadow: 0 0 0 1px #00b0f0;
    background: #fff; }
    .radio-holder input[type="radio"]:checked ~ .customradio:after {
      display: block; }

.radio-holder .customradio {
  display: block;
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 600;
  color: #9296a8;
  margin: 0;
  padding: 1.25rem 0.625rem;
  border-radius: 4px;
  text-align: center;
  border: 1px solid #dae0e6;
  cursor: pointer;
  position: relative;
  background: #fafbfc; }
  .radio-holder .customradio:after {
    display: none;
    position: absolute;
    top: 3px;
    right: 5px;
    font-size: 0.8125rem;
    line-height: 1rem;
    font-weight: 400;
    font-family: 'icomoon';
    content: "";
    font-weight: normal; }

.pre-admission {
  font-size: 0.8125rem;
  line-height: 1.5rem;
  font-weight: 500;
  color: #9296a8;
  padding: 1.75rem 1.5rem 1.25rem;
  text-align: center;
  border-radius: 4px;
  box-shadow: 0 2px 2px rgba(73, 92, 117, 0.1);
  background: #fff; }
  @media (min-width: 576px) {
    .pre-admission {
      font-size: 0.875rem;
      line-height: 1.5rem;
      font-weight: 500; } }
  @media (min-width: 768px) {
    .pre-admission {
      padding: 3.9375rem 0.9375rem;
      margin: 0; } }
  .pre-admission .holder {
    max-width: 925px;
    margin: 0 auto; }
  .pre-admission .text-holder {
    max-width: 500px;
    margin: 0 auto 33px; }
    @media (min-width: 768px) {
      .pre-admission .text-holder {
        margin: 0 auto 48px; } }
  .pre-admission h1 {
    margin: 0 0 1.4375rem; }
    @media (max-width: 575px) {
      .pre-admission h1 {
        font-size: 1rem;
        line-height: 1.5625rem;
        font-weight: 600;
        margin: 0 0 0.9375rem; } }
  .pre-admission p {
    margin: 0 0 1.5rem; }
  .pre-admission .cols-holder {
    margin: 0 0 2rem;
    line-height: 22px;
    font-weight: 500; }
    @media (min-width: 992px) {
      .pre-admission .cols-holder {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-flow: row wrap;
                flex-flow: row wrap;
        margin: 0 -0.625rem 2rem; } }
    @media (min-width: 1260px) {
      .pre-admission .cols-holder {
        margin: 0 -0.875rem 2rem; } }
    .pre-admission .cols-holder .column {
      width: 100%;
      position: relative;
      padding: 0 0.9375rem; }
      @media (min-width: 576px) {
        .pre-admission .cols-holder .column {
          width: 300px;
          margin: 0 auto; } }
      @media (min-width: 992px) {
        .pre-admission .cols-holder .column {
          width: 25%;
          padding: 0 0.625rem; } }
      @media (min-width: 1260px) {
        .pre-admission .cols-holder .column {
          padding: 0 0.875rem; } }
      .pre-admission .cols-holder .column:first-child:after {
        display: none; }
      .pre-admission .cols-holder .column:first-child .icon-box:before {
        display: none; }
      .pre-admission .cols-holder .column:last-child .icon-box:after {
        display: none; }
      .pre-admission .cols-holder .column:after {
        display: none;
        position: absolute;
        top: 31px;
        left: -8px;
        font-size: 16px;
        line-height: 1;
        font-family: 'icomoon';
        content: "";
        font-weight: normal;
        color: #93a4b8; }
        @media (min-width: 992px) {
          .pre-admission .cols-holder .column:after {
            display: block; } }
  .pre-admission .icon-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: 80px;
    height: 80px;
    font-size: 23px;
    line-height: 1;
    color: #00b0f0;
    border: 1px solid #dae0e6;
    border-radius: 50px;
    margin: 0 auto 24px;
    position: relative; }
    @media (min-width: 768px) {
      .pre-admission .icon-box {
        margin: 0 auto 26px; } }
    .pre-admission .icon-box:after, .pre-admission .icon-box:before {
      display: none;
      position: absolute;
      top: 49%;
      left: 100%;
      content: "";
      width: 39px;
      height: 1px;
      margin-left: 16px;
      border-top: 1px dashed #93a4b8; }
      @media (min-width: 992px) {
        .pre-admission .icon-box:after, .pre-admission .icon-box:before {
          display: block; } }
    .pre-admission .icon-box:before {
      left: auto;
      right: 100%;
      margin-right: 16px; }
  .pre-admission .text-box {
    overflow: hidden; }
  .pre-admission .title {
    display: block;
    font-size: 0.8125rem;
    line-height: 1rem;
    font-weight: 600;
    margin: 0 0 0.6875rem;
    color: #325885; }
    @media (min-width: 768px) {
      .pre-admission .title {
        margin: 0 0 1rem; } }
  @media (min-width: 576px) {
    .pre-admission .btn-holder {
      padding: 0 1.5rem;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center; } }
  @media (min-width: 768px) {
    .pre-admission .btn-holder {
      padding: 0; } }
  .pre-admission .btn-holder .btn {
    display: block;
    margin: 0 0 1rem; }
    @media (min-width: 576px) {
      .pre-admission .btn-holder .btn {
        display: inline-block;
        margin: 0 0.5rem; } }
    @media (min-width: 576px) {
      .pre-admission .btn-holder .btn.btn-primary {
        -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
                order: 2; } }
    @media (min-width: 576px) {
      .pre-admission .btn-holder .btn.btn-outline-secondary {
        -webkit-box-ordinal-group: 2;
            -ms-flex-order: 1;
                order: 1; } }

.text-section {
  white-space: nowrap;
  text-align: center;
  min-height: 463px;
  text-align: center;
  color: #9296a8;
  padding: 0.9375rem 1.5rem;
  border-radius: 4px;
  box-shadow: 0 2px 2px rgba(73, 92, 117, 0.1);
  background: #fff; }
  .text-section:after {
    content: '';
    display: inline-block;
    vertical-align: middle;
    width: 0;
    min-height: inherit; }
  .text-section > * {
    white-space: normal;
    display: inline-block;
    vertical-align: middle;
    max-width: 99%; }
  @media (min-width: 768px) {
    .text-section {
      min-height: 568px;
      font-size: 0.875rem;
      line-height: 1.5rem;
      font-weight: 500; } }
  .text-section .holder {
    max-width: 525px; }
  .text-section .icon-holder {
    font-size: 57px;
    line-height: 1;
    color: #00b0f0;
    margin: 0 0 2.25rem; }
    @media (min-width: 768px) {
      .text-section .icon-holder {
        margin: 0 0 3.5rem; } }
  .text-section h1 {
    margin: 0 0 1.5rem; }
    @media (max-width: 575px) {
      .text-section h1 {
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 600;
        margin: 0 0 1rem; } }
  .text-section p {
    margin: 0 0 1.25rem; }
    .text-section p br {
      display: none; }
      @media (min-width: 768px) {
        .text-section p br {
          display: block; } }
  .text-section .btn-holder {
    padding: 0.625rem 0; }
    @media (max-width: 575px) {
      .text-section .btn-holder .btn {
        display: block;
        width: 100%; } }

@media (max-width: 767px) {
  .container {
    padding-left: 24px;
    padding-right: 24px; } }

.profile-detail {
  max-width: 770px;
  margin: 0 auto;
  padding: 1.5rem;
  border-radius: 4px;
  box-shadow: 0 2px 2px rgba(73, 92, 117, 0.1);
  background: #fff; }
  @media (min-width: 768px) {
    .profile-detail {
      padding: 2rem; } }
  @media (max-width: 767px) {
    .profile-detail .jcf-select.jcf-select-lg {
      padding-top: 0.6875rem;
      padding-bottom: 0.6875rem; } }
  @media (max-width: 767px) {
    .profile-detail .form-group {
      margin-bottom: 1rem; } }
  @media (max-width: 767px) {
    .profile-detail .form-control,
    .profile-detail .form-control-lg {
      height: 40px;
      padding-top: 0.625rem;
      padding-bottom: 0.625rem; } }
  .profile-detail .h2 {
    margin: 0 0 1rem; }
    @media (max-width: 767px) {
      .profile-detail .h2 {
        font-size: 1rem;
        line-height: 1.5rem;
        font-weight: 400; } }
  .profile-detail .h3 {
    display: block;
    font-size: 1rem;
    line-height: 1.25rem;
    font-weight: 600;
    margin: 0 0 1.375rem; }
    @media (min-width: 768px) {
      .profile-detail .h3 {
        margin: 0 0 1.875rem; } }
  .profile-detail .profile-holder {
    margin-bottom: 0.875rem; }
  .profile-detail .row {
    margin-right: -12px;
    margin-left: -12px; }
  .profile-detail [class*="col-"] {
    padding-left: 12px;
    padding-right: 12px; }
  .profile-detail .sel-box {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    left: 1px;
    width: 80px;
    z-index: 1; }
  .profile-detail .icon {
    position: absolute;
    top: 50%;
    right: 16px;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%);
    font-size: 12px;
    line-height: 1;
    pointer-events: none; }
    @media (min-width: 768px) {
      .profile-detail .icon {
        font-size: 14px;
        right: 15px; } }
  .profile-detail .code {
    display: inline-block;
    font-size: 0.75rem;
    line-height: 1rem;
    font-weight: 500;
    color: #9296a8;
    min-width: 25px;
    padding: 0 0 0 0.5rem; }
  .profile-detail .btn {
    display: block;
    min-width: 100%; }
    @media (min-width: 576px) {
      .profile-detail .btn {
        display: inline-block;
        min-width: 100px; } }

.form-row {
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center; }

.label-list {
  font-size: 0.75rem;
  line-height: 1rem;
  font-weight: 600;
  color: #325885;
  padding: 0 0 0.125rem; }
  @media (min-width: 992px) {
    .label-list {
      font-size: 0.8125rem;
      line-height: 1.25rem;
      padding: 0 0.9375rem; } }
  .label-list li {
    position: relative;
    padding: 0 0 0 10px; }
    .label-list li:after {
      position: absolute;
      top: 6px;
      left: 0;
      content: "";
      width: 6px;
      height: 6px;
      border-radius: 15px;
      background: #00b0f0; }

.container-small, .container-simple {
  max-width: 770px;
  margin: 0 auto;
  padding: 0; }
  @media (min-width: 768px) {
    .container-small, .container-simple {
      max-width: 802px;
      padding: 0 16px; } }

.container-smaller {
  max-width: 570px;
  margin: 0 auto;
  padding: 0; }
  @media (min-width: 768px) {
    .container-smaller {
      max-width: 602px;
      padding: 0 16px; } }

.container-simple {
  max-width: 802px;
  padding: 0 16px; }

.sticky-header {
  z-index: 1000;
  display: block;
  height: auto;
  width: 100%;
  right: 0;
  font-size: 0.75rem; }
  @media (max-width: 991px) {
    .sticky-header .container-fluid {
      padding-top: 0.8125rem;
      padding-bottom: 0.8125rem; } }

.navbar .icon {
  font-size: 1rem;
  display: inline-block;
  vertical-align: top;
  margin-top: 2px; }

.head-block {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-bottom: 0.5rem;
  padding: 1rem 1.125rem;
  font-size: 0.875rem;
  font-weight: 600;
  line-height: 1.6;
  color: #325885;
  background-color: #fff;
  box-shadow: 0 2px 2px rgba(73, 92, 117, 0.1); }
  @media (min-width: 768px) {
    .head-block {
      margin-bottom: 1rem;
      padding: 0;
      font-size: 1.25rem;
      background-color: transparent;
      box-shadow: none; } }
  .head-block h2 {
    color: inherit;
    font-size: inherit;
    margin-bottom: 0;
    line-height: inherit; }
  .head-block .back-link {
    font-weight: 600; }
    .head-block .back-link i {
      font-size: 80%;
      color: #93a4b8; }
    .head-block .back-link:hover i {
      color: #00b0f0; }
  .head-block .steps {
    font-size: 0.75rem;
    font-weight: 500;
    color: #9296a8; }
  .head-block.head-simple {
    padding: 0 0 0.3125rem;
    background-color: transparent;
    box-shadow: none; }
    @media (min-width: 768px) {
      .head-block.head-simple {
        padding: 0; } }

.review-form {
  color: #9397a9; }
  .review-form .label,
  .review-form label,
  .review-form .form-control {
    font-weight: 500;
    padding: 0.6875rem 0; }
    @media (min-width: 992px) {
      .review-form .label,
      .review-form label,
      .review-form .form-control {
        padding: 0.5625rem 0.9375rem; } }
  .review-form .card-body {
    padding: 0 1.5rem; }
    @media (max-width: 991px) {
      .review-form .card-body {
        padding: 0 1.125rem; } }
    .review-form .card-body > .row {
      border-top-color: #dbe3ee;
      padding: 0.3125rem 0; }
      @media (max-width: 991px) {
        .review-form .card-body > .row {
          margin-left: -18px;
          margin-right: -18px; } }
      .review-form .card-body > .row > [class^="col-"] {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap; }
        @media (max-width: 991px) {
          .review-form .card-body > .row > [class^="col-"] {
            padding-left: 18px;
            padding-right: 18px; } }
    .review-form .card-body:first-child > .row:nth-of-type(1) {
      border: 0; }
  @media (max-width: 767px) {
    .review-form .card {
      border-radius: 0; } }
  @media (min-width: 992px) {
    .review-form .card {
      padding: 0.625rem 0; } }
  .review-form .card .card-header {
    padding: 0.9375rem 1.125rem; }
    @media (min-width: 992px) {
      .review-form .card .card-header {
        padding: 0.6875rem 2.4375rem; } }
  .review-form .card h3 {
    font-size: 0.875rem; }
    @media (min-width: 992px) {
      .review-form .card h3 {
        font-size: 1rem; } }
  .review-form .body-small {
    font-size: 0.75rem; }
    .review-form .body-small .label,
    .review-form .body-small label {
      font-size: 0.75rem;
      padding-top: 0.1875rem;
      padding-bottom: 0.1875rem; }
    .review-form .body-small strong.label {
      font-size: 0.875rem; }
  @media (min-width: 992px) {
    .review-form .card-body .card-header {
      padding: 0.6875rem 0.9375rem; } }
  .review-form .card-body .items-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap; }
    .review-form .card-body .items-list .item {
      width: 100%;
      text-align: right; }
      @media (min-width: 992px) {
        .review-form .card-body .items-list .item {
          width: 20%; } }
  .review-form .card-body .items-descr {
    position: relative; }
    @media (max-width: 991px) {
      .review-form .card-body .items-descr::before {
        content: "";
        position: absolute;
        bottom: 3px;
        left: -9999px;
        right: -9999px;
        height: 1px;
        background-color: #dbe3ee; }
      .review-form .card-body .items-descr:last-child::before {
        content: none; } }
  @media (min-width: 992px) {
    .review-form .card-body .row.items-descr,
    .review-form .card-body .row.items-names {
      margin: 0 0.5rem; } }
  @media (max-width: 991px) {
    .review-form .card-body .row.items-descr .items-list,
    .review-form .card-body .row.items-names .items-list {
      padding-top: 0.25rem;
      margin-bottom: 1.0625rem; } }
  @media (max-width: 991px) {
    .review-form .card-body .row.items-descr strong.label,
    .review-form .card-body .row.items-descr label strong,
    .review-form .card-body .row.items-names strong.label,
    .review-form .card-body .row.items-names label strong {
      font-size: 0.75rem; } }
  .review-form .card-body .row.items-descr .label,
  .review-form .card-body .row.items-descr label,
  .review-form .card-body .row.items-names .label,
  .review-form .card-body .row.items-names label {
    padding: 0.25rem 0; }
    @media (min-width: 992px) {
      .review-form .card-body .row.items-descr .label,
      .review-form .card-body .row.items-descr label,
      .review-form .card-body .row.items-names .label,
      .review-form .card-body .row.items-names label {
        padding: 0.1875rem 0.4375rem; } }
  .review-form .card-body .items-names {
    background-color: rgba(219, 227, 238, 0.24);
    border-radius: 4px; }
    .review-form .card-body .items-names .label,
    .review-form .card-body .items-names label {
      color: #9397a9;
      font-size: 0.6875rem; }
  .review-form strong.label {
    font-weight: 600;
    font-size: 0.75rem; }
    @media (min-width: 992px) {
      .review-form strong.label {
        font-size: 0.875rem; } }
  .review-form .label-list {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit; }
    .review-form .label-list li {
      padding: 0 0 0 15px;
      margin-bottom: 0.375rem; }
      @media (min-width: 992px) {
        .review-form .label-list li {
          margin-bottom: 0.6875rem; } }
  .review-form .btn-navigation a .icon {
    -webkit-transform: none;
        -ms-transform: none;
            transform: none; }
  .review-form .form-control.signature-filed {
    margin: 0; }
    @media (max-width: 991px) {
      .review-form .form-control.signature-filed {
        font-size: 0.8125rem; } }
    @media (min-width: 992px) {
      .review-form .form-control.signature-filed {
        margin: 0 0.9375rem 0 0; } }
  .review-form .box {
    margin-left: auto; }
    .review-form .box .label,
    .review-form .box label {
      padding: 0.25rem 0 !important; }
      @media (min-width: 992px) {
        .review-form .box .label,
        .review-form .box label {
          padding: 0.1875rem 0.4375rem !important; } }
    .review-form .box .value {
      -webkit-box-pack: end;
          -ms-flex-pack: end;
              justify-content: flex-end;
      min-width: 4.375rem; }
      .review-form .box .value .label {
        padding-left: 0 !important; }
  .review-form .check-list {
    -webkit-box-flex: 1;
        -ms-flex-positive: 1;
            flex-grow: 1; }
    .review-form .check-list li {
      margin-bottom: 0.4375rem; }
      @media (min-width: 992px) {
        .review-form .check-list li {
          margin-right: 0.625rem; } }
      @media (max-width: 991px) {
        .review-form .check-list li:last-child {
          margin-bottom: 0; } }
  .review-form .checks-block {
    padding: 0.625rem 0.9375rem; }
    .review-form .checks-block .check-list {
      width: 100%; }
    .review-form .checks-block li {
      margin-right: 0; }
    .review-form .checks-block .custom-control {
      padding-left: 2.5rem; }
      .review-form .checks-block .custom-control-label::before, .review-form .checks-block .custom-control-label::after {
        left: -2.5rem; }
    .review-form .checks-block .custom-control .custom-control-label:before,
    .review-form .checks-block .custom-control .custom-control-label:after {
      width: 1.5rem;
      height: 1.5rem; }
  .review-form .btn-navigation li {
    padding: 0; }
    @media (max-width: 767px) {
      .review-form .btn-navigation li {
        margin-bottom: 1.25rem; }
        .review-form .btn-navigation li:last-child {
          margin-bottom: 0; } }
  @media (max-width: 767px) {
    .review-form .btn-navigation .btn {
      width: 100%; } }
  .review-form .btn-navigation .back-link {
    line-height: 1.8; }

.back-link {
  color: #325885;
  -webkit-transition: color 0.3s;
  transition: color 0.3s; }
  .back-link:hover {
    color: #00b0f0; }
  .back-link .icon {
    -webkit-transition: color 0.3s;
    transition: color 0.3s; }

@media (max-width: 767px) {
  .no-border {
    border: 0 !important; } }

.no-wrap {
  white-space: nowrap; }

@media (max-width: 991px) {
  .text-block p {
    margin-bottom: 1.9rem; } }

.text-block p:last-child {
  margin-bottom: 0; }

.text-block-lg p {
  margin-bottom: 0.6rem; }
  @media (min-width: 992px) {
    .text-block-lg p {
      margin-bottom: 1.7rem; } }

.btn.btn-square {
  border-radius: 4px;
  min-width: 9.875rem; }

@media (max-width: 991px) {
  .permissions-block {
    line-height: 1.4; } }

@media (min-width: 992px) {
  .card.controls-block {
    padding: 0; } }

.controls-block {
  padding: 0; }
  .controls-block .card-body > .row {
    border: 0; }
    @media (min-width: 768px) {
      .controls-block .card-body > .row {
        border-top: 1px solid #dbe3ee; } }
    .controls-block .card-body > .row:first-child {
      border-top: 0; }
  @media (min-width: 992px) {
    .controls-block .label {
      padding: 0.5625rem 0.9375rem; } }

@media (max-width: 991px) {
  .check-btns li {
    width: 50%;
    padding-bottom: 0.625rem; }
  .check-btns .custom-control {
    padding-left: 0;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch; }
    .check-btns .custom-control .custom-control-label {
      padding: 0.625rem;
      width: 100%;
      -ms-flex-item-align: stretch;
          align-self: stretch;
      display: -webkit-box;
      display: -ms-flexbox;
      display: flex;
      -webkit-box-align: center;
          -ms-flex-align: center;
              align-items: center;
      -webkit-box-pack: center;
          -ms-flex-pack: center;
              justify-content: center;
      border: 1px solid #dae0e6;
      border-radius: 4px;
      background-color: #fafbfc; }
      .check-btns .custom-control .custom-control-label::before, .check-btns .custom-control .custom-control-label::after {
        content: none; }
    .check-btns .custom-control-input:checked ~ .custom-control-label {
      border-color: #03b0f3;
      color: #03b0f3; } }

.skew-section {
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-flow: column-reverse nowrap;
          flex-flow: column-reverse nowrap; }
  @media (min-width: 992px) {
    .skew-section {
      -webkit-box-orient: horizontal;
      -webkit-box-direction: normal;
          -ms-flex-flow: row wrap;
              flex-flow: row wrap; } }
  .skew-section .signin-block {
    text-align: left; }
    .skew-section .signin-block .holder {
      padding: 1.4375rem 1.5rem 0; }
      @media (min-width: 768px) {
        .skew-section .signin-block .holder {
          padding: 4.375rem 2.5rem 3.125rem; } }
      @media (min-width: 992px) {
        .skew-section .signin-block .holder {
          padding: 5.625rem 3.75rem 3.75rem; } }
      @media (min-width: 1260px) {
        .skew-section .signin-block .holder {
          padding: 7.5rem 8.75rem 4.5625rem; } }
  .skew-section .signin-text {
    font-size: 1rem;
    color: #325885;
    margin-bottom: 1.75rem; }
    @media (min-width: 768px) {
      .skew-section .signin-text {
        margin-bottom: 2rem; } }
    .skew-section .signin-text h1 {
      font-size: 2rem;
      color: #203b5c; }
      @media (max-width: 767px) {
        .skew-section .signin-text h1 {
          margin-bottom: 0.8125rem; } }
  .skew-section .logo {
    margin: 0;
    margin-bottom: 1.5625rem; }
    @media (min-width: 768px) {
      .skew-section .logo {
        margin: 0; } }
  .skew-section .img-holder::before {
    content: "";
    position: absolute;
    z-index: 10;
    bottom: 0;
    left: 0;
    height: 9%;
    width: 100%;
    background-image: -webkit-linear-gradient(bottom right, #fff 50%, transparent 51%);
    background-image: linear-gradient(to top left, #fff 50%, transparent 51%); }
    @media (min-width: 992px) {
      .skew-section .img-holder::before {
        top: 0;
        left: 0;
        height: 100%;
        width: 8%;
        background-image: -webkit-linear-gradient(top left, #fff 50%, transparent 51%);
        background-image: linear-gradient(to bottom right, #fff 50%, transparent 51%); } }
  .skew-section .img-holder .img {
    padding-top: 82%; }
    @media (min-width: 768px) {
      .skew-section .img-holder .img {
        padding-top: 42%; } }
    @media (min-width: 992px) {
      .skew-section .img-holder .img {
        padding-top: 0; } }
  .skew-section .btn {
    width: 100%;
    margin: 0 auto; }
    @media (min-width: 576px) {
      .skew-section .btn {
        width: auto;
        min-width: 18.75rem; } }
    @media (min-width: 992px) {
      .skew-section .btn {
        width: 100%; } }

.login-block {
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center; }
  .login-block .form-group {
    text-align: left;
    margin-bottom: 0.9375rem; }
  .login-block label,
  .login-block .label {
    margin-bottom: 0.3125rem; }
  .login-block .input-wrap i {
    position: absolute;
    top: 50%;
    right: 1rem;
    pointer-events: none;
    -webkit-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
            transform: translateY(-50%); }
  @media (min-width: 1260px) {
    .login-block .logo {
      top: 110px; } }
  .login-block .signin-block {
    max-width: 21.75rem;
    padding: 0 1.5rem; }
    .login-block .signin-block .holder {
      padding-left: 0;
      padding-right: 0; }
      @media (min-width: 1260px) {
        .login-block .signin-block .holder {
          padding: 12.2rem 0 5.4rem 0; } }
  @media (max-width: 767px) {
    .login-block.input-focused .logo {
      display: none; }
    .login-block.input-focused .signin-block .holder {
      padding-top: 2.6rem; } }

.tooltip-holder input {
  padding-right: 3.5rem; }
  @media (min-width: 768px) {
    .tooltip-holder input {
      padding-right: 3rem; } }

.tooltip-holder .tooltip-opener {
  position: absolute;
  top: 50%;
  right: 2rem;
  -webkit-transform: translateY(-50%);
      -ms-transform: translateY(-50%);
          transform: translateY(-50%);
  line-height: 1;
  font-size: 1rem; }
  @media (min-width: 768px) {
    .tooltip-holder .tooltip-opener {
      right: 1rem; } }

.tooltip-opener.tooltip-right .tooltip-drop {
  width: 12.625rem; }
  @media (max-width: 991px) {
    .tooltip-opener.tooltip-right .tooltip-drop {
      left: -145px; } }

@media (max-width: 991px) {
  .tooltip-opener.tooltip-right .tooltip-wrap::before, .tooltip-opener.tooltip-right .tooltip-wrap::after {
    left: 152px; } }

.payments-form {
  padding: 0.3125rem 1rem 1rem;
  background-color: #fff;
  border-radius: 4px;
  box-shadow: 0 2px 2px rgba(73, 92, 117, 0.1); }
  @media (min-width: 768px) {
    .payments-form {
      padding: 1.5rem; } }
  @media (min-width: 768px) {
    .payments-form .btn-holder {
      padding-top: 0.5rem; } }
  .payments-form .btn {
    min-width: 13.3125rem; }
    @media (max-width: 767px) {
      .payments-form .btn {
        width: 100%; } }
  .payments-form label,
  .payments-form .label {
    margin-bottom: 0.1rem; }
  .payments-form .form-group {
    margin-bottom: 1.1rem; }
  .payments-form .row {
    margin-left: -8px;
    margin-right: -8px; }
    .payments-form .row [class^="col-"] {
      padding-left: 8px;
      padding-right: 8px; }

@media (max-width: 767px) {
  .main-grow {
    height: 100%; }
    .main-grow > section {
      height: 100%;
      max-width: none;
      background-color: #fff; }
      .main-grow > section .head-block {
        margin-bottom: 0;
        padding: 1.5rem 1rem 0.3125rem; }
      .main-grow > section .payments-form {
        box-shadow: none;
        border-radius: 0; } }

.counter {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  width: 1.5rem;
  height: 1.5rem;
  border-radius: 50%;
  font-size: 0.875rem;
  font-weight: 600;
  color: #b9c4d1;
  background-color: #e5e7eb; }
  @media (min-width: 768px) {
    .counter {
      width: 2rem;
      height: 2rem; } }
  .counter.active {
    color: #00aff3;
    background-color: #d9ebf5; }

.tasks-block {
  font-size: 0.75rem; }

.tasks-text {
  display: inline-block;
  vertical-align: top;
  margin-bottom: 1.5rem; }
  @media (min-width: 768px) {
    .tasks-text {
      margin-bottom: 1.875rem; } }

.tasks {
  margin-bottom: 1.5rem; }
  @media (min-width: 768px) {
    .tasks {
      margin-bottom: 1.875rem; } }
  .tasks li {
    margin-bottom: 0.6875rem; }
    @media (min-width: 768px) {
      .tasks li {
        margin-bottom: 0.8125rem; } }
  .tasks-item {
    position: relative;
    display: block;
    padding: 1.0625rem 1rem;
    color: #325885;
    font-size: 12px;
    font-weight: 600;
    background-color: #fff;
    border-radius: 4px;
    box-shadow: 0 2px 2px rgba(73, 92, 117, 0.1); }
    @media (min-width: 768px) {
      .tasks-item {
        padding: 1.0625rem 1.5rem; } }
    .tasks-item:hover {
      box-shadow: 0 2px 2px rgba(0, 176, 240, 0.1); }
      .tasks-item:hover .icon {
        color: #00b0f0; }
        .tasks-item:hover .icon-check {
          color: #008ac0; }
    .tasks-item .icon {
      position: absolute;
      top: 50%;
      right: 0.9375rem;
      -webkit-transform: translateY(-50%);
          -ms-transform: translateY(-50%);
              transform: translateY(-50%);
      color: #93a4b8;
      -webkit-transition: color 0.3s;
      transition: color 0.3s; }
      .tasks-item .icon-check {
        font-size: 1rem;
        color: #00aff3; }

.footer.footer-small {
  background-color: transparent; }
  .footer.footer-small .container {
    display: block;
    position: relative;
    padding-top: 1rem;
    padding-bottom: 1rem;
    max-width: 50.125rem;
    text-align: center; }
    @media (min-width: 768px) {
      .footer.footer-small .container {
        padding-top: 1.875rem;
        padding-bottom: 1.875rem; } }
    .footer.footer-small .container::before {
      content: "";
      position: absolute;
      top: 0;
      left: -9999px;
      right: -9999px;
      height: 1px;
      background-color: #dae0e6; }

.privacy-policy {
  overflow: hidden;
  padding: 0.4375rem 1rem 1rem;
  background-color: #fff;
  font-size: 0.75rem;
  color: #325885; }
  @media (max-width: 767px) {
    .privacy-policy {
      line-height: 2; } }
  @media (min-width: 768px) {
    .privacy-policy {
      padding: 1.5rem;
      box-shadow: 0 2px 2px rgba(73, 92, 117, 0.1);
      border-radius: 4px; } }
  .privacy-policy p {
    margin-bottom: 1.1875rem; }
    @media (min-width: 768px) {
      .privacy-policy p {
        margin-bottom: 1.3125rem; } }
  .privacy-policy .help-text {
    display: inline-block;
    vertical-align: top;
    color: #9296a8;
    margin-bottom: 1rem; }
  .privacy-policy h3 {
    font-size: 0.875rem;
    margin-bottom: 0.3125rem; }
    @media (min-width: 768px) {
      .privacy-policy h3 {
        font-size: 1rem; } }
  .privacy-policy .btn-lists {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: 0;
    text-align: center; }
    @media (min-width: 768px) {
      .privacy-policy .btn-lists {
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        padding-top: 1.5rem; } }
    @media (min-width: 768px) {
      .privacy-policy .btn-lists::before {
        content: "";
        position: absolute;
        top: 0;
        left: -99999px;
        right: -99999px;
        height: 1px;
        background-color: #dbe3ee; } }
    .privacy-policy .btn-lists li {
      margin-bottom: 1.1875rem; }
      @media (min-width: 768px) {
        .privacy-policy .btn-lists li {
          margin-bottom: 1.5rem; } }
      .privacy-policy .btn-lists li:last-child {
        margin-bottom: 0; }
    .privacy-policy .btn-lists .btn {
      width: 100%; }
      @media (min-width: 576px) {
        .privacy-policy .btn-lists .btn {
          width: auto;
          min-width: 16rem; } }
    .privacy-policy .btn-lists .back-link {
      display: inline-block;
      vertical-align: top;
      line-height: 2; }
      .privacy-policy .btn-lists .back-link:hover i {
        color: #00b0f0; }
      .privacy-policy .btn-lists .back-link i {
        color: #93a4b8; }

.js-slide-hidden {
  position: absolute !important;
  left: -9999px !important;
  top: -9999px !important;
  display: block !important; }
/*# sourceMappingURL=main.css.map */