$breakpoints: (
  'xs-phone': 375px,
  'phone': 576px,
  'tablet': 768px,
  'desktop': 992px,
  'widescreen': 1260px
);

// keywords
$media-expressions: (
  'screen': 'screen',
  'print': 'print',
  'handheld': 'handheld',
  'landscape': '(orientation: landscape)',
  'portrait': '(orientation: portrait)',
  'retina2x': '(-webkit-min-device-pixel-ratio: 1.5), (min-resolution: 144dpi)',
  'retina3x': '(-webkit-min-device-pixel-ratio: 2.5), (min-resolution: 240dpi)'
);

// ligatured operators ≥ ≤

@import 'vendors/include-media';

@import "vendors/bootstrap/functions";
@import "vendors/bootstrap/variables";
@import "vendors/bootstrap/mixins";
@import "vendors/date-picker";

@import "bootstrap-custom";

/* ---------------------------------------------------------
   1. Embedded fonts styles
   2. Custom form styles
   3. Common site styles
   ---------------------------------------------------------*/

@import 'base/functions';
@import 'base/mixins';
@import 'base/helpers';
/* ---------------------------------------------------------
   1. Embedded fonts styles
   ---------------------------------------------------------*/
@import 'base/icomoon';
/* ---------------------------------------------------------
   2. Custom form styles
   ---------------------------------------------------------*/
@import 'base/custom-forms';
/* ---------------------------------------------------------
   3. Сommon site styles
   ---------------------------------------------------------*/

@import 'layout/header';
@import 'layout/common';
@import 'layout/footer';
@import 'layout/patch';
@import 'layout/part';

.js-slide-hidden {
    position: absolute !important;
    left: -9999px !important;
    top: -9999px !important;
    display: block !important;
}