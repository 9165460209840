/* custom select styles */

.jcf-select {
  position: relative;
  border: 1px solid $gray-250;
  background: $gray-150;
  margin: 0;
  padding: $input-btn-padding-y $input-btn-padding-x;
  padding-right: rem-calc(45) !important;
  font-size: $input-btn-font-size;
  line-height: $input-btn-line-height;
  color: $gray-800;
  border-radius: 4px;
  display: block;
  width: 100%;
  @include animate(border color background);
  text-align: left;
  height: 40px;

  &.jcf-select-lg {
    padding: $input-btn-padding-y-lg $input-btn-padding-x-lg !important;
    height: 48px;
  }

  &.jcf-select-sm {
    padding: $input-btn-padding-y-sm $input-btn-padding-x-sm !important;
    height: 30px;
  }

  &.jcf-select-sel-flag {
    padding-right: rem-calc(10) !important;
    width: 48px;
    border: 0;
    background: none;

    .jcf-select-opener {
      width: 8px;
      font-size: 9px;
    }
  }

  &.jcf-error,
  .error & {
    border-color: $danger;

    .jcf-select-opener {
      color: $danger;
    }
  }

  &.jcf-success,
  .success & {
    border-color: $success;
  }

  select {
    z-index: 1;
    left: 0;
    top: 0;
  }

  .jcf-select-text {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    cursor: default;
    display: block;
    color: theme-color("dark");
    .jcf-option-first {
      color: theme-color("secondary");
    }
  }

  .jcf-select-opener {
    @include pseudo-element(before, 50%, auto, auto, 50%);
    position: absolute;
    text-align: center;
    width: 40px;
    bottom: 0;
    right: 0;
    top: 0;
    color: theme-color("secondary");
    font-size: rem-calc(10);
    &:before {
      @include icon("\e901");
      transform: translate(-50%, -50%);
      pointer-events: none;
    }
  }

  .jcf-select-drop {
    position: absolute;
    margin-top: 0;
    z-index: 9999;
    top: 100%;
    left: -1px;
    right: -1px;
  }

  .jcf-drop-flipped {
    bottom: 100%;
    top: auto;
  }
}

body>.jcf-select-drop {
  position: absolute;
  margin: -1px 0 0;
  z-index: 9999;
  &.jcf-drop-flipped {
    margin: 1px 0 0;
  }
}

.jcf-select-drop .jcf-select-drop-content {
  border: 1px solid theme-color("primary");
}


/* multiple select styles */

.jcf-list-box {
  overflow: hidden;
  display: inline-block;
  border: 1px solid #b8c3c9;
  min-width: 200px;
  margin: 0 15px;
}

/* select options styles */

.jcf-list {
  display: inline-block;
  vertical-align: top;
  position: relative;
  background: #fff;
  line-height: 14px;
  font-size: 12px;
  width: 100%;

  .jcf-list-content {
    vertical-align: top;
    display: inline-block;
    overflow: auto;
    width: 100%;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      overflow: hidden;
      display: block;
    }
  }

  .jcf-overflow {
    overflow: auto;
  }

  .jcf-option {
    overflow: hidden;
    cursor: default;
    display: block;
    padding: rem-calc(8 10);
    color: #656565;
    height: 1%;
  }

  .jcf-disabled {
    background: #fff !important;
    color: #aaa !important;
  }

  .jcf-optgroup-caption {
    white-space: nowrap;
    font-weight: bold;
    display: block;
    padding: 5px 9px;
    cursor: default;
    color: #000;
  }

  .jcf-optgroup .jcf-option {
    padding-left: 30px;
  }
}

.jcf-select-drop .jcf-hover,
.jcf-list-box .jcf-selected {
  background: $blue;
  color: $white;
}


/* custom file input styles */

.jcf-file {
  @include font(12, 18, 600);
  display: block;
  width: 100%;
  position: relative;
  overflow: hidden;
  background: $white;
  padding: rem-calc(15);
  border: 2px dashed $gray-250;
  border-radius: 4px;
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: center;
  text-align: left;


  .jcf-real-element {
    position: absolute;
    font-size: 200px;
    height: 200px;
    margin: 0;
    right: 0;
    top: 0;
  }

  .jcf-fake-input {
    display: flex;
    flex-flow: row wrap;
    overflow: hidden;
    color: $dark;
    position: relative;
    //padding-bottom: rem-calc(18);

    &:after {
      display: inline-block;
      vertical-align: top;
      content: '\00a0 Browse';
      color: $primary;
      order: 1;
    }
    &:before {
      content: 'Supported file type: JPG, PNG, or PDF';
      display: block;
      color: #b3b6c2;
      font-weight: 500;
      width: 100%;
    }
  }

  .jcf-upload-button {
    display: block;
    overflow: hidden;
    font-weight: 500;
    color: #b3b6c2;
    order: -1;
    margin: 0 15px 0 0;
    &:before {
      @include icon("\e908");
      color: $secondary;
      font-size: 1.375rem;
      display: inline-block;
      vertical-align: top;
    }
  }
}




/* common custom form elements styles */

.jcf-disabled {
  .jcf-select-text {
    color: rgba(theme-color("dark"), 0.4) !important;
  }
}

.jcf-focus,
.jcf-focus * {
  border-color: theme-color("primary");
}


.form-group {
  position: relative;

  .input-wrap {
    position: relative;
  }

  .icon-box {
    position: absolute;
    top: 1px;
    left: 1px;
    bottom: 1px;
    width: 48px;
    border-radius: 4px 0 0 4px;
    z-index: 4;
    pointer-events: none;
    background: #eef7fb;
    border-right: 1px solid $gray-250;
    font-size: rem-calc(16);
    color: theme-color("primary");
    .icon {
      position: absolute;
      top: 50%;
      left: 50%;
      display: block;
      transform: translate(-50%, -50%);
    }

    ~ .form-control,
    ~ .jcf-select {
      padding-left: rem-calc(61) !important;
    }
  }
}
