/* main container styles */
#wrapper {
	display: table;
	table-layout: fixed;
	width: 100%;
	height: 100%;
	position: relative;
	overflow: hidden;
}

/* main informative part styles */
#main {
	position: relative;
	z-index: 10;
	padding: rem-calc(23 0);

	@include media('>=tablet') {
		padding: rem-calc(40 0);
	}

	@include media('>=desktop') {
		padding: rem-calc(56 0);
	}
}

html {
	height: 100%;
}

body {
	height: 100%;
	min-width: $base-min-width;
	-ms-overflow-style: scrollbar;
}

a{
	@include animate(color background border);
}

h1, .h1,
h2, .h2,
h3, .h3,
h4, .h4,
h5, .h5,
h6, .h6 {
	display: block; 
}

h1, .h1 {
	line-height: (32/24);
}

h2, .h2 {
	line-height: (32/20);
}

h3, .h3 {
	line-height: (30/18);
}

h4, .h4 {
	line-height: (20/13);
}


.hidden{
	position:absolute;
	left:-9999px;
	top:auto;
	width:1px;
	height:1px;
	overflow:hidden;
}

.accessibility {
	position:absolute;
	left:-10000px;
	top:auto;
	width:1px;
	height:0;
	overflow:hidden;
}

.accessibility:focus{
	position:static;
	width:auto;
	height:auto;
}

.form-control {
	box-shadow: none;
	outline: none;
	margin: 0;
	display: block;

	&.signature-filed {
		border-radius: 0;
		text-align: center;
		border-color: transparent transparent $dark;
	}

	.error & {
		border-color: $danger;
	}

	.success &{
		border-color: $success;
	}
}

.alert {
	@include font(12, 16, 400);
	margin: rem-calc(16 0);

	.icon {
		font-size: 18px;
		line-height: inherit;
		display: inline-block;
		vertical-align: top;
		margin: -1px 4px 0 0;
	}
}

.alert-dismissible {
	padding-top: rem-calc(22);
	padding-bottom: rem-calc(22);
	.close {
		padding: 0.5rem;
		font-size: 0.625rem;
	}
}

.btn {
	min-width: 84px;
	text-transform: capitalize;
	&:not([class^="btn-outline-"]):not([class*="btn-outline-"]):not(.btn-link) {
		color: $white;
	}

	&.btn-lg {
		min-width: 100px;

		@include media('>=desktop') {
			padding-left: rem-calc(32);
			padding-right: rem-calc(32);
		}
	}

	&.btn-rounded {
		@include media('<tablet') {
			min-width: 32px;
			min-height: 32px;
			text-align: center;
			padding: rem-calc(7);
		}
	}

	&.btn-secondary {
		background: $white;
		border-color: #d7dde6;
		color: $dark !important;
		&:hover {
			background: #eef2f6 !important;
		}

		&:active {
			background: #fafbfc !important;
			border-color: #d7dde6 !important;
		}
	}

	.icon {
		display: inline-block;
		vertical-align: top;
		line-height: inherit;
		font-size: 90%;
		margin: rem-calc(0 6 0 0);
	}
}

.signin-section {
	overflow: hidden;
	min-height: 100vh;
	display: flex;
	flex-flow: column nowrap;
	background: $white;
	@include media('>=desktop') {
		flex-flow: row wrap;
	}

	.logo {
		display: block;
		width: 100%;
		max-width: 235px;
		margin: 0 auto 50px;

		@include media('>=tablet') {
			position: absolute;
			left: 50%;
			transform: translateX(-50%);
			top: 60px;
			margin: 0;
		}
		@include media('>=widescreen') {
			max-width: 299px;
			top: 118px;
		}

		img {
			display: block;
			width: 100%;
			height: auto;
		}
	}

	.signin-block {
		text-align: center;
		flex-grow: 1;
		position: relative;
		background: $white;
		@include media('>=tablet') {
			padding: rem-calc(40 0);
			display: flex;
			flex-flow: row wrap;
			align-items: center;
		}
		@include media('>=desktop') {
			flex-grow: 0;
			min-height: inherit;
			flex-shrink: 0;
			width: 450px;
			padding: rem-calc(100 0);
		}
		@include media('>=widescreen') {
			width: 585px;
		}

		.holder {
			overflow: hidden;
			padding: rem-calc(48 24 19);
			width: 100%;
			@include media('>=tablet') {
				padding: rem-calc(70 40 50);
			}
			@include media('>=desktop') {
				padding: rem-calc(90 60 60);
			}
			@include media('>=widescreen') {
				padding: rem-calc(120 140 73);
			}
		}
	}

	.img-holder {
		width: 100%;
		position: relative;
		overflow: hidden;
		@include media('>=desktop') {
			flex-grow: 1;
			padding-top: 0;
			width: calc(100% - 450px);
		}

		@include media('>=widescreen') {
			width: calc(100% - 585px);
		}

		.img {
			display: block;
			padding-top: 54%;
			background-size: cover;
			background-position: 50% 0;
			background-repeat: no-repeat;

			@include media('>=desktop') {
				padding-top: 0;
				position: absolute;
				top: 0;
				left: 0;
				right: 0;
				bottom: 0;
			}

		}
	}

	h3, .h3 {
		margin-bottom: rem-calc(32);
	}

	.btn-holdre {
		padding: rem-calc(8 0);
		margin: rem-calc(0 0 15);
		@include media('>=desktop') {
			margin: rem-calc(0 0 40);
		}
	}

	.note-text {
		@include fontsize(12, 16);
		color: $gray-800;
		padding: rem-calc(9 0 0);

		@include media('>=widescreen') {
			margin: rem-calc(0 0 -12);
			padding: 0;
		}

		a {
			display: inline-block;
			vertical-align: top;
			color: $gray-800;

			 &:hover {
				color: $primary;
			 }
		}
	}
}

.dropdown {
	position: relative;

	&.align-left {
		.dropdown-menu {
			left: auto !important;
			right: 0;
		}

		&.has-arrow {
			.dropdown-menu {
				&:before,
				&:after {
					left: 100%;
					transform: translateX(-35px);
				}
			}
		}
	}

	&.has-arrow {
		.dropdown-menu {
			@include outlined-arrow(top, 6px, 1px, $white, #d7dde6);
			&:before,
			&:after {
				left: 20px;
			}
		}
	}

	.dropdown-menu {
		padding: rem-calc(10 0);
		@extend %listreset;
		margin: rem-calc(15 0 0);
		border: 1px solid #d7dde6;
		box-shadow: 4px 0 8px rgba(73, 92, 117, 0.2);
		@include font(12, 16, 600);
		li {
			margin: rem-calc(0 0 4);

			&:last-child {
				margin: 0;
			}

			a{
				color: $gray-800;
				display: block;
				padding: rem-calc(6 10);
				transition: all 0.3s ease;

				&:hover {
					color: $white;
					background: rgba($primary, 0.5);
				}
			}
		}
	}
}

.setps-block {
	position: relative;

	.title-text {
		display: block;
		color: $gray-800;
	}

	.text {
		display: block;
		color: #9397a9;
	}

	.label {
		&.color {
			color: #9397a9;
		}
	}

	.step-header {
		@include bg-color(before, $white);
		position: relative;
		padding-top: rem-calc(16);
		padding-bottom: rem-calc(16);
		margin-bottom: rem-calc(15);
		z-index: 10;
		@include media('>=tablet') {
			margin-bottom: rem-calc(20);
		}
		@include media('>=desktop') {
			margin-bottom: rem-calc(39);
		}
		&:before {
			box-shadow: 0 2px 2px rgba(73, 92, 117, 0.1), 0 -1px 0 rgba(147, 164, 184, 0.28);
		}
		h3, .h3 {
			display: inline-flex;
			flex-flow: row nowrap;
			align-items: center;
			@include media('<desktop') {
				@include fontsize(16, 22);
			}
			@include media('<tablet') {
				@include fontsize(12, 16);
			}
		}
	}

	.step-opener {
		display: inline-block;
		vertical-align: top; 
		position: relative;
		padding-right: 16px;
		color: $blue;

		@include media('>=xs-phone') {
			padding-right: 22px;
		}
		@include media('>=tablet') {
			pointer-events: none;
			padding-right: 5px;
		}

		.step-active & {
			&:after {
				top: 4px;
				transform: rotate(-180deg);
			}
		}

		&:after {
			position: absolute;
			top: 1px;
			right: 0;
			@include icon("\e901");
			font-size: 12px;
			line-height: 1;
			color: $gray-600;

			@include media('>=tablet') {
				display: none;
			}
		}
	}

	.step-active {
		.step-opener {
			&:after {
				top: 4px;
				transform: rotate(-180deg);
			}
		}
	}

	.number {
		border-radius: 100%;
		display: inline-flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: center;
		align-self: flex-start;
		flex-shrink: 0;
		@include vertical();
		@include font(18, 22, 600);
		color: $primary;
		background: $blue-600;
		text-align: center;
		padding: rem-calc(5);
		min-width: 32px;
		margin: rem-calc(0 8 0 0);

		@include media('>=desktop') {
			margin: rem-calc(0 15 0 0);
		}
	}

	.title-holder {
		display: block;
		@include fontsize(12, 16);
		position: relative;
		padding: 10px 0 0 10px;
		color: $gray-800;

		@include media('>=tablet') {
			display: inline-block;
			vertical-align: top;
			padding: 5px 0 0 9px;
			@include fontsize(16, 22);
		}
		@include media('>=desktop') {
			@include fontsize(18, 22);
		}

		&.active-title {

			&:before {
				display: none;
				content: "";
				position: absolute;
				top: 8px;
				left: 0;
				width: 3px;
				height: 15px;
				background: $gray-800;
				transform: rotate(10deg);
				@include media('>=tablet') {
					display: block;
				}
			}
		}
	}
}

.custom-control {
	display: flex;
	flex-flow: row wrap;
	align-items: center;
	@include media('>=tablet') {
		padding-left: 2rem;
	}

	.custom-control-label {
		&:before,
		&:after {
			top: 0;
			@include media('>=tablet') {
				width: 1.5rem;
				height: 1.5rem;
				left: -2rem;
				top: -4px;
			}
			@include media('>=desktop') {
				top: -2px;
			}
		}
	}
}

.step-widget {
	position: absolute;
	top: 100%;
	left: 0;
	right: 0;
	padding-bottom: 5px;
	box-shadow: 0 2px 2px rgba(73, 92, 117, 0.1);
	background: $white;
	z-index: 9;
	opacity: 0;
	transition: opacity 0s linear 1s;

	.step-active & {
		opacity: 1;
	}

	.sidebar & {
		opacity: 1;
	}

	@include media('>=tablet') {
		position: relative;
		left: auto;
		top: auto;
		right: auto;
		transition: none;
		transform: none;
		background: none;
		box-shadow: none;
	}

	.step-list {
		width: 100%;
		@extend %listreset;
		@include font(12, 16, 600);
		color: $gray-800;
		padding: rem-calc(15);
		margin: rem-calc(0 0 10);
		box-shadow: 0 2px 2px rgba(73, 92, 117, 0.1),
		inset 0 2px 2px rgba(73, 92, 117, 0.1);
		border-radius: 0 0 4px 4px;
		background: $white;

		@include media('>=tablet') {
			box-shadow: 0 2px 2px rgba(73, 92, 117, 0.1);
			border-radius: 4px;
		}

		@include media('>=desktop') {
			padding: rem-calc(20 15);
			margin: rem-calc(0 0 24);
		}
		@include media('>=widescreen') {
			padding: rem-calc(32);
		}

		li {
			position: relative;
			padding: rem-calc(0 0 25 32);
			z-index: 2;
			@include media('>=widescreen') {
				padding: rem-calc(0 0 30 40);
			}

			&:last-child {
				padding-bottom: 0;
			}

			&.active {
				&:before {
					content: '';
					border-color: $primary;
					background: $white;
				}

				&:after {
					background: #c3d1e2;
				}

				~ li {

					&:before {
						border-color: #c3d1e2;
						content: '';
						background: $white;
					}

					&:after {
						background: #c3d1e2;
					}
				}
			}

			&:before {
				@include icon("\e904");
				position: absolute;
				top: -4px;
				left: 0;
				width: 24px;
				height: 24px;
				border-radius: 50%;
				border: 2px solid $primary;
				background: $primary;
				font-size: 12px;
				line-height: 18px;
				font-weight: 400;
				color: $white;
				text-align: center;
			}

			&:after {
				content: "";
				position: absolute;
				left: 11px;
				top: 0;
				bottom: 0;
				width: 2px;
				z-index: -1;
				background: $primary;
			}
		}
	}

	.btn-holder {
		.btn {
			.icon {
				font-size: 14px;
				margin-top: -2px;
				color: $secondary;
			}
		}
	}

	.logo-wrap {
		display: none;

		@include media('>=tablet') {
			display: block;
		}

		.logo {
			display: inline-block;
			vertical-align: top;
			width: 109px;

			img {
				display: block;
				width: 100%;
				height: auto;
			}
		}
	}
}

.card {
	box-shadow: 0 2px 2px rgba(73, 92, 117, 0.1);
	background: $white;
}

.step-form {
	position: relative;
	overflow: hidden;

	&.form-error,
	.form-error &,
	.form-error {
		.alert {
			display: block;
			&.fade {
				visibility: visible;
				opacity: 1 !important;
			}
		}
	}

	.alert {
		display: none;

		&.alert-danger {
			border-color: #fcc4bf;
		}
		&.fade {
			visibility: hidden;
		}
	}


	.card {
		border: 0;
		.card-header {
			background: none;
			padding: rem-calc(18px 24px 12px);
			border: 0;
			@include media('>=desktop') {
				padding: rem-calc(33px 32px 24px);
			}
		}
	}

	.upload-link {
		display: inline-block;
		vertical-align: top;
		font-size: 16px;
		line-height: 1;
		color: $secondary;

		&:hover {
			color: $blue;
		}

		.icon {
			line-height: inherit;
		}
	}

	.check-list {
		align-self: center;
	}

	.card-body {
		position: relative;
		padding: rem-calc(0 24 20);
		@include media('>=desktop') {
			padding: rem-calc(0 32 16);
		}

		> .row {
			border-top: 1px solid #dae0e6;
			padding: rem-calc(9 0);
			@include media('<desktop') {
				margin-left: -24px;
				margin-right: -24px;
				padding: rem-calc(10 0 6);
			}

			&.bg-gray {
				background: #f8f9fc;
			}

			&.error {
				background: #ffecea !important;
				border-color: #fcc4bf !important;

				label,
				.label {
					color: $danger;
				}

				.jcf-select {
					.jcf-select-opener {
						color: $danger;
					}
				}

				+ .row {
					border-color: #fcc4bf !important;
				}
			}

			.row {
				@include media('>=desktop') {
					flex-grow: 1;
				}
				> [class^="col-"] {
					@include media('>=desktop') {
						display: flex;
						flex-flow: row wrap;
						align-items: center;
					}
					@include media('<desktop') {
						padding-top: 8px;
					}
				}

				label,
				.label {
					@include media('<desktop') {
						padding: 0;
					}
				}
			}

			> [class^="col-"] {
				@include media('>=desktop') {
					display: flex;
					flex-flow: row wrap;
				}
				@include media('<desktop') {
					padding-left: 24px;
					padding-right: 24px;
				}
			}

			.border-left {
				border-color: transparent !important;
				position: relative;

				&:before {
					@include media('>=desktop') {
						content: '';
						position: absolute;
						top: -10px;
						left: -1px;
						bottom: -10px;
						background: #dae0e6;
						width: 1px;
					}
				}
			}
		}
	}

	.label,
	label {
		@include font(12, 16, 600);
		color: $dark;
		display: block;
		padding: rem-calc(0 0 2);
		align-self: center;
		@include media('>=desktop') {
			@include fontsize(13, 20);
			padding: rem-calc(9 15);
			margin: 0;
		}

		sup {
			opacity: 0.5;
			top: -2px;
			margin: 0 0 0 3px;
		}
	}

	.jcf-select,
	.form-control {
		background: none;
		border-color: transparent;
		padding-left: 0 !important;
		align-self: center;
		@include media('>=desktop') {
			font-size: rem-calc(13);
		}
	}

	.form-control {
		&.signature-filed {
			border-color: transparent transparent $dark;
			margin: rem-calc(0 0 8);
			@include media('>=desktop') {
				margin: rem-calc(16 0);
			}
		}
	}
}

.check-list {
	margin: rem-calc(0 -10);

	li {
		padding: rem-calc(5 10);
	}
}

.tooltip-opener {
	line-height: inherit;
	z-index: 1;
	display: inline-block;
	vertical-align: top;
	color: $secondary;
	border-radius: 100%;
	font-size: rem-calc(12);
	margin: rem-calc(0 0 0 10);
	position: relative;
	cursor: pointer;

	@include media('>=tablet') {
		font-size: rem-calc(16);
	}

	&.align-right {
		.tooltip-drop {
			left: auto;
			right: -25px;

			.tooltip-wrap {
				&:after,
				&:before {
					left: calc(100% - 32px);
				}
			}
		}
	}

	&:hover {
		color: $secondary;
	}

	&.hover {
		.tooltip-drop {
			opacity: 1;
			visibility: visible;
		}
	}

	.tooltip-drop {
		@include animate(opacity visibility);
		width: 150px;
		display: block;
		font-family: $font-family-base !important;
		position: absolute;
		top: 100%;
		padding: rem-calc(14 0 0);
		left: -20px;
		z-index: 2;
		opacity: 0;
		visibility: hidden;

		@include media('>=tablet') {
			width: 202px;
		}

		.tooltip-wrap {
			@include outlined-arrow(top, 7px, 1px, $white, #d7dde6);
			position: relative;
			display: block;
			background: $white;
			box-shadow: 4px 0 8px rgba(73, 92, 117, 0.2);
			border: 1px solid #d7dde6;
			color: #9397a9;
			@include font(13, 20, 500);
			border-radius: 4px;
			padding: rem-calc(10 15);
			text-align: left;
			&:after,
			&:before {
				left: 28px;
			}
		}

		.text {
			display: block;
		}
	}
}

.tooltip {
	margin: 8px 0 0 100px;
	width: 145px;
	@include media('>=tablet') {
		width: 202px;
		margin: 8px 0 0 158px;
	}

	&.bs-tooltip-top,
	&.bs-tooltip-auto[x-placement^="top"] {
		margin-top: 0 !important;
		.arrow {
			&:before{
				border-top-color: $white;
			}
		}
	}
	&.show {
		opacity: 1 !important;
	}
	.arrow {
		&:before{
			border-bottom-color: $white;
		}
	}
}

.tooltip-inner {
	background: $white;
	box-shadow: 4px 0 8px rgba(73, 92, 117, 0.2);
	border: 1px solid #d7dde6;
	color: #9397a9;
	@include font(13, 20, 500);
	border-radius: 4px;
	padding: rem-calc(10 15);
	text-align: left;
}

.btn-navigation {
	justify-content: space-between;
	li {
		width: 100%;
		text-align: center;
		padding: rem-calc(5 0);
		flex-shrink: 0;
		@include media('>=phone') {
			width: auto;
			text-align: left;
			padding: rem-calc(0);
			max-width: 50%;
		}
		&:only-child {
			width: 100%;
			@include media('>=phone') {
				display: flex;
				flex-flow: row wrap;
				justify-content: flex-end;
			}
		}
	}

	a {
		position: relative;
		display: block;
		&:not([class^="btn"]):not([class*="btn"]) {
			color: $dark;
			display: inline-block;
			vertical-align: top;
			font-size: 12px;

			&:hover {
				color: $blue;
				.icon {
					color: $blue;
				}
			}
		}

		.icon {
			transition: color 0.3s ease;
			line-height: inherit;
			display: inline-block;
			vertical-align: top;
			transform: rotate(180deg);
			margin: 1px 6px 1px 0;
			color: #93a4b8;
		}
	}
}

.custom-file {
	position: relative;

	.custom-file-label {
		border-style: dashed;
		position: relative;
		display: flex;
		flex-flow: row wrap;
		align-items: center;
		justify-content: center;
		font-size: 12px;
		padding: rem-calc(15);

		.align-content {
			display: flex;
			flex-flow: row nowrap;
			align-items: center;
		}
		p {
			margin: 0;
		}

		&:after {
			display: none;
		}

		.icon {
			color: $secondary;
			font-size: 1.375rem;
			display: inline-block;
			vertical-align: top;
			flex-shrink: 0;
			margin: rem-calc(0 15 0 0);

			@include media('>=tablet') {
				font-size: 2rem;
			}
		}

		.main-text {
			display: block;
		}

		.note-text {
			display: block;
			color: #b3b6c2;
			font-weight: 500;
		}
	}

	.custom-file-input {
		position: absolute;
	}
}