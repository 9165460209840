// vertical align el inside parent with fixed height/min-height
// usage
// html - .parent>.child
// scss - @include v-align;
//     or @include v-align(250px);
//     or @include v-align(250px, bottom, before);
//
@mixin v-align($va-height: 100%, $va-direction: middle, $va-pseudo: after) {
  white-space: nowrap;
  text-align: center;

  &:#{$va-pseudo} {
    content: '';
    display: inline-block;
    vertical-align: $va-direction;
    width: 0;
    min-height: $va-height;
  }

  > * {
    white-space: normal;
    display: inline-block;
    vertical-align: $va-direction;
    max-width: 99%;
  }
}

// vertical align a pair of child el inside parent
// usage
// html - .post>.image+.text
// scss - @include v-align-pair(image, text);
//     or @include v-align-pair(image, text, bottom);
//     or @include v-align-pair(image, text, middle, 200px);
//
@mixin v-align-pair($child-name1, $child-name2, $valign: middle, $width1: auto, $width2: auto ) {
  display: table;
  .#{$child-name1}{
    display: table-cell;
    vertical-align: $valign;
    width: $width1;
  }
  .#{$child-name2} {
    display: table-cell;
    vertical-align: $valign; 
    width: $width2;
  }
}


// vertical align el
// usage: @include vertical-align-el;
//
@mixin vertical-align-el {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

/// Mixin helping defining both `width` and `height` simultaneously.
///
/// @author Hugo Giraudel
///
/// @access public
///
/// @param {Length} $width - Element’s `width`
/// @param {Length} $height ($width) - Element’s `height`
///
/// @example scss - Usage
///   .foo {
///     @include size(10em);
///   }
///
///   .bar {
///     @include size(100%, 10em);
///   }
///
/// @example css - CSS output
///   .foo {
///     width: 10em;
///     height: 10em;
///   }
///
///   .bar {
///     width: 100%;
///     height: 10em;
///   }
///
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

/// font-smothing
/// @include font-smoothing(on);
/// @include font-smoothing(off);
@mixin font-smoothing($value:on){
  @if $value == on{
    -webkit-font-smoothing:antialiased;
    -moz-osx-font-smoothing:grayscale;
  }
  @else{
    -webkit-font-smoothing:subpixel-antialiased;
    -moz-osx-font-smoothing:auto;
  }
}


/// Hides the text in an element, commonly used to show an image. Some elements will need block-level styles applied.
///
/// @link http://zeldman.com/2012/03/01/replacing-the-9999px-hack-new-image-replacement
///
/// @example scss - Usage
///   .element {
///     @include hide-text;
///   }
///
/// @example css - CSS Output
///   .element {
///     overflow: hidden;
///     text-indent: 101%;
///     white-space: nowrap;
///   }
///
@mixin hide-text {
  overflow: hidden;
  text-indent: 101%;
  white-space: nowrap;
}


/// Creates a visual triangle.
/// Mixin takes ($size, $color, $direction)
/// The $size argument can take one or two values—width height.
/// The $color argument can take one or two
/// values—foreground-color background-color.
///
/// @author http://bourbon.io/docs/#triangle
///
/// $direction:
/// up, down, left, right, up-right, up-left, down-right, down-left
/// @example scss - Usage
///   @include triangle(12px, gray, down);
///   @include triangle(12px 6px, gray blue, up-left);
///
@mixin triangle($size, $color, $direction) {
  $width: nth($size, 1);
  $height: nth($size, length($size));
  $foreground-color: nth($color, 1);
  $background-color: if(length($color) == 2, nth($color, 2), transparent);
  height: 0;
  width: 0;

  @if ($direction == up) or ($direction == down) or ($direction == right) or ($direction == left) {
    $width: $width / 2;
    $height: if(length($size) > 1, $height, $height/2);

    @if $direction == up {
      border-bottom: $height solid $foreground-color;
      border-left: $width solid $background-color;
      border-right: $width solid $background-color;
    } @else if $direction == right {
      border-bottom: $width solid $background-color;
      border-left: $height solid $foreground-color;
      border-top: $width solid $background-color;
    } @else if $direction == down {
      border-left: $width solid $background-color;
      border-right: $width solid $background-color;
      border-top: $height solid $foreground-color;
    } @else if $direction == left {
      border-bottom: $width solid $background-color;
      border-right: $height solid $foreground-color;
      border-top: $width solid $background-color;
    }
  } @else if ($direction == up-right) or ($direction == up-left) {
    border-top: $height solid $foreground-color;

    @if $direction == up-right {
      border-left:  $width solid $background-color;
    } @else if $direction == up-left {
      border-right: $width solid $background-color;
    }
  } @else if ($direction == down-right) or ($direction == down-left) {
    border-bottom: $height solid $foreground-color;

    @if $direction == down-right {
      border-left:  $width solid $background-color;
    } @else if $direction == down-left {
      border-right: $width solid $background-color;
    }
  } @else if ($direction == inset-up) {
    border-color: $background-color $background-color $foreground-color;
    border-style: solid;
    border-width: $height $width;
  } @else if ($direction == inset-down) {
    border-color: $foreground-color $background-color $background-color;
    border-style: solid;
    border-width: $height $width;
  } @else if ($direction == inset-right) {
    border-color: $background-color $background-color $background-color $foreground-color;
    border-style: solid;
    border-width: $width $height;
  } @else if ($direction == inset-left) {
    border-color: $background-color $foreground-color $background-color $background-color;
    border-style: solid;
    border-width: $width $height;
  }
}


$animation-speed: .3s !default;
$easing-default: ease;


@mixin animate($properties, $duration: $animation-speed, $easing: $easing-default) {
  $list:();
  @each $prop in $properties {
    $str: #{$prop} #{$duration} #{$easing};
    $list: join($list, #{$str}, comma);
  }
  transition: $list;
}


/// Mixin for styling form-placeholders
/// @example scss - Usage
///   @include placeholder {
///    color: #333;
///   }
///
///   input[type='text'],
///   input[type='tel'],
///   input[type='email'] {
///     @include placeholder {
///      color: red;
///     }
///   }

@mixin placeholder {
  &::-webkit-input-placeholder {@content}
  &::-moz-placeholder {opacity: 1; @content}
  &:-moz-placeholder {@content}
  &:-ms-input-placeholder {@content}
  &.placeholder {@content}
}


// alignment
@mixin align($align: top) {
  display: inline-block;
  vertical-align: $align;
}


@mixin inline-reset {
  font-size: 0;
  line-height: 0;
  > * {
    font-size: $font-size-base;
    line-height: $line-height-base;
  }
}


@mixin clear-list {
  margin: 0;
  padding: 0;
  list-style: none;
}


@mixin clearfix {
  &:after{
    clear: both;
    display: block;
    content: "";
  }
}


@mixin box($size) {
  width: $size;
  height: $size;
  border-radius: $size;
}


// mixin for position
@mixin position {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
}


// size
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

// responsive image
@mixin responsive-image {
  display: block;
  width: 100%;
  height: auto;
}

// custom font mixin
@mixin fontface ($fontfamily, $filename, $weight: normal, $style: normal) {
  font-family: $fontfamily;
  src: url('../fonts/#{$filename}.woff2') format('woff2'),
     url('../fonts/#{$filename}.woff') format('woff'),
     url('../fonts/#{$filename}.ttf') format('truetype');
     font-weight: $weight;
     font-style: $style;
}


/// Font-size px to rem calc
/// @example scss - Usage
/// selector {
///    @include rem-font-size(16px);
/// }

@mixin rem-font-size($pxSize) {
  font-size: $pxSize;
  font-size: ($pxSize / 10px) + rem;
}

// extent background
@mixin bg-color($element, $bg-color){
  position: relative;
  &:#{$element} {
    position: absolute;
    display: block;
    content: "";
    top: 0;
    bottom: 0;
    left: -9999px;
    right: -9999px;
    background: $bg-color;
    z-index: -1;
  }
}


// pseudo-element (after and/or before) 
@mixin pseudo-element($element, $top, $right, $bottom, $left){
  &:#{$element} {
    position: absolute;
    display: block;
    content: "";
    top:$top;
    bottom:$bottom;
    left:$left;
    right:$right;
  }
}

// vertical 
@mixin vertical($vertical: top) {
  display: inline-block;
  vertical-align:$vertical;
}

//Font
@mixin font($size: 16, $lineheight: 16, $weight:400) {
  font-size: rem-calc($size); 
  line-height: rem-calc($lineheight);
  font-weight: $weight;
}

@mixin fontsize($size: 16, $lineheight: 16) {
  font-size: rem-calc($size);
  line-height: rem-calc($lineheight);
}

// btn-close
@mixin btn-close($cross-size, $line-width, $cross-color, $position-top, $position-right){
  position: absolute;
  display: block;
  width: $cross-size;
  height: $cross-size;
  top: $position-top;
  right:$position-right;
  transition: all 0.3s ease;
  font-size: 0;
  line-height: 0;
  text-indent: 100%;
  overflow: hidden;

  &:before,
  &:after{
    position: absolute;
    display: block;
    content: '';
    top:50%;
    left: 0;
    right: 0;
    height: $line-width;
    background: $cross-color;
    transform: rotate(45deg);
    margin-top: -$line-width / 2;
    transition: background 0.3s ease;
  }

  &:after{
    transform: rotate(-45deg);
  }
}

// btn-plus
@mixin btn-plus($plus-size, $line-width, $plus-color, $position-top, $position-right){
  position: absolute;
  display: block;
  width: $plus-size;
  height: $plus-size;
  top: $position-top;
  right:$position-right;
  transition: all 0.3s ease;
  font-size: 0;
  line-height: 0;
  text-indent: 100%;
  overflow: hidden;

  &:before,
  &:after{
    position: absolute;
    display: block;
    content: '';
    top:50%;
    left: 5%;
    right: 5%;
    height: $line-width;
    background: $plus-color;
    transition: all 0.3s ease;
    margin-top: -$line-width / 2;
  }

  &:after{
    transform: rotate(90deg);
  }
}

// icomoon usage
@mixin icon($content){ 
  font-family: 'icomoon';
  content: $content;
  font-weight: normal;
}


// outline-arrow
@mixin outlined-arrow($direction, $width, $outline-width, $inner-color, $outline-color){
  position: relative;
  &:after,
  &:before{
    border: solid transparent;
    content: " ";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
  }
  &:after{
    border-width: $width;
  }
  &:before{
    border-width: $width+$outline-width;
  }
  @if $direction == "top" {
    &:after,
    &:before{
      bottom: 100%;
      left: 50%;
    }
    &:after{
      border-color: rgba($inner-color, 0);
      border-bottom-color: $inner-color;
      margin-left: -($width);
    }
    &:before{
      border-color: rgba($outline-color, 0);
      border-bottom-color: $outline-color;
      margin-left: -($width+$outline-width);
    }
  } @else if $direction == "right" {
    &:after,
    &:before{
      top: 50%;
      left: 100%;
    }
    &:after{
      border-color: rgba($inner-color, 0);
      border-left-color: $inner-color;
      margin-top: -($width);
    }
    &:before{
      border-color: rgba($outline-color, 0);
      border-left-color: $outline-color;
      margin-top: -($width+$outline-width);
    }
  } @else if $direction == "bottom" {
    &:after,
    &:before{
      top: 100%;
      left: 50%;
    }
    &:after{
      border-color: rgba($inner-color, 0);
      border-top-color: $inner-color;
      margin-left: -($width);
    }
    &:before{
      border-color: rgba($outline-color, 0);
      border-top-color: $outline-color;
      margin-left: -($width+$outline-width);
    }
  } @else if $direction == "left" {
    &:before,
    &:after{
      top: 50%;
      right: 100%;
    }
    &:after{
      border-color: rgba($inner-color, 0);
      border-right-color: $inner-color;
      margin-top: -($width);
    }
    &:before{
      border-color: rgba($outline-color, 0);
      border-right-color: $outline-color;
      margin-top: -($width+$outline-width);
    }
  }
}
