.container-small {
  max-width: 770px;
  margin: 0 auto;
  padding: 0;

  @include media('>=tablet') {
    max-width: 802px;
    padding: 0 16px;
  }
}

.container-smaller {
  max-width: 570px;
  margin: 0 auto;
  padding: 0;

  @include media('>=tablet') {
    max-width: 602px;
    padding: 0 16px;
  }
}

.container-simple {
  @extend .container-small;
  max-width: 802px;
  padding: 0 16px;
}

.sticky-header {
  z-index: 1000;
  display: block;
  height: auto;
  width: 100%;
  right: 0;
  font-size: rem-calc(12px);

  .container-fluid {
    @include media('<desktop') {
      padding-top: rem-calc(13px);
      padding-bottom: rem-calc(13px);
    }
  }
}

.navbar {
  .icon {
    font-size: rem-calc(16px);
    display: inline-block;
    vertical-align: top;
    margin-top: 2px;
  }
}

.head-block {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: rem-calc(8px);
  padding: rem-calc(16px 18px);
  font-size: rem-calc(14px);
  font-weight: 600;
  line-height: 1.6;
  color: $dark;
  background-color: $white;
  box-shadow: 0 2px 2px rgba(73, 92, 117, 0.1);

  @include media('>=tablet') {
    margin-bottom: rem-calc(16px);
    padding: 0;
    font-size: rem-calc(20px);
    background-color: transparent;
    box-shadow: none;
  }

  h2 {
    color: inherit;
    font-size: inherit;
    margin-bottom: 0;
    line-height: inherit;
  }

  .back-link {
    font-weight: 600;

    i {
      font-size: 80%;
      color: $gray-600;
    }

    &:hover {
      i {
        color: $link-color;
      }
    }
  }

  .steps {
    font-size: rem-calc(12px);
    font-weight: 500;
    color: $gray-650;
  }

  &.head-simple {
    padding: rem-calc(0 0 5px);
    background-color: transparent;
    box-shadow: none;

    @include media('>=tablet') {
      padding: 0;
    }
  }
}

.review-form {
  @extend .step-form;
  color: $gray-light;

  .label,
  label,
  .form-control {
    font-weight: 500;
    padding: rem-calc(11px 0);

    @include media(">=desktop") {
      padding: rem-calc(9 15);
    }
  }

  .card-body {
    padding: rem-calc(0 24px);

    @include media('<desktop') {
      padding: rem-calc(0 18px);
    }

    &>.row {
      border-top-color: $gray-50;
      padding: rem-calc(5px 0);

      @include media('<desktop') {
        margin-left: -18px;
        margin-right: -18px;
      }

      &>[class^="col-"] {
        display: flex;
        flex-wrap: wrap;

        @include media('<desktop') {
          padding-left: 18px;
          padding-right: 18px;
        }
      }
    }

    &:first-child {
      &>.row {
        &:nth-of-type(1) {
          border: 0;
        }
      }
    }
  }

  .card {
    @include media('<tablet') {
      border-radius: 0;
    }

    @include media('>=desktop') {
      padding: rem-calc(10px 0);
    }

    .card-header {
      padding: rem-calc(15px 18px);

      @include media('>=desktop') {
        padding: rem-calc(11px 39px);
      }
    }

    h3 {
      font-size: rem-calc(14px);

      @include media('>=desktop') {
        font-size: rem-calc(16px);
      }
    }
  }

  .body-small {
    font-size: rem-calc(12px);

    .label,
    label {
      font-size: rem-calc(12px);
      padding-top: rem-calc(3px);
      padding-bottom: rem-calc(3px);
    }

    strong.label {
      font-size: rem-calc(14px);
    }
  }

  .card-body {
    .card-header {
      // padding: rem-calc(15px 18px);

      @include media('>=desktop') {
        padding: rem-calc(11px 15px);
      }
    }

    .items-list {
      display: flex;
      flex-wrap: wrap;

      .item {
        width: 100%;
        text-align: right;

        @include media('>=desktop') {
          width: 20%;
        }
      }
    }

    .items-descr {
      position: relative;

      @include media('<desktop') {
        &::before {
          content: "";
          position: absolute;
          bottom: 3px;
          left: -9999px;
          right: -9999px;
          height: 1px;
          background-color: $gray-50;
        }

        &:last-child {
          &::before {
            content: none;
          }
        }
      }
    }

    .row.items-descr,
    .row.items-names {
      @include media('>=desktop') {
        margin: rem-calc(0 8px);
      }

      .items-list {
        @include media('<desktop') {
          padding-top: rem-calc(4px);
          margin-bottom: rem-calc(17px);
        }
      }

      strong.label,
      label strong {
        @include media('<desktop') {
          font-size: rem-calc(12px);
        }
      }

      .label,
      label {
        padding: rem-calc(4px 0);

        @include media('>=desktop') {
          padding: rem-calc(3px 7px);
        }
      }
    }

    .items-names {
      background-color: rgba($gray-50, 0.24);
      border-radius: 4px;

      .label,
      label {
        color: $gray-light;
        font-size: rem-calc(11px);
      }
    }
  }

  strong.label {
    font-weight: 600;
    font-size: rem-calc(12px);

    @include media('>=desktop') {
      font-size: rem-calc(14px);
    }
  }

  .label-list {
    font-size: inherit;
    font-weight: inherit;
    line-height: inherit;

    li {
      padding: 0 0 0 15px;
      margin-bottom: rem-calc(6px);

      @include media('>=desktop') {
        margin-bottom: rem-calc(11px);
      }
    }
  }

  .btn-navigation {
    a {
      .icon {
        transform: none;
      }
    }
  }

  .form-control.signature-filed {
    margin: 0;

    @include media('<desktop') {
      font-size: rem-calc(13px);
    }

    @include media('>=desktop') {
      margin: rem-calc(0 15px 0 0);
    }
  }

  .box {
    margin-left: auto;

    .label,
    label {
      padding: rem-calc(4px 0) !important;

      @include media('>=desktop') {
        padding: rem-calc(3px 7px) !important;
      }
    }

    .value {
      justify-content: flex-end;
      min-width: rem-calc(70px);

      .label {
        padding-left: 0 !important;
      }
    }
  }

  .check-list {
    flex-grow: 1;

    li {
      margin-bottom: rem-calc(7px);

      @include media('>=desktop') {
        margin-right: rem-calc(10px);
      }

      &:last-child {
        @include media('<desktop') {
          margin-bottom: 0;
        }
      }
    }
  }

  .checks-block {
    padding: rem-calc(10px 15px);

    .check-list {
      width: 100%;
    }

    li {
      margin-right: 0;
    }

    .custom-control {
      padding-left: 2.5rem;

      &-label {

        &::before,
        &::after {
          left: -2.5rem;
        }
      }
    }

    .custom-control .custom-control-label:before,
    .custom-control .custom-control-label:after {
      width: 1.5rem;
      height: 1.5rem;
    }
  }

  .btn-navigation {
    li {
      padding: 0;

      @include media('<tablet') {
        margin-bottom: rem-calc(20px);

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .btn {
      @include media('<tablet') {
        width: 100%;
      }
    }

    .back-link {
      line-height: 1.8;
    }
  }
}

.back-link {
  color: $dark;
  transition: color 0.3s;

  &:hover {
    color: $link-color;
  }

  .icon {
    transition: color 0.3s;
  }
}

.no-border {
  @include media('<tablet') {
    border: 0 !important;
  }
}


.no-wrap {
  white-space: nowrap;
}

.text-block {
  p {
    @include media('<desktop') {
      margin-bottom: 1.9rem;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }
}

.text-block-lg {
  p {
    margin-bottom: 0.6rem;

    @include media('>=desktop') {
      margin-bottom: 1.7rem;
    }
  }
}

.btn.btn-square {
  border-radius: 4px;
  min-width: rem-calc(158px);
}

.permissions-block {
  @include media('<desktop') {
    line-height: 1.4;
  }
}

.card.controls-block {
  @include media('>=desktop') {
    padding: 0;
  }
}

.controls-block {
  padding: 0;

  .card-body>.row {
    border: 0;

    @include media('>=tablet') {
      border-top: 1px solid $gray-50;
    }

    &:first-child {
      border-top: 0;
    }
  }

  .label {
    @include media('>=desktop') {
      padding: 0.5625rem 0.9375rem;
    }
  }
}

.check-btns {
  @include media('<desktop') {
    li {
      width: 50%;
      padding-bottom: rem-calc(10px);
    }

    .custom-control {
      padding-left: 0;
      align-items: stretch;

      .custom-control-label {
        padding: rem-calc(10px);
        width: 100%;
        align-self: stretch;
        display: flex;
        align-items: center;
        justify-content: center;
        border: 1px solid $gray-250;
        border-radius: 4px;
        background-color: $gray-150;

        &::before,
        &::after {
          content: none;
        }
      }

      &-input:checked {
        &~.custom-control-label {
          border-color: $blue-light;
          color: $blue-light;
        }
      }
    }
  }
}

.skew-section {
  flex-flow: column-reverse nowrap;

  @include media('>=desktop') {
    flex-flow: row wrap;
  }

  .signin-block {
    text-align: left;

    .holder {
      padding: rem-calc(23 24 0);

      @include media('>=tablet') {
        padding: rem-calc(70 40 50);
      }

      @include media('>=desktop') {
        padding: rem-calc(90 60 60);
      }

      @include media('>=widescreen') {
        padding: rem-calc(120 140 73);
      }
    }
  }

  .signin-text {
    font-size: rem-calc(16px);
    color: $dark;
    margin-bottom: rem-calc(28px);

    @include media('>=tablet') {
      margin-bottom: rem-calc(32px);
    }

    h1 {
      font-size: rem-calc(32px);
      color: $gray-1000;


      @include media('<tablet') {
        margin-bottom: rem-calc(13px);
      }
    }
  }

  .logo {
    margin: 0;
    margin-bottom: rem-calc(25px);

    @include media('>=tablet') {
      margin: 0;
    }
  }

  .img-holder {
    &::before {
      content: "";
      position: absolute;
      z-index: 10;
      bottom: 0;
      left: 0;
      height: 9%;
      width: 100%;
      background-image: linear-gradient(to top left, $white 50%, transparent 51%);

      @include media('>=desktop') {
        top: 0;
        left: 0;
        height: 100%;
        width: 8%;
        background-image: linear-gradient(to bottom right, $white 50%, transparent 51%);
      }
    }

    .img {
      padding-top: 82%;

      @include media('>=tablet') {
        padding-top: 42%;
      }

      @include media('>=desktop') {
        padding-top: 0;
      }
    }
  }

  .btn {
    width: 100%;
    margin: 0 auto;

    @include media('>=phone') {
      width: auto;
      min-width: rem-calc(300px);
    }

    @include media('>=desktop') {
      width: 100%;
    }
  }
}

.login-block {
  flex-direction: row;
  justify-content: center;

  .form-group {
    text-align: left;
    margin-bottom: rem-calc(15px);
  }

  label,
  .label {
    margin-bottom: rem-calc(5px);
  }

  .input-wrap {
    i {
      position: absolute;
      top: 50%;
      right: rem-calc(16px);
      pointer-events: none;
      transform: translateY(-50%);
    }
  }

  .logo {
    @include media('>=widescreen') {
      top: 110px;
    }
  }

  .signin-block {
    max-width: rem-calc(348px);
    padding: rem-calc(0 24px);

    .holder {
      padding-left: 0;
      padding-right: 0;

      @include media('>=widescreen') {
        padding: 12.2rem 0 5.4rem 0;
      }
    }
  }

  &.input-focused {
    @include media('<tablet') {
      .logo {
        display: none;
      }

      .signin-block {
        .holder {
          padding-top: 2.6rem;
        }
      }
    }
  }
}

.tooltip-holder {
  input {
    padding-right: 3.5rem;

    @include media('>=tablet') {
      padding-right: 3rem;
    }
  }

  .tooltip-opener {
    position: absolute;
    top: 50%;
    right: rem-calc(32px);
    transform: translateY(-50%);
    line-height: 1;
    font-size: rem-calc(16px);

    @include media('>=tablet') {
      right: rem-calc(16px);
    }
  }
}

.tooltip-opener.tooltip-right {

  .tooltip-drop {
    width: rem-calc(202px);

    @include media('<desktop') {
      left: -145px;
    }

  }

  .tooltip-wrap {
    &::before,
    &::after {
      @include media('<desktop') {
        left: 152px;
      }
    }
  }
}

.payments-form {
  padding: rem-calc(5px 16px 16px);
  background-color: $white;
  border-radius: 4px;
  box-shadow: 0 2px 2px rgba(73, 92, 117, 0.1);

  @include media('>=tablet') {
    padding: rem-calc(24px);
  }

  .btn-holder {
    @include media('>=tablet') {
      padding-top: rem-calc(8px);
    }
  }

  .btn {
    min-width: rem-calc(213px);

    @include media('<tablet') {
      width: 100%;
    }
  }

  label,
  .label {
    margin-bottom: 0.1rem;
  }

  .form-group {
    margin-bottom: 1.1rem;
  }

  .row {
    margin-left: -8px;
    margin-right: -8px;

    [class^="col-"] {
      padding-left: 8px;
      padding-right: 8px;
    }
  }

}

.main-grow {
  @include media('<tablet') {
    height: 100%;

    &>section {
      height: 100%;
      max-width: none;
      background-color: $white;

      .head-block {
        margin-bottom: 0;
        padding: rem-calc(24px 16px 5px);
      }

      .payments-form {
        box-shadow: none;
        border-radius: 0;
      }
    }
  }
}

.counter {
  display: flex;
  align-items: center;
  justify-content: center;
  width: rem-calc(24px);
  height: rem-calc(24px);
  border-radius: 50%;
  font-size: rem-calc(14px);
  font-weight: 600;
  color: $gray-450;
  background-color: $gray-350;

  @include media('>=tablet') {
    width: rem-calc(32px);
    height: rem-calc(32px);
  }

  &.active {
    color: $blue;
    background-color: $blue-lighten;
  }
}

.tasks-block {
  font-size: rem-calc(12px);
}

.tasks-text {
  display: inline-block;
  vertical-align: top;
  margin-bottom: rem-calc(24px);

  @include media('>=tablet') {
    margin-bottom: rem-calc(30px);
  }
}

.tasks {
  margin-bottom: rem-calc(24px);

  @include media('>=tablet') {
    margin-bottom: rem-calc(30px);
  }

  li {
    margin-bottom: rem-calc(11px);

    @include media('>=tablet') {
      margin-bottom: rem-calc(13px);
    }
  }

  &-item {
    position: relative;
    display: block;
    padding: rem-calc(17px 16px);
    color: $dark;
    font-size: 12px;
    font-weight: 600;
    background-color: $white;
    border-radius: 4px;
    box-shadow: 0 2px 2px rgba(73, 92, 117, 0.1);

    @include media('>=tablet') {
      padding: rem-calc(17px 24px);
    }

    &:hover {
      box-shadow: 0 2px 2px rgba($primary, 0.1);

      .icon {
        color: $primary;

        &-check {
          color: darken($blue, 10%);
        }
      }
    }

    .icon {
      position: absolute;
      top: 50%;
      right: rem-calc(15px);
      transform: translateY(-50%);
      color: $secondary;
      transition: color 0.3s;

      &-check {
        font-size: rem-calc(16px);
        color: $blue;
      }
    }
  }
}

.footer.footer-small {
  background-color: transparent;

  .container {
    display: block;
    position: relative;
    padding-top: rem-calc(16px);
    padding-bottom: rem-calc(16px);
    max-width: rem-calc(802px);
    text-align: center;

    @include media('>=tablet') {
      padding-top: rem-calc(30px);
      padding-bottom: rem-calc(30px);
    }

    &::before {
      content: "";
      position: absolute;
      top: 0;
      left: -9999px;
      right: -9999px;
      height: 1px;
      background-color: $gray-250;
    }
  }
}

.privacy-policy {
  overflow: hidden;
  padding: rem-calc(7px 16px 16px);
  background-color: $white;
  font-size: rem-calc(12px);
  color: $dark;

  @include media('<tablet') {
    line-height: 2;
  }

  @include media('>=tablet') {
    padding: rem-calc(24px);
    box-shadow: 0 2px 2px rgba(73, 92, 117, 0.1);
    border-radius: 4px;
  }

  p {
    margin-bottom: rem-calc(19px);

    @include media('>=tablet') {
      margin-bottom: rem-calc(21px);
    }
  }

  .help-text {
    display: inline-block;
    vertical-align: top;
    color: $gray-650;
    margin-bottom: rem-calc(16px);
  }

  h3 {
    font-size: rem-calc(14px);
    margin-bottom: rem-calc(5px);

    @include media('>=tablet') {
      font-size: rem-calc(16px);
    }
  }

  .btn-lists {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-bottom: 0;
    text-align: center;

    @include media('>=tablet') {
      align-items: center;
      padding-top: rem-calc(24px);
    }

    &::before {
      @include media('>=tablet') {
        content: "";
        position: absolute;
        top: 0;
        left: -99999px;
        right: -99999px;
        height: 1px;
        background-color: $gray-50;
      }
    }

    li {
      margin-bottom: rem-calc(19px);

      @include media('>=tablet') {
        margin-bottom: rem-calc(24px);
      }

      &:last-child {
        margin-bottom: 0;
      }
    }

    .btn {
      width: 100%;

      @include media('>=phone') {
        width: auto;
        min-width: rem-calc(256px);
      }
    }

    .back-link {
      display: inline-block;
      vertical-align: top;
      line-height: 2;

      &:hover {
        i {
          color: $primary;
        }
      }

      i {
        color: $gray-600;
      }
    }
  }
}